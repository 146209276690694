import React, { lazy, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../../_components";
import Connexion from "../Connexion/connexion";
import LoaderSpinner from "../Others/loader/loader";
import SetupChecking from "../AccountSetup/setupChecking"; // DO NOT USE LAZY ON THIS COMPONENT.

import { useDispatch, useSelector } from "react-redux";
import { store } from "../../_helpers";
import { tahService } from "../../_api";

import { urlConstants, userConstants } from "../../_constants";

import SOSRequestForm from "components/ParentPage/Body/SOSRequestForm";
import AutoLogin from "components/AutoLogin/autoLogin";

import "./app.css";

// Customer's components
const TableauDeBordParent = lazy(() => import('../ParentPage/Body/TableauDeBord/tableauDeBordParent'));
const ProfileParents = lazy(() => import('../ParentPage/Body/Profil/profile'));
const DetailsCourse = lazy(() => import('../ParentPage/Body/LessonDetails/lessonDetails'));
const ReviewForm = lazy(() => import('../ParentPage/Body/ReviewForm/reviewForm'));
const CreditCardUpdate = lazy(() => import('../ParentPage/Body/CreditCardUpdate/creditCardUpdate'));

// Tutor's components
const TableauDeBordTutor = lazy(() => import('../TutorPage/Body/TableauDeBord/tableauDeBord'));
const ProfilTutor = lazy(() => import('../TutorPage/Body/Profil/profil'));
const MesFinances = lazy(() => import('../TutorPage/Body/MesFinances/myFinances'));
const RequestCard = lazy(() => import('../TutorPage/Body/requestCard'));

// Shared components
const Sponsorship = lazy(() => import('../Sponsorship/sponsorship'));

export default function App() {
    const store = useSelector(store => store);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!store?.tah?.user?.id && store?.authentication?.user && store?.authentication?.user?.role !== userConstants.ROLE_ADMIN) {

            const updateStore = async () => await tahService.tah_update_all()?.then((data) => {
                if (data) {
                    dispatch(data);
                }
            });
            updateStore();
        }
    }, [store])

    if (!store?.tah?.user && store?.authentication?.user && store?.authentication?.user?.role !== userConstants.ROLE_ADMIN) {
        return <LoaderSpinner />
    }
    return (
        <div id="app" className="App">
            <LoaderSpinner absolute global />
            <div className="App-content">
                <Switch>
                    <Route exact path={urlConstants.LOGIN} component={Connexion} />
                    <Route path={urlConstants.CREATE_PASSWORD} component={() => <Connexion isFirstLogin />} />
                    <Route exact path={urlConstants.STRIPE_SETUP} component={() => <Connexion isStripeSetup />} />
                    <Route
                        path={urlConstants.RECOVER_PASSWORD}
                        component={() => <Connexion recoverPassword />}
                    />
                    <Route exact path={urlConstants.SETUP_CHECKING} component={SetupChecking} />
                    <Route
                        path={urlConstants.AUTOLOGIN}
                        component={AutoLogin}
                    />

                    {/* TUTOR PAGES */}
                    <PrivateRoute exact path={urlConstants.TUTOR_HOME} component={<Redirect to={urlConstants.TUTOR_DASHBOARD} />} />
                    <PrivateRoute
                        exact
                        path={urlConstants.TUTOR_DASHBOARD}
                        component={TableauDeBordTutor}
                        role={userConstants.ROLE_TUTOR}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.TUTOR_PROFILE}
                        component={ProfilTutor}
                        role={userConstants.ROLE_TUTOR}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.TUTOR_MY_FINANCES}
                        component={MesFinances}
                        role={userConstants.ROLE_TUTOR}
                    />
                    <PrivateRoute
                        path={urlConstants.TUTOR_REQUEST_DETAILS}
                        component={RequestCard}
                        role={userConstants.ROLE_TUTOR}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.TUTOR_DECLARE_COURSE}
                        component={() => <Redirect to={urlConstants.TUTOR_DASHBOARD_TAB_3} />}
                        role={userConstants.ROLE_TUTOR}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.TUTOR_SPONSORSHIP}
                        role={userConstants.ROLE_TUTOR}
                        component={() => <Sponsorship />}
                    />
                    {/* TUTOR PAGES END */}

                    {/* CUSTOMER PAGES */}
                    <PrivateRoute
                        exact
                        path={urlConstants.CUSTOMER_DASHBOARD}
                        role={userConstants.ROLE_CUSTOMER}
                        component={TableauDeBordParent}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.CUSTOMER_PROFILE}
                        role={userConstants.ROLE_CUSTOMER}
                        component={ProfileParents}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.CUSTOMER_SPONSORSHIP}
                        role={userConstants.ROLE_CUSTOMER}
                        component={() => <Sponsorship customer />}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.CUSTOMER_SOS_REQUEST_FORM}
                        role={userConstants.ROLE_CUSTOMER}
                        component={SOSRequestForm}
                    />
                    <PrivateRoute
                        path={urlConstants.CUSTOMER_LESSON_DETAILS()}
                        role={userConstants.ROLE_CUSTOMER}
                        component={DetailsCourse}
                    />
                    <PrivateRoute
                        path={urlConstants.CUSTOMER_REVIEW_FORM}
                        role={userConstants.ROLE_CUSTOMER}
                        component={ReviewForm}
                    />
                    <PrivateRoute
                        exact
                        path={urlConstants.CUSTOMER_CARD_FORM}
                        role={userConstants.ROLE_CUSTOMER}
                        component={CreditCardUpdate}
                    />
                    {/* CUSTOMER PAGES END */}

                    <Route>
                        <Default />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

function Default() {
    // Get user from Redux's store.
    var storeData = store.getState();
    var user = storeData.authentication.user;
    if (user?.role === userConstants.ROLE_CUSTOMER) {
        return (
            <Route
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: urlConstants.CUSTOMER_DASHBOARD,
                            state: { from: props.location },
                        }}
                    />
                )}
            />
        );
    } else if (user?.role === userConstants.ROLE_TUTOR) {
        return (
            <Route
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: urlConstants.TUTOR_DASHBOARD_RAW,
                            state: { from: props.location },
                        }}
                    />
                )}
            />
        );
    } else {
        return (
            <Route
                render={(props) => (
                    <Redirect
                        to={{ pathname: urlConstants.LOGIN, state: { from: props.location } }}
                    />
                )}
            />
        );
    }
}
