import React, { useEffect, useState } from 'react';
import SimpleModal from "components/Others/modal/modal";
import NeedStripeVerif from './components/NeedStripeVerif';

import stripeEvents from '_events/stripeEvents';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import ConditionConfirmationModal from "../Modal/conditionsConfirmation/conditionConfirmation";

export default function EventsModal({ }) {
    const [open, setOpen] = useState(true);
    const [event, setEvent] = useState();
    const store = useSelector(store => store);
    const history = useHistory();

    useEffect(() => {
        if (store?.tah?.user?.tutor?.id !== undefined) {
            setEvent(stripeEvents(store));
        }

    }, [store]);

    useEffect(() => {
        setOpen(true);
    }, [history?.location?.pathname])

    switch (event) {
        case "stripe_need_verification":
            return (
                <SimpleModal
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <NeedStripeVerif onRedirect={() => setOpen(false)} />
                </SimpleModal>
            )
        default:
            return (
                <></>
            )
    }

}
