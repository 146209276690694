import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { colorsConstants } from '_constants';
import ButtonAction from '../button/buttonAction/buttonAction';

export async function confirmModal(title = "", onConfirm = () => { return }, id = null, onClose = () => { return }, confirmLabel = "CONFIRMER", cancelLabel = "ANNULER") {

    confirmAlert({
        customUI: ({ onClose: onCloseModal }) => {
            return (
                <div
                    className="rounded-border px-sm-5 p-3 mx-sm-0 mx-3"
                    style={{ border: "solid 3px #4a25aa", backgroundColor: "#ffffff" }}
                >
                    <h1
                        className="police-gilroy-font mb-4 text-center"
                        style={{ fontSize: 110 + "%" }}
                    >
                        {title}
                    </h1>

                    <div className="d-flex justify-content-between">
                        <ButtonAction
                            className="w-100 align-self-center scrollbar-hidden mr-2"
                            color={colorsConstants.GREEN}
                            onClick={() => {
                                onCloseModal();
                                onConfirm(id)
                            }}
                            style={{}} >
                            {confirmLabel}
                        </ButtonAction>
                        <ButtonAction
                            className="w-100 align-self-center scrollbar-hidden ml-2"
                            color={colorsConstants.RED}
                            onClick={() => {
                                onCloseModal();
                                onClose()
                            }}
                            style={{}} >
                            {cancelLabel}
                        </ButtonAction>
                    </div>
                </div >
            );
        },
    });
}