import { userConstants } from "../_constants";
import OptionModel from "./option.model";

export default class UserModel {
    /**
     * @param {number} id
     * @param {string} firstname
     * @param {string} lastname
     * @param gender
     * @param email
     * @param disabled
     */
    constructor(id, firstname, lastname, gender, email, disabled) {
        this.id = id;
        this.lastname = lastname;
        this.firstname = firstname;
        this.gender = gender;
        this.email = email;
        this.disabled = disabled;

        this.convertGender = function () {
            switch (this.gender) {
                case userConstants.GENDER_FEMALE:
                    return "Mme";
                case userConstants.GENDER_MALE:
                    return "M";
                default:
                    return "";
            }
        }
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }

    isEqual(object) {
        if (!!!object) {
            return false;
        }
        return Object.keys(this).every(value => {
            return this[value] === object[value]
        });
    }

    toOptionModel() {
        return new OptionModel().buildFromInstance({
            value: this.id,
            label: `${this.firstname} ${this.lastname}`,
            extra: this,
        })
    }
}
