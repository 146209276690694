
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { appConstants } from '../../../../_constants';
import { Tooltip } from "@material-ui/core";
import { TrendingUpOutlined } from "@material-ui/icons";

class ToolTipProps {
    constructor(title, placement, disabled) {
        return {
            title: title || "",
            placement: placement || "bottom",
            disabled: disabled !== undefined ? disabled : true,
        }
    }
}

function TextFormGroupInput({ onChange, label, value = "", className, inputClassName, labelClassName, placeholder, submitted = false, disabled = false, hideLabel = false, type = "text", required = true, min = null, max = null, readOnly = false, emptyMessage = "", invalidFeedBackMessage = appConstants.DEFAULT_INVALID_FEEDBACK, toolTip: toolTipProps = new ToolTipProps(), onBlur: onBlurProps = () => { return } }) {
    const [inputValue, setInputValue] = useState(value ? value : "");
    const [toolTipOpen, setToolTipOpen] = useState(false);

    useEffect(() => {
        setInputValue(value ? value : "");
    }, [value]);

    useEffect(() => {
        if (!!!toolTipProps.disabled) {
            setToolTipOpen(false);
        }
    }, [toolTipProps.disabled]);

    function handleOnChange(event) {
        setInputValue(event.target.value);
        onChange(event.target.value, label);
    }

    function onOpenToolTip(_event) {
        if (!!!toolTipProps.disabled) {
            setToolTipOpen(true);
        }
    }
    function onCloseToolTip(_event) {
        setToolTipOpen(false);
    }

    function onBlur() {
        onBlurProps(label);
    }

    return (
        <Tooltip title={toolTipProps.title} placement={toolTipProps.placement} open={toolTipOpen} onOpen={onOpenToolTip} onClose={onCloseToolTip} >
            <div className={className ? `form-group ${className}` : "form-group w-100 mx-3 "}>
                {!hideLabel ?
                    <label className={labelClassName ? labelClassName : "text-input-font"}>
                        {label}
                    </label>
                    : null
                }
                {readOnly ?
                    <p className={"police-gte-font " + (inputClassName ? inputClassName : "")}>{inputValue ? inputValue : emptyMessage}</p>
                    :
                    <input
                        className={
                            "form-control input-font " +
                            (submitted && !inputValue
                                ? "is-invalid "
                                : "") + (inputClassName ? inputClassName : "")
                        }
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={handleOnChange}
                        disabled={disabled}
                        type={type}
                        min={min}
                        max={max}
                        onBlur={onBlur}
                    />
                }
                {required ? <div className="invalid-feedback">{invalidFeedBackMessage}</div> : null}
            </div>
        </Tooltip>
    )
}


TextFormGroupInput.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    submitted: PropTypes.bool,
    type: PropTypes.string,
}

TextFormGroupInput.defaultProps = {
    onChange: () => { return }
}

export default TextFormGroupInput;