import React, { useEffect, useState } from 'react';
import ButtonAction from '../../../components/Others/button/buttonAction/buttonAction';
import SelectFormGroupInput from '../../../components/Others/forms/components/selectFormGroupInput';
import TextFormGroupInput from '../../../components/Others/forms/components/textFormGroupInput';
import { ToggleFormGroupInput } from 'components/Others/forms/components/toggleFormGroupInput';
import TextAreaFormGroupInput from '../../../components/Others/forms/components/textAreaFormGroupInput';
import MultiSelectFormGroupInput from 'components/Others/forms/components/multiSelectFormGroupInput';
import FormModel from '../../../_models/form.model';
import LoaderSpinner from 'components/Others/loader/loader';
import { MixinSwal } from 'components/Others/alert/mixinSwal';

import { apiConstants, appConstants, buttonConstants, lessonConstants, titleConstants, userConstants, placeHolderConstants, requestConstants, idConstants } from '../../../_constants';

import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { apiService } from '_api/api.service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import loading from 'components/Others/process/loading';
import { tahActions } from '_actions/tah.actions';
import { customerActions } from '_actions/customer.actions';

import SendIcon from '@material-ui/icons/Send';

class Form extends FormModel {
    constructor(student, availability, beginAt, endAt, comment, duration, lessonPerWeek, address, subjects, isOnline = false, preferences ='none') {
        super();
        this.student = student;
        this.availability = availability;
        this.beginAt = beginAt;
        this.endAt = endAt;
        this.comment = comment;
        this.duration = duration;
        this.lessonPerWeek = lessonPerWeek;
        this.address = address;
        this.subjects = subjects;
        this.isOnline = isOnline;
        this.preferences = preferences;
    }

    toForm() {
        return {
            student: `${apiConstants.API_STUDENTS}/${this.student}`,
            availability: this.availability,
            beginAt: this.beginAt,
            endAt: this.endAt,
            notes: this.comment,
            lessonsDuration: (this.duration / 60).toString(),
            lessonsPerWeek: parseInt(this.lessonPerWeek),
            address: `${apiConstants.API_ADDRESSES}/${this.address}`,
            requestSkills: this.subjects?.map(skill => { return { skill: `${apiConstants.API_SKILLS}/${skill.value}` } }),
            online: this.isOnline,
        }
    }

    completed() {
        return Object.keys(this).every(key => {
            if (key === "comment") {
                return true;
            } else {
                return this[key] != undefined;
            }
        })
    }
}

export default function SOSRequestForm({ }) {
    const [submitted, setSubmitted] = useState(false);
    const [actions, setActions] = useState([]);
    const [form, setForm] = useState(new Form());
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const customerStore = useSelector(store => store?.tah?.user?.customer);
    const studentOptions = useSelector(store => store?.tah?.user?.customer?.students?.map(elt => elt.toOptionModel()));
    const subjectsOptions = useSelector(store => store?.tah?.user?.subjects);
    const addressesOptions = useSelector(store => store?.tah?.user?.customer?.addresses?.map(elt => elt.toOptionModel()));
    const minDate = new Date((new Date()).getTime() + (5 * 86400000)).toISOString().split('T')[0]
    const maxDate = new Date(new Date().setMonth(new Date().getMonth()+8)).toISOString().split('T')[0];

    const dispatch = useDispatch();
    const history = useHistory();

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        init();
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        storeManager();
    }, [customerStore])

    async function init() {
        setForm(new Form().buildFromInstance({ ...form, endAt: maxDate }));
    }

    /** Update store when new data found */
    async function storeManager() {
        const nextActions = [];
        if (typeof customerStore !== "undefined" && actions?.length === 0) {
            if (!!!customerStore?.students) {
                const students = await apiService.getCustomerStudents(customerStore.id);
                nextActions.push(customerActions.setStudents(students));
            }
            if (!!!addressesOptions) {
                const addresses = await apiService.getCustomerAddresses(customerStore.id);
                nextActions.push(customerActions.setAddresses(addresses));
            }
            if (!!!subjectsOptions) {
                const subjects = await apiService.getSkills(1, 0);
                nextActions.push(tahActions.setSubjects(subjects));
            }
            if (nextActions.length > 0) {
                setActions(nextActions);
                dispatch({
                    type: userConstants.TAH_UPDATE_MULTIPLE,
                    value: nextActions
                });
            }
        }
    }

    async function onSubmit() {
        setSubmitted(true);
        if (form.completed()) {
            loading(true);
            await apiService.postRequest(form.toForm()).then(response => {
                if (response) {
                    MixinSwal({
                        icon: appConstants.ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                        text: appConstants.SOS_REQUEST_SUBMITTED_SUCCESS,
                        timer: 2000,
                    }).then(_ => {
                        history.goBack();
                    });
                } else {
                    Swal.fire({
                        icon: appConstants.ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            });
            loading(false);
        } else {
            Swal.fire({
                icon: appConstants.ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            })
        }
    }

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_AVAILABILITIES:
                setForm(new Form().buildFromInstance({ ...form, availability: value }));
                break;

            case appConstants.FORM_LABEL_STARTING_DATE:
                setForm(new Form().buildFromInstance({ ...form, beginAt: value }));
                break;

            case appConstants.FORM_LABEL_ENDING_DATE:
                setForm(new Form().buildFromInstance({ ...form, endAt: value }));
                break;

            case appConstants.FORM_LABEL_GENDER_PREFERENCE:
                setForm(new Form().buildFromInstance({ ...form, preferences: value }));
                break;

            case appConstants.FORM_LABEL_STUDENT:
                setForm(new Form().buildFromInstance({ ...form, student: value }));
                break;

            case appConstants.FORM_LABEL_COMMENT:
                setForm(new Form().buildFromInstance({ ...form, comment: value }));
                break;

            case appConstants.FORM_LABEL_COURSE_DURATION_2:
                setForm(new Form().buildFromInstance({ ...form, duration: value }));
                break;

            case appConstants.FORM_LABEL_NBR_LESSONS:
                setForm(new Form().buildFromInstance({ ...form, lessonPerWeek: value }));
                break;

            case appConstants.FORM_LABEL_ADDRESS:
                setForm(new Form().buildFromInstance({ ...form, address: value }));
                break;

            case appConstants.FORM_LABEL_SUBJECTS:
                setForm(new Form().buildFromInstance({ ...form, subjects: value }));
                break;

            case appConstants.FORM_LABEL_COURSE_TYPE:
                setForm(new Form().buildFromInstance({ ...form, isOnline: value }));
                break;

            default:
                break;
        }
    }

    if (typeof studentOptions === "undefined") {
        return <LoaderSpinner />
    }

    return (
        <div id={idConstants.tutorial.FORM_SOS_REQUEST} className="container w-100 px-5 pb-5 d-flex flex-column" style={{ overflow: "hidden auto", backgroundColor: "#ffffff" }}>
            <h1 className="title pt-xl-5 py-4 text-center mb-4" style={{ fontSize: windowWidth < appConstants.SCREEN_WIDTH_MEDIUM ? "30px" : 40 + 'px' }}>
                {titleConstants.SOS_REQUEST_FORM}
            </h1>

            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_STUDENT} value={form.student} onChange={onChange} options={studentOptions} submitted={submitted} />
            <MultiSelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_SUBJECTS} options={subjectsOptions} onChange={onChange} invalidFeedBackMessage={appConstants.INVALID_FEEDBACK_SELECT_SKILLS_MIN_1} submitted={submitted} />
            <TextFormGroupInput className="mx-0" type="date" min={minDate} label={appConstants.FORM_LABEL_STARTING_DATE} value={form.beginAt} onChange={onChange} submitted={submitted} />
            <TextFormGroupInput className="mx-0" type="date" min={form?.beginAt?.length > 0 ? new Date(new Date(form.beginAt).setDate(new Date(form.beginAt).getDate() + 1)).toISOString().split('T')[0] : null} label={appConstants.FORM_LABEL_ENDING_DATE} value={maxDate} onChange={onChange} submitted={submitted} />
            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_COURSE_DURATION_2} value={form.duration} onChange={onChange} options={lessonConstants.DURATIONS_LIST()} submitted={submitted} />
            <SelectFormGroupInput className="mx-0" type="number" label={appConstants.FORM_LABEL_NBR_LESSONS} value={form.lessonPerWeek} onChange={onChange} options={requestConstants.NBR_LESSONS_OPTIONS} submitted={submitted}/>
            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_GENDER_PREFERENCE} value={form.preferences} options={requestConstants.GENDER_PREFERENCE_OPTIONS} onChange={onChange} submitted={submitted} required />
            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_ADDRESS} value={form.address} onChange={onChange} options={addressesOptions} submitted={submitted} />
            <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_AVAILABILITIES} value={form.availability} onChange={onChange} submitted={submitted} placeholder={placeHolderConstants.AVAILABILITIES} required />
            <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_COMMENT} value={form.comment} onChange={onChange} placeholder={placeHolderConstants.FILL_COMMENTARY} submitted={submitted} />
            <ToggleFormGroupInput className="form-group mx-0" value={form?.isOnline} label={appConstants.FORM_LABEL_COURSE_TYPE} onChange={onChange} submitted={submitted} />

            <ButtonAction className="mx-0 mt-3 align-self-center" onClick={onSubmit} style={{ fontSize: "15px" }} >
                {buttonConstants.FORM_SUBMIT_REPORT}
                <SendIcon className="align-middle ml-2" />
            </ButtonAction>
        </div>
    )
}
