import React, { useEffect } from "react"
import { useSelector } from "react-redux";
import loading from "components/Others/process/loading";
import { apiService } from "../../_api/api.service";

import { urlConstants } from "_constants";

const MAX_RETRIES = 3;

export default function SetupChecking() {
    const userId = useSelector(store => store?.tah?.user?.id);
    
    useEffect(() => {
        loading(true)
        init(0);
    }, [userId])

    async function init(retry) {
        if (userId) {
            if (retry < MAX_RETRIES) {
                // Try MAX_RETRIES times to check if user has payment method: if not, retry because the system may take some time to update.
                setTimeout(async () => {
                    const hasPaymentMethod = await apiService.getCustomerHasPaymentMethodByUserId(userId);
                    if (!hasPaymentMethod) {
                        await init(retry+1);
                    } else {
                        loading(false);
                        window.location.href = urlConstants.CUSTOMER_DASHBOARD;
                    }
                }, retry && 3000);
            } else {
                loading(false);
                window.location.href = urlConstants.STRIPE_SETUP;
            }
        }
    }
    return(
        <div/>
    )
}