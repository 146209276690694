import React from 'react';
import MobileWrapper from './mobile';
import EventsModal from 'components/TutorPage/Events/EventsModal';
//import Ladders from 'components/TutorPage/Modal/ladders/ladders';

import { navbarMenuConstants } from '../../_constants';
import Tutorial from 'components/Tutorial/tutorial';
import NavbarMenu from './navBar/navBar';

import { apiService } from '_api/api.service';
import { useDispatch, useSelector } from 'react-redux';

import { STEPS } from "../Tutorial/Steps/tutor";
import { tahActions } from '_actions/tah.actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function TutorWrapper({ children }) {
    const tutorId = useSelector((store) => store?.tah?.user?.tutor?.id)
    const guide = useSelector((store) => store?.tah?.user?.guide)
    const openTutorial = guide?.active && (STEPS.length > guide?.step ? true : STEPS.length <= guide?.step ? false : true)
    let closeable = guide?.step > STEPS.length
    const dispatch = useDispatch();
    const history = useHistory();

    async function onChange(step) {
        if (step > guide?.step) {
            const nextStep = await apiService.patchTutorStepGuide(tutorId, { stepGuide: step }) || step
            dispatch(tahActions.setStepGuide(nextStep))
        }
        if (step === STEPS.length) {
            history.push("/")
        }
    }

    return (
        <div className="window-size d-flex flex-column">
            <EventsModal />
            {/* <Ladders /> */}
            <Tutorial open={openTutorial} closeable={closeable} activeStep={guide?.step} steps={STEPS} onChange={onChange} />

            <NavbarMenu />

            {/* Mobile Wrapper handles desktop & mobile wrapper */}
            <MobileWrapper tabs={navbarMenuConstants.TUTOR} >
                {children}
            </MobileWrapper>

        </div>
    )
}
