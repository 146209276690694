export const buttonConstants = {
    FORM_ADD_SUBMIT_HOMEWORK: "Ajouter le devoir",
    FORM_SAVE: "Sauvegarder",
    FORM_SAVE_HOMEWORK_AS_DONE: "Devoir fait 😊",
    FORM_SAVE_HOMEWORK_AS_NOT_DONE: "Devoir non fait ☹️",
    FORM_SUBMIT_REPORT: "Envoyer",
    FORM_VALIDATE: "Valider",
    DECLARE_COURSE: "DÉCLARER UN COURS",
    HELP: "BESOIN D'AIDE",
    MY_ADVISOR: "MON CONSEILLER",
    SPONSOR: "PARRAINER €",
    OTHER_SERVICE: "BÉNÉFICIER D'UN AUTRE SERVICE",
    BANK_INFORMATION: "INFORMATIONS BANCAIRES",
    VERIFY_STRIPE_ACCOUNT: "VÉRIFIER MON COMPTE",
    SUBMIT_IBAN: "ENREGISTRER IBAN",
    CREATE_STRIPE_ACCOUNT: "Créer un compte Stripe",
    UPDATE_ON_STRIPE: "Mettre à jour sur Stripe",
    FORM_CONNEXION: "SE CONNECTER",
    ATTESTATION_DOWNLOAD: "Télécharger",
    DOWNLOAD: "Télécharger",
    CLICK_HERE: "Clique ici !",
    NEXT: "Suivant",
    BACK: "Précédent",
    COMPLETED: "Fin !",
}
