import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import "./menuPopover.css"
import { idConstants } from '_constants';

export default function PopoverMenu({ children, id: idProps = null, className = "" }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (open) {
            setAnchorEl(null)
        } else setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div id={idProps} className={className + (open ? " active" : "")} onClick={handleClick}>
            <div >
                <IconButton className="icon-menu" variant="contained" style={{ color: "#ffffff" }} onClick={handleClick}>
                    <MenuIcon fontSize="large" />
                </IconButton>
                {/* <Menu
                    id={id}
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div id="toto">
                        {children.map(child => <MenuItem onClick={handleClose}>{child}</MenuItem>)}
                    </div>
                </Menu> */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {children}
                </Popover>
            </div>
        </div >
    )
}