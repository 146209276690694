
import React, { useEffect, useState } from "react";

export default function CheckboxFormGroupInput({ value: valueProps = false, label, className = "", inputClassName="", style = {}, onChange: onChangeProps = () => { return }, hideLabel = false, submitted = false, activeLabel = true }) {
    const [value, setValue] = useState(valueProps);

    useEffect(() => {
        setValue(valueProps);
    }, [valueProps]);

    function onChange(event) {
        setValue(event.target.value === 'false');
        onChangeProps(event.target.value === 'false', label);
    }

    function onClick(_event) {
        if (activeLabel) {
            const newValue = !value;
            setValue(newValue);
            onChangeProps(newValue);
        }
    }

    return (
        <div className={`${className} form-check`}>
            <input className={
                "form-check-input" +
                (submitted && !value
                    ? "is-invalid "
                    : "") + (inputClassName ? inputClassName : "")
            } type="checkbox" value={value} checked={value} onChange={onChange} style={style} />
            {!hideLabel ?
                <label className="form-check-label" onClick={onClick}>
                    {label}
                </label>
                :
                null}
        </div>
    )
}