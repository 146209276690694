export const tutorConstants = {
    PUT: 'TUTOR_PUT',// #1 Maybe same usage: need to be checked
    DELETE: 'TUTOR_DELETE',
    SET_LADDERS: "SET_LADDERS",
    SET_LADDER: "SET_LADDER",
    SET_PROGRESSION: "SET_PROGRESSION",
    SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
    SET_STUDENTS: "SET_TUTOR_STUDENTS",
    SET_TUTOR_DETAILS: "SET_TUTOR_DETAILS",
    SET_TUTOR: "SET_TUTOR",// #1 Maybe same usage: need to be checked
};
