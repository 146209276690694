import { formCompletion } from "_constants/formCompletion.constants";
import { apiConstants, homeworkConstants } from "../_constants";
import { JSdateToInputDate } from "../_helpers";
import OptionModel from "./option.model";

const FIELD_CREATE_REQUIRED = ["title", "deadline", "description", "subjects"];
const FIELD_UPDATE_REQUIRED = (isCompleted) => {
    if (isCompleted) {
        return ["title", "deadline", "description", "comment", "completionDate", "subjects"];
    } else return ["title", "deadline", "description", "comment", "subjects"];
}

export default class HomeworkModel {

    /**
     * 
     * @param {number} id 
     * @param {string} title 
     * @param {string} deadline - date
     * @param {string} description 
     * @param {boolean} isCompleted 
     * @param {string} comment 
     * @param {date} completionDate 
     */
    constructor(id, title, deadline, description, isCompleted, comment, completionDate, skills) {
        this.id = id;
        this.title = title;
        this.deadline = deadline;
        this.description = description;
        this.isCompleted = isCompleted;
        this.comment = comment;
        this.completionDate = completionDate;
        this.skills = skills;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            if (key === "deadline") {
                this[key] = instance[key] ? JSdateToInputDate(new Date(instance[key])) : undefined;
            } else if (key === "skills") {
                this[key] = instance[key]?.map(skill => new OptionModel(skill.id, skill.title))
            } else this[key] = instance[key];
        })
        return this;
    }

    fieldsCompleted(type) {
        const check = (fieldsRequired) => {
            for (const key in this) {
                if (fieldsRequired?.includes(key)) {
                    if (this[key] && this[key]?.length > 0) {
                        if (key === "deadline") {
                            if (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(this[key])) {
                                continue;
                            } else return formCompletion.HOMEWORK_DATE_FORMAT_ERROR(this[key]);
                        } else continue;
                    }
                    else return formCompletion.DEFAULT_ERROR;
                }
                else {
                    continue;
                }
            }

            return formCompletion.OK;
        }

        switch (type) {
            case homeworkConstants.FORM_CREATE:
                return check(FIELD_CREATE_REQUIRED);

            case homeworkConstants.FORM_CHECK_IN:
                const tes = FIELD_UPDATE_REQUIRED(this.isCompleted)
                return check(tes);

            default:
                return check(FIELD_CREATE_REQUIRED);
        }
    }

    toForm() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            deadline: this.deadline,
            isCompleted: this.isCompleted,
            comment: this.comment,
            completionDate: this.completionDate,
            skills: this.skills.map(skill => `${apiConstants.API_SKILLS}/${skill.value}`),
        }
    }

}