import UserModel from "./user.model"

export class StudentModel extends UserModel{
    /**
     * 
     * @param {UserModel} user 
     * @param {string} level 
     * @param {Array<*>} passions 
     */
    constructor(user = new UserModel(), level, passions, options = []) {
        super(user?.id, user?.firstname, user?.lastname);
        this.level = level;
        this.passions = passions;
        this.options = options;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
          if (key === "level" && "grade" in instance) {
            this.level = instance["grade"]
          } else {
            this[key] = instance[key];
          }
        });
        return this;
      }
}