export default class AdvisorModel {
    constructor(firstname, lastname, phone, email, calendly) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.phone = phone;
        this.email = email;
        this.calendly = calendly;


    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }

    isEqual(advisor) {
        if (!!!advisor) {
            return false;
        }
        return Object.keys(this).every(value => {
            return this[value] === advisor[value]
        });
    }

}