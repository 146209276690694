const { tutorConstants } = require("_constants");

export const tutorActions = {
    setLadders,
    setLadder,
    setProgression,
    setNotifications,
    setStudents,
    setTutorDetails,
    setTutor,
};

function setTutor(tutor) {
    return {
        type: tutorConstants.SET_TUTOR,
        value: tutor,
    }
}

function setLadder(ladder) {
    return {
        type: tutorConstants.SET_LADDER,
        value: ladder,
    }
}

function setLadders(ladders) {
    return {
        type: tutorConstants.SET_LADDERS,
        value: ladders,
    }
}

function setProgression(progression) {
    return {
        type: tutorConstants.SET_PROGRESSION,
        value: progression,
    }
}

function setNotifications(notifications) {
    return {
        type: tutorConstants.SET_NOTIFICATIONS,
        value: notifications,
    }
}

function setStudents(students) {
    return {
        type: tutorConstants.SET_STUDENTS,
        value: students,
    }
}

function setTutorDetails(tutor) {
    return {
        type: tutorConstants.SET_TUTOR_DETAILS,
        value: tutor,
    }
}
