import { isBoolean } from "lodash";
import { customerConstants, tutorConstants, userConstants } from "../_constants";

export function tah(state = {}, action) {
  switch (action.type) {
    case userConstants.TAH_UPDATE_MULTIPLE:
      return action.value.reduce((accState, action) => {
        return tah(accState, action);
      }, state)

    case userConstants.TAH_SET_OPTIONS:
      return {
        user: state.user,
        options: {
          ...state.options,
          ...action.value,
        }
      }

    case userConstants.TAH_UPDATE_ALL:
      if (!!!action.value.tutor && !!!state?.user?.tutor) {
        return {
          options: state.options,
          user:
          {
            ...state.user,
            ...action.value,
          },
        }

      } else return {
        options: state.options,
        user:
        {
          ...state.user,
          ...action.value,
          tutor: {
            ...action.value.tutor,
            ...state?.user?.tutor,
          }
        },
      }

    case userConstants.TAH_UPDATE_REQUESTS_TO_REPORT:
      return {
        options: state.options,
        user: { ...state.user, requestsToReport: action.value }
      };

    case userConstants.TAH_DELETE_ALL:
      return {
        options: state.options,
        user: null
      };

    case userConstants.PROFILE_UPDATE:
      return { ...state, profile: action.value };

    case userConstants.TAH_SET_SUBJECTS:
      return {
        options: state.options,
        user: {
          ...state.user,
          subjects: action.value || state?.user?.subjects,
        }
      };

    case userConstants.TAH_SET_STEP_GUIDE:
      return {
        options: state.options,
        user: {
          ...state.user,
          guide: {
            active: state?.user?.guide?.active,
            step: action.value || state?.user?.guide?.step,
          }
        }
      };
    case userConstants.TAH_SET_GUIDE:
      return {
        options: state.options,
        user: {
          ...state.user,
          guide: {
            active: typeof action.value?.active === "boolean" ? action.value?.active : state?.user?.guide?.active,
            step: action.value?.step || state?.user?.guide?.step,
          }
        }
      };
    
      case userConstants.SET_STRIPE_PROMISE:
      return {
        options: state.options,
        user: {
          ...state.user,
          guide: {
            active: typeof action.value?.active === "boolean" ? action.value?.active : state?.user?.guide?.active,
            step: action.value?.step || state?.user?.guide?.step,
          }
        }
      };

    default:
      return userCustomer(state, action) || userTutor(state, action) || state;
  }
}

function userCustomer(state = {}, action) {
  switch (action.type) {
    case customerConstants.SET_ADDRESSES:
      return {
        options: state.options,
        user: {
          ...state.user,
          customer: {
            ...state?.user?.customer,
            addresses: action.value || state?.user?.customer?.addresses,
          }
        }
      };
    case customerConstants.SET_ADVISOR:
      return {
        options: state.options,
        user: {
          ...state.user,
          customer: {
            ...state?.user?.customer,
            advisor: action.value || state?.user?.customer?.advisor,
          }
        }
      };

    case customerConstants.SET_STUDENTS:
      return {
        options: state.options,
        user: {
          ...state.user,
          customer: {
            ...state?.user?.customer,
            students: action.value || state?.user?.customer?.students,
          }
        }
      };

    default:
      return;
  }
}

function userTutor(state = {}, action) {
  switch (action.type) {
    case tutorConstants.SET_TUTOR:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state.user?.tutor,
            ...action.value,
            students: action.value?.students || state?.user?.tutor?.students,
          }
        }
      };
    case tutorConstants.SET_STUDENTS:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            students: action.value || state?.user?.tutor?.students,
          }
        }
      };
    case tutorConstants.SET_NOTIFICATIONS:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            notificationEnabled: isBoolean(action.value) ? action.value : state?.user?.tutor?.notificationEnabled,
          }
        }
      };
    case tutorConstants.SET_PROGRESSION:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            progression: action.value || state?.user?.tutor?.progression,
          }
        }
      };
    case tutorConstants.SET_LADDERS:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            ladders: action.value || state?.user?.tutor?.ladders,
          }
        }
      };
    case tutorConstants.SET_LADDER:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            ladder: action.value || state?.user?.tutor?.ladder,
          }
        }
      };
    case userConstants.TAH_UPDATE_LESSONS:
      return {
        options: state.options,
        user: { ...state.user, tutor: { ...state?.user?.tutor, lessons: action.value } }
      };
      
    case userConstants.SET_STRIPE_PROMISE:
      return {
        options: state.options,
        user: { 
          ...state.user,
          stripePromise: action.value || state?.user?.stripePromise,
        }
      };

    case tutorConstants.SET_TUTOR_DETAILS:
      return {
        options: state.options,
        user: {
          ...state.user,
          tutor: {
            ...state?.user?.tutor,
            characteristics: action.value.characteristics || state?.user?.tutor?.characteristics,
            transports: action.value.transports || state?.user?.tutor?.transports,
            levels: action.value.levels || state?.user?.tutor?.levels,
            hobbies: action.value.hobbies || state?.user?.tutor?.hobbies,
            subjects: action.value.subjects || state?.user?.tutor?.subjects,
          }
        }
      };

    default:
      return;
  }
}
