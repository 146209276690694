import AddressModel from "./address.model";
import LessonModel from "./lesson.model";
import { StudentModel } from "./student.model";
import UserModel from "./user.model";

export default class RequestModel {

    /**
     *
     * @param {number} id
     * @param {UserModel} student
     * @param {StudentModel} customer
     * @param {UserModel} tutor
     * @param {Array<*>} subjects
     * @param {string} start
     * @param {string} end
     * @param {*} availabilities
     * @param {AddressModel} address
     * @param {*} lessonsPerWeek
     * @param {LessonModel} lesson
     * @param {string} notes
     * @param {string} state
     * @param {Array<*>} applications
     * @param {Array<*>} dislikes
     * @param {*} type
     * @param {Boolean} homeworkRequired
     * @param {Array<*>} options
     * @param selectedTutorGain
     */
    constructor(id, student = new StudentModel(), customer = new UserModel(), tutor = new UserModel(), subjects, start, end, availabilities, address = new AddressModel(), lessonsPerWeek, lesson = new LessonModel(), notes, state, applications, dislikes, type, homeworkRequired = false, options = [], selectedTutorGain = null) {
        this.id = id;
        this.student = student;
        this.customer = customer;
        this.tutor = tutor;
        this.subjects = subjects;
        this.start = start;
        this.end = end;
        this.availabilities = availabilities;
        this.address = address;
        this.lessonsPerWeek = lessonsPerWeek;
        this.lesson = lesson;
        this.notes = notes;
        this.state = state;
        this.applications = applications;
        this.dislikes = dislikes;
        this.type = type;
        this.homeworkRequired = homeworkRequired
        this.options = options
        this.selectedTutorGain = selectedTutorGain;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }

    labelForReport() {
        return `${this.student.firstname} ${this.student.lastname} (${this.customer?.convertGender()}.${this?.customer?.lastname})`;
    }
}
