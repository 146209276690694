import { appConstants } from "_constants/app.constants.js";

const events = {
    ACCOUNT_STRIPE_NEEDS_VERIF: "stripe_need_verification"
}

const stripeEvents = function (store) {
    if (store.tah?.user?.balance === appConstants.DEFAULT_ACCOUNT_STRIPE_NEEDS_VERIF && !!store.tah?.user?.tutor?.stripeVerified) {
        return events.ACCOUNT_STRIPE_NEEDS_VERIF
    }
}
export default stripeEvents;
