import axios from "axios";
import { store, updateTokens } from "../_helpers/store.js";
import { tahService, userService } from ".";
import Swal from "sweetalert2";
import { userActions } from "../_actions/user.actions.js";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
//request interceptor to add the auth token header to requests
api.interceptors.request.use(
    async function (config) {
        // Edit response config
        //on regarde depuis combien de temps le user et le token existe
        var storeData = store.getState();

        if (storeData.authentication.hasOwnProperty("user")) {
            //on regarde si on a un user d'enregistré
            if (typeof storeData.authentication.user?.date_of_token !== "undefined") {
                //on regarde si on a une date pour le token
                var now = Math.round(Date.now() / 1000); // Convert milliseconds into seconds
                var exp = storeData.authentication.user.date_of_token;
                var time = now - exp;
                if (time > 0) {
                    //la durée de vie du token est bientot épuisé ou épuisé on va le refresh
                    const tempInstance = axios.create({
                        baseURL: process.env.REACT_APP_API_URL,
                    });
                    return await tempInstance
                        .get(
                            "/token/refresh?refresh_token=" +
                            storeData.authentication.user.refreshToken,
                            {},
                            {
                                headers: {
                                    Authorization: null,
                                },
                            }
                        )
                        .then((resp) => {
                            updateTokens({ token: resp.data.token, refresh_token: resp.data.refresh_token }, { ...storeData, dispatch: store.dispatch });
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + resp.data.token;
                            config.headers.Authorization = "Bearer " + resp.data.token;
                            return config;
                        })
                        .catch((_error) => {
                            userService.logout();
                            return config;
                        });
                    //si refresh pas possible alors on dit a l'user que la session est expirée
                    //si refresh posssible on enregistre et on continue
                } else {
                    // la durée du vie du token est encore bonne
                    var AUTH_HEADER = "Bearer " + storeData.authentication.user.token;

                    config.headers.Authorization = AUTH_HEADER;
                    return config;
                }
            } else {
                return config;
            }
        } else {
            return config;
        }
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.error(error);
        if (401 === error?.response?.status) {
            /** Remove data store in store **/
            // Remove user's data in store redux.
            await tahService.tah_delete_all();
            // Remove authentification methods in store redux
            await store.dispatch(userActions.logout());
            !Swal.isVisible() && Swal.fire({
                icon: "error",
                title: "Session expirée",
                text: "Votre session est expirée, merci de vous reconnecter.",
            }).then(() => {
                window.location = "/login";
            });
        }
        return Promise.reject(error);
    }
);

//response interceptor to refresh token on receiving token expired error

export default api;
