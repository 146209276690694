import React from "react";
import { Link } from "react-router-dom";

import ButtonAction from "../buttonAction/buttonAction";
import "./buttonSquare.css";

function ButtonSquare({ children, className: classNameProps = "", classNameContent = "", onClick = () => { return }, link, newTab, color, style }) {
    return (
        <div className={`h-100 w-100 ${classNameProps}`}>
            <div className="square">
                <Link to={{ pathname: link }} target={newTab ? "_blank" : null} rel={newTab ? "noreferrer" : null} onClick={onClick}>
                    <ButtonAction className={`${classNameContent} content`} color={color} style={style}>{children}</ButtonAction>
                </Link>
            </div>
        </div >
    )
}

export default ButtonSquare;
