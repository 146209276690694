import React from 'react';
import Carousel from '../../Others/carousel/carousel';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import "./index.css";

export default function MobileWrapper({ children, tabs = [], customer = false }) {
    const history = useHistory();
    const location = useLocation();
    const { tab } = useParams();
    const activeByDefault = tabs?.findIndex(elt => elt.path === location?.pathname || `${elt.path}/${tab}` === location?.pathname);

    function onChange(_title, path) {
        if (path) {
            history.push(path);
        }
    }

    return (
        <>
            {activeByDefault >= 0 ?
                <Carousel className="d-xl-none" onChange={onChange} items={tabs} customer={customer} activeByDefault={activeByDefault} />
                : null
            }
            {children}
        </>
    );
}
