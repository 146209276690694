export default class ErrorModel {
    /**
     * @param {string} status 
     * @param {string} message 
     */
    constructor(status, message) {
        this.status = status ? status : undefined;
        this.message = message ? message : undefined;
        this.error = true;
    }
}