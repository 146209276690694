import React from 'react';
import CustomerWrapper from "./customerWrapper";
import TutorWrapper from './tutorWrapper';

export default function Wrapper({ children, type = "tutor" }) {

    switch (type) {
        case "tutor":
            return (
                <TutorWrapper >
                    {children}
                </TutorWrapper>
            );
        case "customer":
            return (
                <CustomerWrapper >
                    {children}
                </CustomerWrapper>
            );
        default:
            return;
    }
}