import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Wrapper from './wrapper';
import Step from './Steps/step';

import { buttonConstants, appConstants } from '_constants';
import { TYPE } from '_models/step.model';

import "./style.scss";

import Lottie from 'react-lottie';
import userClickAnimation from "../../../src/images/lottie/userClick.json"


export default function Tutorial({ open: openProps = false, steps: tutorialSteps = [], closeable, activeStep: activeStepProps = 0, onClose: onCloseProps = () => { }, onChange: onChangeProps = () => { } }) {
    let anch = useRef(undefined)
    const [open, setOpen] = useState(openProps);
    const [controller, setController] = useState(new AbortController());
    const [activeStep, setActiveStep] = useState(activeStepProps);
    const history = useHistory()
    const theme = useTheme();
    const maxSteps = tutorialSteps.length;
    const realSteps = tutorialSteps.reduce((acc, step) => {
        if (step.type !== TYPE.CLICK) {
            return acc + 1
        } else return acc
    }, 0)

    useEffect(() => {
        if (document.readyState === 'complete' && open) {
            if (!!!anch?.current && anch?.current !== null) {
                anchorHandler(tutorialSteps[activeStep].id);
            } else if (anch?.current !== null) {
                if (!urlManager(false) && anch.current.id === tutorialSteps[activeStep].id) {
                    highlightHandler(anch.current, true)
                }
            }
        }
    })

    useEffect(() => {
        setOpen(openProps)
    }, [openProps])

    useEffect(() => {
        if (Number.isInteger(activeStep) && openProps) {
            lazyStep(activeStep)
            urlManager();
            anchorHandler(tutorialSteps[activeStep].id, true);
        }
    }, [activeStep])

    function lazyStep(currentStep) {
        let step = currentStep;
        while (tutorialSteps[step - 1]?.mobileSkip && !!!screenSize()) {
            step = step - 1
        }
        if (step >= 0) {
            const id = tutorialSteps[step].id
            if (id && document.readyState == 'complete') {
                const element = document.querySelectorAll(`#${id}`);
                let prevAnchor = (element[1] || element[0]); // Très con => c'était pour le format mobile mais les 2 élément sont toujours chargés ([1] est tjrs pris)
                if (prevAnchor && tutorialSteps[step].type !== TYPE.DEFAULT) {
                    const actionEl = tutorialSteps[step].userAction.element(prevAnchor);
                    if (!!actionEl) {
                        actionEl.addEventListener("collapse", (event) => {
                            event.srcElement.click()
                            setTimeout(() => {
                                // Needed to add highlight class after click has been triggered
                                anch.current = highlightHandler(prevAnchor, true)
                            }, 50)
                        }, { once: true })
                    }
                }
            }
        }
    }

    function anchorHandler(id, events = true) {
        if (id === null) {
            anch.current = null;
            return
        }
        if (document.readyState == 'complete') {
            let newAnchor = document.getElementById(id)
            if (!!newAnchor) {
                if (tutorialSteps[activeStep].type === TYPE.CLICK) {
                    const clickEl = tutorialSteps[activeStep].userAction.element(newAnchor)
                    if (tutorialSteps[activeStep].userAction.collapse && clickEl.className.includes("active")) {
                        const event = new Event('collapse');
                        clickEl.dispatchEvent(event);
                    }

                }
                newAnchor = tutorialSteps[activeStep].onLoad(newAnchor);
                events && userActionHanlder(newAnchor);
                newAnchor = highlightHandler(newAnchor, true);
            } else {
                tutorialSteps[activeStep].type === TYPE.MODAL && tutorialSteps[activeStep].onLoad(newAnchor)
                newAnchor = undefined
            }
            anch.current = newAnchor
        }
    }

    function userActionHanlder(anchor) {
        // First abort all "click" events regerated for this elmeent (happenend when go back and then go to element: -1; +1). 
        controller.abort()
        // Then, start up a new controller.
        let nextController = new AbortController()
        setController(nextController)
        if (anchor && tutorialSteps[activeStep].type !== TYPE.DEFAULT) {
            const actionEl = tutorialSteps[activeStep].userAction.element(anchor);
            if (!!actionEl) {
                actionEl.addEventListener(tutorialSteps[activeStep].type || "click", (_event) => {
                    // Allow the click event on the button to be trigger first.
                    setTimeout(() => {
                        handleNext()
                    }, 100)
                }, { once: true, signal: nextController.signal })
                actionEl.addEventListener("collapse", (event) => {
                    event.srcElement.click()
                    setTimeout(() => {
                        // Needed to add highlight class after click has been triggered
                        anch.current = highlightHandler(anch.current, true)
                    }, 100)
                }, { once: true })
            }
        }
    }

    function highlightHandler(anchor, add) {
        document.body.style.overflow = "scroll"
        if (!!anchor) {
            if (add) {
                if (!anchor.className.includes("tutorial-step-highlight")) {
                    anchor.className = `${anchor.className} tutorial-step-highlight ${tutorialSteps[activeStep].type === TYPE.CLICK && !anchor.className.includes(" enabled") ? "enabled" : ""}`
                    const childEl = tutorialSteps[activeStep].userAction?.element(anchor);
                    if (childEl && tutorialSteps[activeStep].type === TYPE.CLICK) {
                        childEl.className = `${childEl.className} ${!childEl.className.includes(" enabled") ? "enabled" : ""}`
                    }
                }
            } else {
                if (anchor.className.includes("tutorial-step-highlight")) {
                    anchor.className = anchor.className.replace(`tutorial-step-highlight ${tutorialSteps[activeStep].type === TYPE.CLICK ? "enabled" : ""}`, "")
                }
            }
        }
        return anchor
    }

    function urlManager(push = true) {
        let needToPush = !!tutorialSteps[activeStep].url && history.location.pathname !== tutorialSteps[activeStep].url

        if (push) {
            needToPush && history.push(tutorialSteps[activeStep].url);
        } else return needToPush && tutorialSteps[activeStep].url;
    }

    function onClose() {
        // Reload default current className before loading next anchor.
        anch.current = highlightHandler(anch.current, false)
        document.body.style.overflow = "hidden"
        setOpen(false);
        onCloseProps();
    }

    const handleNext = async () => {
        onChangeProps(stepPreview(true))
        const step = stepPreview(true);
        if (step === maxSteps) {
            onClose()
        } else {
            setActiveStep(step);
            // Reload default current className before loading next anchor.
            anch.current = highlightHandler(anch.current, false)
        }
    };

    const handleBack = () => {
        setActiveStep(stepPreview(false))
        // Reload default current className before loading next anchor.
        anch.current = highlightHandler(anch.current, false)
    };

    const stepPreview = (next = true) => {
        const inc = next ? 1 : -1;
        let step = activeStep + inc;
        while (step <= maxSteps && tutorialSteps[step]?.mobileSkip && !!!screenSize()) {
            step = step + inc
        }
        return step
    }

    const screenSize = () => {
        return window.innerWidth < appConstants.SCREEN_WIDTH_EXTRA_LARGE ? 0 : 1
    }
    const userStep = () => {
        if (tutorialSteps[activeStep]?.type !== TYPE.DEFAULT) {
            return 0
        } else {
            let result = activeStep
            let acc = 0
            while (acc < activeStep) {
                if (tutorialSteps[acc]?.type === TYPE.CLICK) {
                    result = result - 1
                }
                acc = acc + 1
            }
            return result
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: userClickAnimation,
        renderer: 'svg'
    }
    if (!Number.isInteger(activeStep) || !open) {
        return <></>
    }

    return <Wrapper open={open} closeable={closeable} anchorEl={tutorialSteps[activeStep]?.id === null ? null : anch?.current} onClose={onClose} loading={!!!anch?.current} placement={tutorialSteps[activeStep]?.placement[screenSize()]} >
        <div className="d-flex w-100">
            <Step title={tutorialSteps[activeStep]?.title()} description={tutorialSteps[activeStep]?.description({ steps: realSteps, screenSize: screenSize() })} />
        </div>
        <MobileStepper
            steps={tutorialSteps[activeStep]?.type !== TYPE.DEFAULT ? 0 : realSteps}
            position="static"
            variant="dots"
            activeStep={userStep()}
            className="mobile-stepper font-weight-bold"
            nextButton={
                tutorialSteps[activeStep]?.type === TYPE.CLICK ?
                <div  className="d-flex justify-content-center align-items-center">

                    <Lottie
                        options={defaultOptions}
                        height={60}
                        width={60}
                    />
                </div>
                    :
        <>
            <Button className="d-none d-sm-flex ml-5" size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
                {activeStep === maxSteps - 1 ? buttonConstants.COMPLETED : buttonConstants.NEXT}
                {activeStep === maxSteps - 1 ? null : theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
            <Button className="d-sm-none ml-3" size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
                {activeStep === maxSteps - 1 ? buttonConstants.COMPLETED : null}
                {activeStep === maxSteps - 1 ? null : theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
        </>
            }
        backButton={
            <>
                <Button className="d-none d-sm-flex mr-5" size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    {buttonConstants.BACK}
                </Button>
                <Button className="d-sm-none mr-3" size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </Button>
            </>
        }
        />
    </Wrapper>
}