import React, { Component } from "react";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import Header from "../Header/header.js";
import FormCreatePassword from "../AccountSetup/formCreatePassword";
import FormStripeSetup from "../AccountSetup/formStripeSetup";
import ButtonLoading from "../Others/button/button";
import PasswordReset from "./passwordReset/passwordReset.js";

import loading from "components/Others/process/loading.js";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./connexion.css";

import join from "../../images/join.png";
import join2x from "../../images/join@2x.png";
import join3x from "../../images/join@3x.png";
import { history } from "../../_helpers";
import { urlConstants } from "_constants/url.constants.js";
import { buttonConstants } from "_constants/buttons.constants.js";

class Connexion extends Component {
  state = {
    email: "",
    password: "",
    confirmedPassword: "",
    createPasswordSuccess: false,
    resetPassword: false,
    recoverPassword: this.props.recoverPassword,
  };

  componentDidMount(){
    // Prevent loading launched by others pages.
    loading(false);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleOnLogin = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
      submitted: true,
    });

    if (this.state.email && this.state.password) {
      await this.props.dispatch(
        await userActions.login(this.state.email, this.state.password)
      );
    }
  };

  onPasswordResetMailSend = () => {
    this.setState({
      resetPassword: false,
    });
  };

  onPasswordChanged = () => {
    history.replace("/login");
    this.setState({
      recoverPassword: false,
    });
  };

  formLogin() {
    const { email, password } = this.state;
    return (
      <Form className="d-flex flex-column justify-content-center m-auto h-100" style={{maxWidth: 600}}>
        <h1 className="connexion-title pb-4 text-center mt-sm-0 mt-4">CONNEXION</h1>
        <Form.Group controlId="email">
          <Form.Label className="connexion-label-input">Email</Form.Label>
          <Form.Control
            className="connexion-form-input"
            type="email"
            placeholder="prenom.nom@domaine.fr"
            value={email}
            onChange={this.handleChange}
            style={{ height: 40 + "px" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password" className="mt-4">
          <Form.Label className="connexion-label-input">
            Mot de passe
          </Form.Label>
          <Form.Control
            className="connexion-form-input"
            type="password"
            placeholder="Entrer votre mot de passe"
            value={password}
            onChange={this.handleChange}
            style={{ height: 40 + "px" }}
          ></Form.Control>
        </Form.Group>
        <div className="text-center">
          <Col className="text-right px-0">
            <a
              href="#"
              className="connexion-register"
              style={{ textDecoration: "auto" }}
              onClick={(event) => {
                event.preventDefault();
                this.setState({ resetPassword: true });
              }}
            >
              Mot de passe oublié ?
            </a>
          </Col>
          <ButtonLoading
            className="connexion-button rounded-border mt-4 mb-3 px-4 py-sm-2 py-1"
            onClick={this.handleOnLogin}
          >
            <div className="d-flex" style={{whiteSpace: "nowrap"}} >
              {buttonConstants.FORM_CONNEXION}
              <Image
                src={join}
                srcSet={join + " 1x," + join2x + " 2x," + join3x + " 3x"}
                className="ml-2 connexion-button-img h-100"
                alt="Eddmon"
              />
            </div>
          </ButtonLoading>
        </div>
      </Form>
    );
  }

  render() {
    const { resetPassword, recoverPassword } = this.state;
    return (
      <div className="window-size">
        <Header />
        <Container fluid className="connexion-container nopadding">
          <Row className="connexion-container nopadding">
            <Col className="align-self-center">
              {this.props.isFirstLogin ||
              (this.props.recoverPassword && recoverPassword) ? (
                <FormCreatePassword
                  recoverPassword={this.props.recoverPassword}
                  onPasswordChanged={this.onPasswordChanged}
                />
              ) : this.props.isStripeSetup ? (
                <FormStripeSetup
                  urlToReload={`${process.env.REACT_APP_URL}${urlConstants.SETUP_CHECKING}`}
                  isSetUp
                />
              ) : resetPassword ? (
                <PasswordReset onSuccess={this.onPasswordResetMailSend} />
              ) : (
                this.formLogin()
              )}
              {this.props.isFirstLogin || this.props.isStripeSetup ? null : (
                <Container>
                  <Row className="text-center">
                    <Col className="col-12">
                      <a
                        href={urlConstants.SUBSCRIBE_COURSES}
                        className="connexion-register"
                      >
                        Non inscrit, je souhaite prendre des cours ?
                      </a>{" "}
                    </Col>
                    <Col className="col-12">
                      <a
                        href={urlConstants.BECOME_TUTOR}
                        className="connexion-register"
                      >
                        Non inscrit, je souhaite devenir tuteur ?
                      </a>
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Connexion.propTypes = {
  isFirstLogin: PropTypes.bool,
  isStripeSetup: PropTypes.bool,
};

Connexion.defaultProps = {
  isFirstLogin: false,
  isStripeSetup: false,
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps)(Connexion);
