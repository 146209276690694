import { durationListGenerator } from "../_helpers";

export const lessonConstants = {
    DURATIONS_LIST: () => { return durationListGenerator(15, 300, 15) },
    DURATION_MAX: 600, // in minutes
    GRADE_MAX: 10,
    WEEKDAYS: ["LUNDI", "MARDI", "MERCR.", "JEUDI", "VEND.", "SAMEDI", "DIMAN."],
    HOUR_RANGE: ["7h-10h", "10h-13h", "13h-16h", "16h-19h", "19h-22h"],
    FORMAT: (isOnline) => isOnline ? "En ligne" : "À domicile",
};
