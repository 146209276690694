export default class LadderModel {
    constructor(rank, firstname = "", picture, points){
        this.rank = rank;
        this.firstname = firstname;
        this.picture = picture;
        this.points = points;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }

    isEqual(object) {
        if (!!!object) {
            return false;
        }
        return Object.keys(this).every(value => {
            return this[value] === object[value]
        });
    }
}