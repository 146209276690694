import React, { useEffect, useState } from "react";
import TextFormGroupInput from "components/Others/forms/components/textFormGroupInput";
import SimpleModal from "../../../components/Others/modal/modal";

import { appConstants, modalConstants } from "../../../_constants";
import { IconButton } from "@material-ui/core";
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';

import CloseIcon from '@material-ui/icons/Close';

export default function AdvisorModal({ open: openProps = false, advisor, onClose: onCloseProps = () => { return } }) {
    const [open, setOpen] = useState(openProps);

    useEffect(() => {
        setOpen(openProps)
    }, [openProps])

    return (
        <SimpleModal
            open={open}
            onClose={onCloseProps}
            loading={typeof advisor === "undefined"}
        >
            <div className="form d-flex flex-column p-3 form-container mb-3 mt-3 col-lg-6 col-md-8 col-sm-4 col-11 mx-auto w-100" style={{ top: "1%", height: "95vh", overflow: "hidden scroll", transform: "translateY(0px)" }}>
                <div className="d-flex justify-content-end">
                    <IconButton className="p-1 outline-none" onClick={() => onCloseProps()} style={{ borderRadius: ".25rem" }}>
                        <CloseIcon style={{ outline: "none !important", border: "none !important" }} />
                    </IconButton>
                </div>
                <div className="d-flex justify-content-center">
                    <AccessibilityNewOutlinedIcon />
                    <h4 className="text-center mb-4 mx-2">{modalConstants.ADVISOR_TITLE}</h4>
                    <AccessibilityNewOutlinedIcon />
                </div>
                <div className="d-flex justify-content-between" >
                    {/* <div className="col-3 d-flex align-items-center justify-content-center">
                        <Avatar className="" />
                    </div> */}
                    <div className="w-100 align-self-center" >
                        {/* <TextFormGroupInput className="d-flex justify-content-between mb-0" inputClassName="text-blue" readOnly label={appConstants.FORM_LABEL_FIRSTNAME} value={advisor?.firstname} />
                        <TextFormGroupInput className="d-flex justify-content-between mb-0" inputClassName="text-blue" readOnly label={appConstants.FORM_LABEL_LASTNAME} value={advisor?.lastname} />
                         */}
                        <TextFormGroupInput className="d-flex justify-content-between mb-0" inputClassName="text-blue font-weight-bold" readOnly label={appConstants.FORM_LABEL_MAIL} value={advisor?.email} />
                        <TextFormGroupInput className="d-flex justify-content-between mb-0" inputClassName="text-blue font-weight-bold" readOnly label={appConstants.FORM_LABEL_PHONE} value={advisor?.phone} />
                    </div>
                </div>
                <iframe src={advisor?.calendly} height={"100%"} />
            </div>
        </SimpleModal>
    )
}