export default class PaginationModel {
    constructor(page, values, pageTotal) {
        this.page = page;
        this.values = values;
        this.pageTotal = pageTotal;
    }

    buildFromInstance(instance = new PaginationModel()) {
        Object.keys(this).forEach((key) => {
          this[key] = instance[key];
        });
        return this;
      }
}