const { userConstants } = require("_constants");

export const tahActions = {
    setSubjects,
    setOptions,
    setStepGuide,
    setGuide,
    setStripePromise,
};

function setSubjects(subjects) {
    return {
        type: userConstants.TAH_SET_SUBJECTS,
        value: subjects,
    }
}

function setOptions(options) {
    return {
        type: userConstants.TAH_SET_OPTIONS,
        value: options,
    }
}

function setStepGuide(step) {
    return {
        type: userConstants.TAH_SET_STEP_GUIDE,
        value: step,
    }
}

function setGuide(guide) {
    return {
        type: userConstants.TAH_SET_GUIDE,
        value: guide,
    }
}

function setStripePromise(stripePromise) {
    return {
        type: userConstants.SET_STRIPE_PROMISE,
        value: stripePromise,
    }
}
