import React, { useEffect, useState } from "react";

import { modalConstants } from "../../../../_constants";
import SimpleModal from "../../../Others/modal/modal";
import "./conditionConfirmation.css"
import {apiService} from "../../../../_api/api.service";
import {useDispatch, useSelector} from "react-redux";
import {tutorActions} from "../../../../_actions/tutor.actions";
import {formatDate} from "../../../../_helpers";

export default function ConditionConfirmationModal({ open: openProps = false, onClose: onCloseProps = () => { return } }) {
    const [open, setOpen] = useState(openProps);
    const tutorStore = useSelector((store) => store.tah?.user?.tutor);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [infosPrices, setInfosPrices] = useState(undefined);

    async function init() {
        await apiService.getTutorPrices().then(data => {
            if (data !== false)
                setInfosPrices(data);
        });
    }

    useEffect(() => {
        setOpen(openProps)
        init();
    }, [openProps])

    async function onSubmit(hasAcceptedConditions = false) {
        setDisabled(true);
        await apiService.patchTutorAcceptedConditions(tutorStore?.id, hasAcceptedConditions)
            .then(_ => {
                tutorStore.hasAcceptedConditions = hasAcceptedConditions;
                dispatch(tutorActions.setTutor(tutorStore));
                setDisabled(false);
                setOpen(false)
                onCloseProps();
            });
    }

    return (
        <SimpleModal
            open={open}
            onClose={onCloseProps}
            autoClose={false}
        >
            <div className="condition-modal d-flex flex-column p-3 form-container mb-3 mt-3 col-lg-6 col-md-8 col-sm-4 col-11 mx-auto w-100">
                <div className="condition-modal--header d-flex justify-content-center modal-header">
                    <h4 className="text-center mb-4 mx-2">
                        {modalConstants.NEW_CONDITIONS} <br/><br/>
                        {modalConstants.NEW_CONDITIONS_SUBTITLE}
                    </h4>
                </div>
                <div className="w-100 d-flex justify-content-center" >
                    <ul>
                        <li>
                            {modalConstants.CONDITION_1}
                        </li>
                        <li>
                            {modalConstants.CONDITION_2}
                        </li>
                        <li>
                            {modalConstants.CONDITION_3}
                        </li>
                    </ul>
                </div>
                <div className="w-100 mt-3">
                    <div style={{maxHeight: "40vh", overflowY: "scroll", border: '1px solid black', padding: '1rem'}}>
                        <div className="text-center">
                            <img width="418.9" height="139.65"
                                 src="https://uploads-ssl.webflow.com/62e7db7cc9c73e6d6a8e326b/62fa54e718d02f42497f365a_Group.svg"/>
                        </div>
                        <br/><br/><br/><h1>CONTRAT EDDMON DE PRESTATION DE SERVICES</h1><br/><br/>ENTRE LES SOUSSIGNES :<br/>EDDMON,
                        société́ par actions simplifiées, au capital de 10.000 €, immatriculée au RCS de PARIS sous le
                        n°839 206 836, dont le siège social est situé au 17 rue trousseau Paris (75011), représentée
                        par Monsieur Quiniou, en sa qualité́ de Président ayant tous deux les pouvoirs aux fins des
                        présentes,<br/><br/>Ci-après désignée la « Société́ », d'une part, <br/>ET,<br/>Madame,
                        Monsieur {tutorStore?.user?.firstname} {tutorStore?.user?.lastname}, né
                        le {formatDate(new Date(tutorStore?.birthdate))}, de nationalité française, demeurant
                        au {tutorStore?.number + ' ' + tutorStore?.address + ', ' + tutorStore?.zipcode + ' ' + tutorStore?.city} /
                        Micro-entreprise immatriculée sous le Numéro {tutorStore?.siret}, dont le siège social est
                        sis {tutorStore?.number + ' ' + tutorStore?.address + ', ' + tutorStore?.zipcode + ' ' + tutorStore?.city}<br/>Ci-après
                        désigné le «Prestataire»,<br/><br/>Ci-après collectivement dénommées les « Parties » ou
                        individuellement, la « Partie ».<br/><br/><br/>IL A ETE PREALABLEMENT EXPOSE CE QUI SUIT
                        :<br/><br/><br/>La société EDDMON propose un service de mise en relation entre des étudiants
                        (Prestataire) souhaitant enseigner et des Clients à la recherche de cours particuliers.<br/>La
                        plateforme internet de la société́ EDDMON permet aux Clients de se voir attribuer un Prestataire
                        en fonction de sa localisation et de certaines caractéristiques renseignées sur sa fiche ainsi
                        que de solliciter la réalisation d’une prestation d’enseignement portant sur différentes
                        matières scolaires proposées. <br/><br/>La plateforme EDDMON transmet ensuite ces sollicitations
                        au Prestataire d’enseignement inscrit afin qu’ils puissent intervenir. <br/>C’est dans ce
                        contexte que les Parties se sont rapprochées afin d’arrêter et de formaliser les conditions et
                        modalités de leur accord.<br/><br/>CECI AYANT ETE RAPPELE, LES PARTIES SONT EXPRESSÉMENT
                        CONVENUES DE CE QUI SUIT : <br/><br/>TITRE 1 – DISPOSITIONS GÉNÉRALES<br/><br/>Article 1 –
                        DÉFINITIONS<br/>Client(s) : désigne tout utilisateur de la plateforme qui sollicite un
                        Prestataire inscrit pour la réalisation d’une prestation d’enseignement.<br/>Commission :
                        désigne le pourcentage prélevé́ en amont par la Société́ sur le prix remis par le Client pour la
                        réalisation d’une prestation d’enseignement, avant que celui-ci ne soit versé au
                        Prestataire.<br/>Contrat : désigne le présent contrat, y compris son préambule, à l’exclusion
                        de tout autre support.<br/>Droits de propriété intellectuelle : désigne l’ensemble des droits de
                        propriété intellectuelle tels que prévus le plus largement par le Code de la propriété́
                        intellectuelle, les conventions nationales et internationales.<br/>Élève : désigne la personne
                        qui bénéficie réellement de la Prestation, sans considération de savoir avec qui le Prestataire
                        a contracté.<br/>Internet : désigne différents réseaux de serveurs localisés en divers lieux à
                        travers le monde, reliés entre eux à l'aide de réseaux de communication et communiquant à
                        l'aide d'un protocole spécifique connu sous le nom de TCP/IP. <br/>Plateforme : désigne le site
                        internet « EDDMON » permettant au Prestataire de s’y référencer et au Client de solliciter un
                        Prestataire, localisé à l’adresse « www.eddmon.fr ». <br/>Prestation(s) ou Mission(s) :
                        désigne la prestation de services qu’un Prestataire est susceptible de fournir à un Client
                        consistant à dispenser des cours dans une matière scolaire. <br/>Prix total : désigne le
                        montant remis par le Client à la Société́ avant imputation de la Commission de cette dernière
                        et avant que le solde ne soit versé au Prestataire. <br/>Prix : désigne le montant reversé par
                        la Société́ au Prestataire en raison de la prestation réalisée, après déduction de la Commission
                        de la Société́. <br/>Tiers : désigne toute entité́, personne morale ou physique, extérieure à
                        la Société́ et au titre de laquelle ce dernier n’assume aucune garantie, responsabilité́ ni
                        représentation. <br/><br/>Article 2 – OBJET DU CONTRAT <br/>2.1 - Le présent Contrat fixe le
                        cadre juridique et opérationnel dans lequel s’inscrivent les relations commerciales entre la
                        Société́ et le Client, consistant pour la première, en sa qualité́ d’intermédiaire, à mettre en
                        relation le Client avec des Prestataire en contrepartie d’une Commission consentie par le
                        Prestataire.<br/>2.2 - A cet effet, le fonctionnement général consiste, pour la Société́, à
                        mettre à disposition du Client sa Plateforme internet sur laquelle ce dernier devra s’inscrire
                        pour pouvoir être mis en relation avec un Client à fin de recevoir un soutien scolaire. <br/>A
                        ce titre, la Société permet également, par le biais de la Plateforme, l’édition de la
                        facturation pour les Prestataires et les Clients, de procéder aux encaissements et aux
                        reversements de prix des Prestations ainsi que d’assurer le suivi des réclamations
                        éventuelles.<br/>Le Client est libre d’accepter ou non d’être mis en relation avec le
                        Prestataire proposé par la Société́. Cependant, le Client ne pourra remettre en cause le
                        service proposé par la Société ", celle-ci ayant obéi à toutes ses obligations. <br/>2.3 - A
                        l’issue de la Prestation, le Client versera le Prix total à la Société́ qui en sera dépositaire
                        et qui le reversera au Prestataire après imputation de sa Commission. <br/><br/>Article 3 –
                        DUREE DU CONTRAT<br/>Le présent Contrat est conclu pour une durée indéterminée et prend effet à
                        compter de la date de sa signature par les Parties. <br/><br/>TITRE 2 – FONCTIONNEMENT DE LA
                        PLATEFORME<br/><br/>Article 4 – PROCESSUS D’INSCRIPTION<br/>4.1 - Il est rappelé que
                        préalablement à la signature du présent Contrat, le Client s’est rendu sur la Plateforme de la
                        Société et, après avoir suivi un processus d’inscription, a été ​amené à fournir à la Société
                        toutes les informations nécessaires concernant son identité.<br/>4.2 - Aux termes des présentes,
                        le Prestataire reconnaît également expressément et réitère que les informations qu’il a fournies
                        lors de l’étape d’inscription sont exhaustives et sincères.<br/>Le Prestataire reconnaît qu’au
                        cas où la Société́ découvrirait que les informations fournies sont trompeuses ou inexactes,
                        elle dispose de la faculté́ de procéder à la résiliation immédiate du présent Contrat dans les
                        termes de l’article 15.3 sans préjudice de dommages et intérêts qui pourraient être
                        réclamés.<br/><br/>Article 5 – MODALITES D’ATTRIBUTION DES MISSIONS<br/>5.1 - Après la phase
                        d’inscription et de signature du Contrat, la Société pourra transmettre au Prestataire les
                        sollicitations des Clients de manière à ce qu’il puisse accomplir des Missions.<br/>Si une ou
                        plusieurs missions intéressent le Prestataire, il pourra envoyer une demande en cliquant sur
                        “postuler” pour démontrer son intérêt pour ces missions auprès de la société́.<br/>5.2 - Les
                        Parties s’accordent sur le fait que la Société́ n’a aucune obligation de proposer un minimum de
                        Missions au Prestataire. 5.3 - Lorsque le Prestataire aura démontré́ son intérêt pour une ou
                        plusieurs propositions de cours, le conseiller peut joindre le Prestataire pour confirmer sa
                        motivation et son intérêt pour la mission. <br/>5.4 - Si le conseiller de la Société́ estime,
                        pour des raisons discrétionnaires, que le Prestataire n’est pas apte à assurer les Prestations
                        d’enseignement, il aura la faculté́ de ne pas confier la Mission envisagée à ce dernier sans
                        que celui-ci ne puisse prétendre à des indemnités, à la résiliation ou des recours. <br/>5.5 -
                        Dans le cas où le conseiller décide de confier la Mission au Prestataire, il transmettra au
                        Prestataire et au Client leurs coordonnées respectives afin qu’ils puissent prendre contact et
                        convenir d’un rendez-vous pour l’accomplissement de la Mission. Le Prestataire s’engage alors à
                        exécuter sa Mission selon les modalités prévues à l’article 7 du présent Contrat « Réalisation
                        des Missions par le Prestataire » et notamment à ne pas abandonner sa mission avant d’avoir
                        effectué au moins cinq prestations par Client. <br/>5.6 - Après la réalisation d’une Mission, le
                        Prestataire devra se connecter sous sept (7) jours à l’aide de son login et de son mot de passe
                        sur le site internet de la Société́ afin de valider que le cours a été́ effectué ainsi qu’un
                        retour sur les activités avec son élève.<br/><br/>TITRE 3 – LES OBLIGATION DU
                        PRESTATAIRE<br/><br/>Article 6 – LES OBLIGATION ADMINISTRATIVES ET FISCALES<br/><br/>6.1 –
                        Immatriculation en qualité d'auto-entrepreneur ou sous une autre forme légalement
                        autorisée.<br/>6.1.1 - Les prestataires exerçant une activité́ commerciale ou artisanale à
                        titre habituel sont dans l’obligation de s’immatriculer, conformément aux dispositions de la Loi
                        n°2001-626 du 18 juin relative à l’artisanat, au commerce et aux très petites entreprises.
                        6.1.2 - Le Client s’engage par conséquent à remettre spontanément à la Société́ au moment de
                        la signature du présent Contrat tous les documents justifiant de l’accomplissement de ses
                        obligations administratives d’immatriculation et tous ceux qui pourraient lui être demandés
                        (notamment numéros SIRET et SAP).<br/>6.1.3 - La réalisation des formalités est effectuée par le
                        Prestataire sous sa seule responsabilité́ et la Société́ ne pourrait être tenue responsable pour
                        toute omission ou défaut d’information dans ce cadre. <br/><br/><br/>6.2 – L’obligation de
                        déclaration du Prestataire<br/>6.2.1 - Pour ouvrir droit aux avantages fiscaux des Clients, le
                        Prestataire dispensant des cours à domicile doit déclarer son activité conformément aux
                        articles L. 7232-1 à L. 7232-24 et D. 7231-1 du Code du Travail. <br/>6.2.2 - Le Prestataire
                        s’engage expressément à se conformer aux obligations résultant des dispositions ci-dessus
                        mentionnées. A cet égard, il confie un mandat à la Société́ pour établir les attestations
                        destinées aux Clients, dans le respect des dispositions légales, conformément aux termes de
                        l’article 9.2.2. <br/>6.2.3 - Le Prestataire peut notamment se renseigner de manière détaillée
                        sur la manière d’effectuer la déclaration précitée, permettant à ses Clients d’obtenir des
                        avantages fiscaux, sur le site internet de la Chambre de Commerce et d’Industrie de Paris à
                        l’adresse suivante : <br/>«
                        http://www.entreprises.cci-paris-idf.fr/web/reglementation/activites-reglementees/sap/agrement-declaration.
                        ».<br/>6.2.4 - Le contenu et la réalisation des déclarations est effectué par le Prestataire
                        sous sa seule responsabilité́ et la Société́ ne pourrait être tenue responsable pour toute
                        omission ou défaut d’information dans ce cadre.<br/><br/>6.3 – La vérification de
                        l’accomplissement des obligations administratives par la Société́<br/>6.3.1 - Le Prestataire
                        pourra être sollicité téléphoniquement par la Société́ pour déterminer s’il est à jour de ses
                        obligations administratives mentionnées aux articles 6.1 « Immatriculation en qualité́
                        d’auto-entrepreneur ou sous une autre forme légalement autorisée » et 6.2 « L’obligation de
                        déclaration du Client » du présent Contrat.<br/>6.3.2 - Si le Prestataire ne remplit pas les
                        obligations administratives requises, il ne pourra se voir attribuer de Missions et son compte
                        sera suspendu par la Société́ laquelle lui accordera un délai de deux (2) mois pour accomplir
                        les formalités. Si à l’issue de ce délai, le Prestataire n’a pas accompli les formalités
                        requises, la Société́ pourra résilier le Contrat sans indemnités de part et d’autre,
                        conformément aux termes de l’article 15.3.<br/>6.3.3 – À tout moment, la Société́ pourra
                        recommander au Prestataire un Tiers pour l’assister dans la réalisation des obligations et
                        formalités. Il s’agira d’une simple recommandation non contraignante que le Prestataire sera
                        libre de refuser pour les accomplir par ses propres moyens. <br/><br/><br/>6.4 – L’obligation de
                        fournir des informations sincères, exactes et exhaustivesEn tout temps et dans le cadre de tous
                        rapports avec la Société́, le Prestataire s’engage à transmettre des informations sur ses
                        diplômes, sa situation, son identité́ et sur l’exécution des Prestations de manière sincère,
                        exacte et exhaustive. Tout manquement à cet égard engagera la responsabilité́ du Prestataire et
                        pourra donner lieu à résiliation dans les termes de l’article 15.3.<br/><br/>Article 7 –
                        REALISATION DES MISSIONS PAR LE PRESTATAIRE<br/>7.1 - Le Prestataire s’engage à assurer
                        l’exécution auprès des Clients des Missions qu’il a accepté́ de prendre en charge dans les
                        conditions de l’article 5 « Modalités d’attribution des missions » du présent Contrat,
                        consistant à dispenser un enseignement sous forme de soutien scolaire ou d’assistance en
                        informatique aux Clients, en fonction des besoins qu’ils ont exprimés et dans les conditions
                        qu’ils ont souhaitées. Il s’engage pour chaque mission acceptée à destination d’un Client à
                        effectuer un minimum de cinq prestations d’affilées.<br/>7.2 - Le Prestataire est libre des
                        moyens et outils employés dans le cadre de sa Mission mais s’engage cependant à l’exécuter
                        conformément aux règles de l’art et de la meilleure manière. Le Prestataire s'engage également
                        expressément à dispenser les cours exclusivement au domicile des Clients.<br/>7.3 - Notamment,
                        mais pas seulement, dans le cadre du respect de la qualité́ des Prestations, le Prestataire
                        devra :<br/>
                        <ul>
                            <li>mettre en place une organisation satisfaisante, lui permettant de dispenser
                                régulièrement les cours objet de sa Mission, en accord avec les disponibilités du Client
                                ;
                            </li>
                            <li>à entre ponctuel, courtois et à se présenter au domicile du Client dans une tenue
                                soignée ;
                            </li>
                            <li>à n’introduire au domicile du Client aucune boisson alcoolisée ou substance interdite
                                ;
                            </li>
                            <li>à communiquer régulièrement avec le Client pour lui permettre de suivre l’évolution et
                                les progrès de l’Élève ;
                            </li>
                            <li>à faire preuve de pédagogie envers l’Élève et à s’efforcer de faire réaliser des
                                progrès à l’Élève en matière scolaire et/ou en informatique.
                            </li>
                        </ul>
                        <br/>Les principes directeurs qui précèdent constituent essentiellement une orientation générale
                        permettant de s’assurer que les Prestations accomplies en toute indépendance par les
                        Prestataires répondent à des standards de qualité́. Ils ne représentent pas des consignes ou
                        des directives de contrainte vis-à-vis du Prestataire.<br/>7.4 - En cas d’empêchement, le
                        Prestataire est tenu de prévenir le Client au minimum dans un délai de 48 heures avant la date
                        de sa Prestation d’enseignement et reprogrammer son intervention en concertation avec le Client
                        afin d’accomplir sa Mission. <br/>Si le Prestataire estime, pour quelque raisons que ce soit, ne
                        plus être en mesure de dispenser régulièrement ses cours à domicile, il devra en informer
                        immédiatement la Société et au minimum 48 heures avant sa prochaine et dernière Mission afin que
                        la Société ​ soit en mesure de procéder à son remplacement.<br/>7.5 - Si le Prestataire ne
                        respecte pas les délais de prévenance susmentionnés ou s’il rencontre une quelconque difficulté
                        avec un Client, la Société́ se réserve le droit de résilier immédiatement le présent Contrat
                        dans les conditions de l’article 15.3.<br/>7.6 - De manière générale, le Prestataire devra
                        informer la Société de toute difficulté́ qui pourrait survenir dans l’accomplissement de ses
                        Missions ou, plus généralement, dans l’exécution du présent Contrat.<br/>7.7 - Il est enfin
                        convenu et accepté par le Prestataire, que le Client demeure libre de changer de prestataire à
                        tout moment sans indemnité́ aucune due au Prestataire.<br/>7.8 – Le Prestataire accepte que les
                        Clients publient un avis portant sur ses Prestations, accessibles sur la Plateforme. Si le
                        Prestataire estime que l’avis porte atteinte à ses droits, il devra en référer à la Société
                        afin que cette dernière évalue la légitimité́ de la requête en suppression. La Société informera
                        le Prestataire de la décision prise qui demeure discrétionnaire. <br/>Le Prestataire accepte
                        également que les avis qu’il laisse sur les Clients soient transmis à ces derniers. <br/><br/>TITRE
                        4 – OBLIGATIONS DE LA SOCIÉTÉ<br/><br/>Article 8 – MISE A DISPOSITION DE LA PLATEFORME<br/>8.1 -
                        La Société́ consent à tout mettre en œuvre et à faire ses meilleurs efforts pour assurer le
                        bon fonctionnement de sa Plateforme afin de permettre la délivrance de ses services
                        d’intermédiation de la meilleure des manières. A ce titre, elle permet au Prestataire de
                        s’inscrire sur sa Plateforme et d’accéder à son compte personnel par le biais de son login et
                        mot de passe.<br/>8.2 - La Société́ n’est néanmoins pas tenue de présenter au Prestataire un
                        nombre minimum de Missions en raison du caractère fluctuant des demandes des Clients, tenant
                        tant aux matières enseignées qu’au niveau des Élèves.<br/><br/>Article 9 – PAIEMENT DU PRIX ET
                        FRAIS <br/><br/>9.1 – Paiement du Prix<br/><br/>9.1.1 - Le Prestataire est mis en relation avec
                        un Client ayant opté pour le paiement par remboursement différé de crédit d’impôt.<br/><br/>9.1.1.1
                        - Le Prestataire facturera le Prix total aux Clients suivant le barème horaire ci-après : <br/>

                        <ul>
                            {infosPrices?.map(prices => (
                                <li>Prestation pour un enseignement de niveau « {prices.name} » {prices.type === "home" ? 'à domicile' : 'en ligne'} : {prices.price} € /h TTC ;</li>
                            ))}
                        </ul>

                        Ces prix sont fixés discrétionnairement par la Société et sont susceptibles d’évolution en
                        fonction du marché et feront l’objet d’un avenant au présent contrat en cas de
                        modification.<br/>9.1.1.2 – Dans un premier temps, après l’accomplissement de sa Mission, le
                        Prestataire s’oblige, dans un délai de sept (7) jours maximum, à faire un rapport de son
                        intervention dans la rubrique « Reporting » accessible sur la Plateforme à partir de son espace
                        personnel. La mise en ligne par le Prestataire de son rapport conditionne expressément son droit
                        au paiement du Prix. <br/>Un compte-rendu de Mission sera concomitamment adressé par la
                        Société́ au Client, laissant à ce dernier la possibilité́ d’émettre une réclamation sous
                        vingt-quatre (24) heures.<br/>9.1.1.3 – Dans un second temps, après mise en ligne du rapport par
                        le Prestataire sur la Plateforme, le Client versera le Prix total à la Société́ sur le compte
                        de son prestataire de paiement (société́ STRIPE, https://stripe.com/) qui en sera, dans un
                        premier temps, le dépositaire pour le compte du Prestataire. <br/>Dans un délai de sept (7)
                        jours après écoulement du délai de réclamation du Client et uniquement en l’absence d’une telle
                        réclamation et à la condition que le Prestataire ait effectué cinq prestations d’affilé, la
                        Société́ donnera les consignes à son prestataire de paiement pour reverser le Prix au
                        Prestataire, correspondant au Prix total versé par le Client déduction faite de la Commission
                        prélevée par la Société́. <br/>En cas de réclamation émise par le Client, la Société devra
                        restituer le Prix total au Client, en informant le Prestataire de cette réclamation, lequel fera
                        son affaire personnelle dudit litige et du recouvrement des sommes dès lors qu’il agit en
                        qualité de prestataire indépendant. <br/>9.1.1.4 - Le Prestataire accepte que la Commission de
                        la Société́ soit fixée aux montants suivants :<br/>9.1.1.4.1 - Dans le cas où le tuteur a
                        effectué 35h de cours ou plus et a parrainé un nouveau tuteur (inscrit sur la plateforme Eddmon)
                        :<br/>

                        <ul>
                            {infosPrices?.map(prices => (
                                <li>Commission sur une Prestation pour un enseignement de niveau « {prices.name} » {prices.type === "home" ? 'à domicile' : 'en ligne'} : {prices.price - prices.majoredTutorGain} € /h TTC ;</li>
                            ))}
                        </ul>

                        <br/>9.1.1.4.2 - Dans le cas où le tuteur a effectué moins de 35h de cours :<br/>

                        <ul>
                            {infosPrices?.map(prices => (
                                <li>Commission sur une Prestation pour un enseignement de niveau « {prices.name} » {prices.type === "home" ? 'à domicile' : 'en ligne'} : {prices.price - prices.tutorGain} € /h TTC ;</li>
                            ))}
                        </ul>

                        Ces prix sont fixés
                        discrétionnairement par la Société et sont susceptibles d’évolution en fonction du marché et
                        feront l’objet d’un avenant au présent contrat en cas de modification.<br/><br/>9.1.1.5 - Toute
                        Prestation annulée ou ayant fait l’objet d’une réclamation du Client ne donnera pas lieu au
                        paiement du Prix.<br/>9.1.1.6 - Le prix perçu sur une prestation pourra être revu à la hausse
                        pour des motifs justifiant une telle opération ( Peu de candidats pour une mission, excellente
                        prestation du tuteur,... ).<br/><br/>9.1.2 - Le Prestataire est mis en relation avec un Client
                        ayant opté pour le paiement par avance immédiate de crédit d’impôt.<br/><br/>9.1.2.1 - Le
                        Prestataire facturera une commission de sous-traitance à la Société suivant le barème horaire
                        ci-après : <br/>9.1.2.1.1 - Dans le cas où le tuteur a effectué 35h de cours ou plus et a
                        parrainé un nouveau tuteur (inscrit sur la plateforme Eddmon) :<br/>


                        <ul>
                            {infosPrices?.filter((item) => item.type === "home").map(prices => (
                                <li>Prestation pour un enseignement de niveau « {prices.name} » {prices.type === "home" ? 'à domicile' : 'en ligne'} : {prices.majoredTutorGain} € /h TTC ;</li>
                            ))}
                        </ul>

                        9.1.2.1.2 - Dans le cas où le tuteur a effectué moins de 35h de cours :<br/>

                        <ul>
                            {infosPrices?.filter((item) => item.type === "home").map(prices => (
                                <li>Prestation pour un enseignement de niveau « {prices.name} » {prices.type === "home" ? 'à domicile' : 'en ligne'} : {prices.tutorGain} € /h TTC ;</li>
                            ))}
                        </ul>

                        Ces prix sont fixés
                        discrétionnairement par la Société et sont susceptibles d’évolution en fonction du marché et
                        feront l’objet d’un avenant au présent contrat en cas de modification.<br/>9.1.2.2 – Dans un
                        premier temps, après l’accomplissement de sa Mission, le Prestataire s’oblige, dans un délai de
                        sept (7) jours maximum, à faire un rapport de son intervention dans la rubrique « Reporting »
                        accessible sur la Plateforme à partir de son espace personnel. La mise en ligne par le
                        Prestataire de son rapport conditionne expressément son droit au paiement du Prix. <br/>Un
                        compte-rendu de Mission sera concomitamment adressé par la Société́ au Client, laissant à ce
                        dernier la possibilité́ d’émettre une réclamation sous vingt-quatre (24) heures.<br/>9.1.2.3 –
                        Dans un second temps, après mise en ligne du rapport par le Prestataire sur la Plateforme,
                        l’organisme URSSAF versera le Prix total à la Société́ sur son compte bancaire dénommé Eddmon
                        PLUS.<br/>Dans un délai de sept (7) jours après écoulement du délai de réclamation du Client et
                        uniquement en l’absence d’une telle réclamation et à la condition que le Prestataire ait
                        effectué cinq prestations d’affilé, la Société́ produira au nom du Prestataire une facture
                        adressée à la Société puis procédera au paiement des cours par virement bancaire sur le compte
                        bancaire du Prestataire. <br/>En cas de réclamation émise par le Client, la Société devra
                        restituer le Prix total au Client, en informant le Prestataire de cette réclamation, lequel fera
                        son affaire personnelle dudit litige et du recouvrement des sommes dès lors qu’il agit en
                        qualité de prestataire indépendant. <br/>9.1.2.4 - Toute Prestation annulée ou ayant fait
                        l’objet d’une réclamation du Client ne donnera pas lieu au paiement du Prix.<br/>9.1.2.5 - Le
                        prix perçu sur une prestation pourra être revu à la hausse pour des motifs justifiant une telle
                        opération ( Peu de candidats pour une mission, excellente prestation du tuteur,...
                        ).<br/><br/><br/>9.2 – Modalités de paiement et de facturation<br/>9.2.1 - La Société́ reversera
                        le Prix au Prestataire par virement bancaire sur son compte, dont les coordonnées bancaires et
                        le relevé d’identité bancaire (RIB) seront transmis par le Prestataire à la Société́ par tout
                        moyen.<br/>La perception de la Commission de la Société́ sera accompagnée de l’émission d’une
                        facture par la Société́ à l’intention du Prestataire.<br/>9.2.2 - Le Prestataire mandate par
                        ailleurs expressément la Société́, tout en préservant son indépendance et son autonomie, sans
                        que cela ne constitue une association de fait ou que cela ne caractérise une dépendance ou
                        n’octroie de pouvoirs de direction de l’une à l’autre, pour émettre des factures pour son
                        compte à l’intention des Clients au même titre que des attestations requises pour les avantages
                        fiscaux de ces derniers. <br/>Les Parties conviennent que, dans le cadre de ce mandat, le
                        Prestataire conserve l'entière responsabilité́ de ses obligations en matière de facturation et
                        de ses conséquences au regard de toutes les taxes pouvant être dues, et il dispose d’un délai de
                        quarante-huit (48) heures pour contester le contenu des factures émises en son nom et pour son
                        compte.<br/>Le Prestataire s’engage à régler à l’administration publique toutes les taxes
                        pouvant être dues sur les Prestations et la facturation et devra réclamer le double de la
                        facture si cette dernière ne lui est pas parvenue ainsi que signaler toute modification
                        concernant son identification.<br/>Le Prestataire reconnait avoir pris connaissance de ses
                        obligations mentionnées dans le rescrit 2006-39 TCA du 12 septembre 2006 (figurant à l’adresse
                        : « http://archives-bofip.impots.gouv.fr/bofip-A/g4/g7/g1/33380-AIDA.html »).<br/>9.2.3 - En cas
                        de modification de ses coordonnées bancaires, le Prestataire s’engage à adresser ses nouvelles
                        coordonnées bancaires à la Société́ dans un délai de sept (7) jours. <br/>Dans le cas
                        contraire, la Société́ ne pourra pas être tenue pour responsable de l’absence de paiement des
                        sommes qui lui sont dues ou du paiement sur l’ancien compte bancaire communiqué par le
                        Prestataire.<br/><br/>9.3 – Frais engagés<br/>L’ensemble des frais de toute nature (transport,
                        accès internet, etc.) engagés par le Prestataire et nécessaires à l’exécution de sa Prestation
                        d’enseignement demeurent à sa charge. Il ne pourra solliciter aucune prise en charge par la
                        Société́ ni par le Client.<br/><br/>TITRE 5 – STIPULATIONS DIVERSES<br/><br/>Article 10 –
                        COLLABORATION DES PARTIES<br/>Les Parties s’engagent à collaborer de la meilleure des manières,
                        en toute bonne foi et diligence dans le cadre de l’exécution du présent Contrat. Chacune des
                        Parties s'engage à immédiatement informer l’autre de tout évènement ou élément susceptible
                        d’affecter la bonne exécution du présent Contrat. <br/><br/>Article 11 – INDEPENDANCE DES
                        PARTIES<br/>11.1 - Aucune des Parties ne peut prendre d'engagement au nom et/ou pour le compte
                        de l'autre Partie. Chacune des Parties demeure seule responsable de ses allégations,
                        engagements, prestations, produits et personnels. <br/>Le Prestataire reconnaît que la Société́
                        ne dispose à son encontre d’aucun pouvoir de contrôle ni de direction, qu’il exerce son
                        activité́ en toute indépendance et autonomie. La Société exerce simplement un rôle
                        d’intermédiaire mettant en relation le Prestataire avec le Client et assurant le versement du
                        Prix.<br/>Le Prestataire conserve de manière exclusive le droit de déterminer quand et pendant
                        combien de temps il accepte une Mission. Le Prestataire est en mesure de refuser une Mission
                        proposée par la Société́ sans avoir à produire de motif ou de justificatif.<br/>11.2 - Les
                        Parties conviennent que le présent Contrat est un contrat de prestation de services entre deux
                        professionnels indépendants et n’est pas un contrat de travail ni ne crée de relation d’emploi,
                        y compris dans les obligations incombant à un employeur vis-à-vis de son employé́ en matière
                        de droit du travail, de droit fiscal ou de droit de la sécurité́ sociale, entre la Société́
                        d’une part et le Prestataire de l’autre ou encore vis-à-vis du Client.<br/><br/><br/>Article 12
                        – CLAUSE DE NON CONCURRENCE – NON SOLLICITATION<br/>12.1 – Le Prestataire s’interdit
                        expressément et irrévocablement, directement ou indirectement, notamment par personne interposée
                        ou au travers d'une société́ ou autre entité́, pendant toute la durée d’exécution du présent
                        Contrat et pendant deux (2) années après l’expiration du terme du présent Contrat, pour quelque
                        raison que ce soit, d’engager des relations commerciales et/ou de proposer ses/des services aux
                        Clients de la Société́, passés, présents et prospectés ainsi que de les solliciter à cette fin.
                        La présente clause vaudra même dans l'hypothèse où la sollicitation serait à l’initiative du
                        ou des Clients.<br/>12.2 - Le non-respect de cet engagement engagera la responsabilité́ du
                        Prestataire et pourra donner lieu à la résiliation du Contrat à ses torts exclusifs suivant
                        les termes de l’article 15.3 ainsi qu’à l’octroi de dommages et intérêts.<br/><br/>Article 13 –
                        PROPRIETE INTELLECTUELLE<br/>Les Parties restent chacune propriétaire de leurs Droits de
                        propriété intellectuelle respectifs, le présent Contrat n'entraînant aucun transfert ni cession
                        de ces derniers.<br/>En conséquence, tous les supports développés par la Société́ sont protégés
                        par le droit d'auteur, des marques ou des brevets. Ils sont la propriété exclusive de la
                        Société́. Toutefois, par les présentes, la Société́ concède à l’Enseignant le droit d’utiliser
                        la marque de la Société́ et les supports publicitaires de cette dernière.<br/>Cette concession
                        est consentie à titre exclusif et temporaire au profit du Prestataire dans le cadre de
                        l’exécution du présent Contrat et exclusivement pour la seule durée de celui-ci. <br/>Les
                        Parties sont expressément convenues que cette concession n’est pas cessible, sous quelque forme
                        que ce soit, à un tiers. <br/><br/>Article 14 – CONFIDENTIALITE<br/>14.1 - Chacune des Parties
                        au présent Contrat s’engage à :<br/>(i) tenir confidentielles toutes les informations qu'elle
                        recevra de l'autre Partie, en faisant preuve au moins du même degré́ de vigilance que celui dont
                        elle fait preuve pour protéger ses propres informations confidentielles ; <br/>(ii) ne pas
                        divulguer les informations confidentielles de l'autre Partie à un Tiers quelconque, autre que
                        des employés ou préposés ayant besoin de les connaître ; et <br/>(iii) n'utiliser les
                        informations confidentielles de l'autre Partie qu'à l'effet d'exercer ses droits et de remplir
                        ses obligations aux termes du présent Contrat. <br/>14.2 - Nonobstant ce qui précède, aucune des
                        Parties n'aura d'obligation quelconque à l'égard d'informations qui :<br/>(i) seraient tombées
                        ou tomberaient dans le domaine public indépendamment d'une faute par la Partie les recevant
                        ; <br/>(ii) seraient connues de la Partie les recevant avant que l'autre Partie ne les lui
                        divulgue ; <br/>(iii) seraient légitimement reçues d'un Tiers non soumis à une obligation de
                        confidentialité́ ; et <br/>(iv) devraient être divulguées en vertu de la loi ou sur ordre d'un
                        tribunal (auquel cas elles ne devront être divulguées que dans la mesure requise et après en
                        avoir prévenu par écrit la Partie les ayant fournies). <br/>14.3 - Le Prestataire s’interdit au
                        surplus de divulguer à un Tiers les informations personnelles (adresse, situation familiale,
                        code d’entrée de l’immeuble ou de la résidence, etc.) du Client qu’il aurait pu acquérir dans
                        l’exercice de sa Mission, à l’exception notable des informations qui lui seraient demandées par
                        la Société́ pour le bon déroulement de sa Mission ou pour répondre aux obligations juridiques,
                        règlementaires ou fiscales auquel il est astreint, et pour tout autre motif nécessaire.<br/>14.4
                        - Les Parties s'engagent par ailleurs à faire respecter ces dispositions par leur personnel et
                        par tout préposé́ ou Tiers qui pourrait intervenir à quelque titre que ce soit dans le cadre du
                        présent Contrat.<br/><br/>Article 15 – RESILIATION<br/>15.1 - Chacune des Parties peut mettre
                        fin au Contrat à tout moment par email, lettre recommandée avec demande d'avis de réception ou
                        tout autre manifestation explicite de volonté par écrit de le résilier sous réserve de respecter
                        un préavis d’au moins un mois (1) mois. <br/>15.2 - Si la Société́ n'exécute pas les obligations
                        lui incombant au titre du présent Contrat vis-à-vis du Prestataire, celui-ci pourra, au choix
                        ou successivement :<br/>- suspendre l’exécution de ses Prestations dans l’attente que la
                        Société́ exécute l’obligation inobservée en la mettant en demeure de le faire par tout moyen
                        écrit ;<br/>- résilier le Contrat de manière anticipée, sans autre forme de préavis, mais en
                        notifiant cette résiliation par tout moyen écrit. <br/>15.3 – Si le Prestataire n'exécute pas
                        les obligations lui incombant au titre du présent Contrat vis-à-vis de la Société́ ou s’il fait
                        l’objet d’une réclamation pendante de la part d’un Client, la Société́ pourra, au choix ou
                        successivement :<br/>- suspendre le profil du Prestataire de la Plateforme de sorte qu’aucune
                        nouvelle Mission ne lui soit attribuée et lui demander de suspendre ses Missions en cours auprès
                        des Clients dans l’attente qu’il exécute l’obligation inobservée et en le mettant en demeure de
                        le faire par tout moyen écrit ou, le cas échéant, dans l’attente de l’examen de la réclamation
                        par la Société́ ;<br/>- résilier le Contrat de manière anticipée, sans autre forme de préavis,
                        mais en notifiant cette résiliation par tout moyen écrit.<br/><br/>Article 16 –
                        RESPONSABILITE<br/>16.1 – Responsabilité́ de la Société́<br/>16.1.1 - En toutes circonstances et
                        pour toutes les fonctionnalités de la Plateforme, la Société́ n'a qu'une obligation de moyens.
                        La Société ​s’entoure des plus grandes précautions s’agissant du fonctionnement et de la
                        maintenance du Site, et ne fait appel à cet effet qu’aux informations et moyens techniques qui
                        lui apparaissent fiables. <br/>16.1.2 - Le Prestataire reconnaît et accepte cependant que la
                        Société́ : <br/>- ne garantit pas un fonctionnement sans erreurs et ininterrompu du Site, ni
                        l'exactitude ou la mise à jour de son contenu ; <br/>- peut modifier sans préavis le contenu et
                        les fonctionnalités de la Plateforme ; <br/>- s'efforce de maintenir accessible le Site 24
                        heures sur 24 et sept jours sur sept, mais n'est tenue à aucune obligation de résultat. La
                        Société́ peut donc interrompre l'accès au Site, notamment pour des raisons de maintenance et de
                        mise à niveau. <br/>L'accès peut également être interrompu pour toute autre raison. La Société́
                        ne saurait en aucun cas être tenue responsable de ces interruptions et des conséquences qui
                        peuvent en découler pour les Prestataires ; <br/>- peut mettre fin ou modifier les
                        caractéristiques de la Plateforme à tout moment, et cela sans préavis. En aucun cas la Société́
                        ne saurait être tenue responsable d'une perte ou d'une détérioration de ces données. Toute
                        information ou conseil fourni par la société́ ne saurait être interprété comme une quelconque
                        garantie. <br/>- n'assume aucune garantie au titre des liens hypertextes qui peuvent conduire à
                        une consultation de sites web extérieurs gérés par des Tiers. Sur ces sites web, la société́ n'a
                        aucun contrôle et n'assume aucune responsabilité́ quant à leur contenu. <br/>16.1.3 – Il est
                        rappelé que la Société́ a un simple rôle d’intermédiaire entre le Prestataire et les
                        Clients. <br/>Le Prestataire accepte et reconnait par conséquent que la société ​ ne pourra être
                        tenue pour responsable des conditions de réalisation de la Mission et des évènements qui
                        pourraient subvenir à cette occasion. <br/>Le Prestataire accepte et reconnaît encore que la
                        Société́ n’assume aucune responsabilité́ ni garantie au titre des réclamations des Clients qui
                        seraient adressées et qu’elle transmettra au Prestataire le cas échéant. <br/>16.1.4 - En cas de
                        procédure judiciaire où la Société́ serait mise dans la cause, le Prestataire s’engage
                        expressément et irrévocablement à la relever et garantir au titre de toute réclamation
                        introduite. <br/><br/><br/>16.2 – Limitation de responsabilité́ opposable au Prestataire<br/>16.2.1
                        - Sauf en cas de faute lourde ou dolosive à l’origine exclusive du préjudice subi, la Société́
                        ne pourra être tenue pour responsable, à quelque titre que ce soit, des dommages indirects,
                        matériels ou immatériels (tels que manque à gagner, perte d’exploitation, perte de Clientèle,
                        atteinte à l’image de marque, etc.), de quelque nature que ce soit en relation avec l’exécution
                        du présent Contrat. <br/>16.2.2 - En tout état de cause, en cas de mise en jeu de la
                        responsabilité de la Société́, les Parties conviennent que l’indemnité́ mise à sa charge sera
                        limitée au montant forfaitaire de deux mille (2.000) euros. <br/><br/>16.3 – Responsabilité́ du
                        Prestataire<br/>16.3.1 – Le Prestataire est responsable de la bonne exécution de ses obligations
                        découlant du présent Contrat et, sauf clause pénale expressément précisée, peut être tenue de
                        réparer ses fautes par le versement de dommages et intérêts à la Société́. 16.3.2 – Le
                        Prestataire est également seul responsable de la bonne exécution de la Prestation
                        d’enseignement. Il est tenu de respecter la date et l’heure convenues d’un commun d’accord avec
                        le Client. Il est aussi tenu de se conformer à la nature et au contenu de la Prestation
                        d’enseignement attendus par le Client et de ni limiter ni outrepasser le cadre de celle-ci. Il
                        doit respecter les règles de l’art applicables à la Prestation d’enseignement concernée et
                        contribuer à la réussite de cette dernière. Le Prestataire s’engage notamment à ne pas
                        intervenir pour toute Mission pour laquelle il ne serait pas compétent ou qu’il se ne serait pas
                        en mesure de réaliser à son terme. Le Prestataire devra faire son affaire personnelle de toute
                        réclamation qui lui serait adressée personnellement ou qui lui serait transmise par
                        l’intermédiaire de la Société́ et s’engage à relever et garantir cette dernière au titre de
                        toute action qui serait introduite devant une juridiction. A cet effet, le Prestataire accepte
                        que la Société́ communique ses coordonnées au Client ayant fait appel à ses services et qui
                        souhaiterait lui adresser une telle réclamation. <br/>16.3.3 – Le Prestataire s’engage à
                        procéder aux déclarations sociales, administratives et fiscales afférentes à son activité́ sous
                        sa seule responsabilité. La Société́ n’assume aucune responsabilité́ au titre de toute omission,
                        erreur ou fausse déclaration que le Prestataire pourrait effectuer.<br/>16.3.4 – Le Prestataire
                        s’engage à souscrire toutes les assurances couvrant sa responsabilité professionnelle et
                        notamment mais pas seulement les atteintes aux biens et aux personnes pouvant être occasionnés
                        lors de l’exécution de ses Prestations. <br/>16.3.5 – Conformément à l’article 14 «
                        Confidentialité́ » du présent Contrat, le Prestataire reconnaît expressément que les données
                        nominatives concernant les Clients et qui lui sont transmises ne doivent être utilisées que dans
                        le cadre de la réalisation de la Prestation sollicitée ou pour toute réclamation. Ces données ne
                        peuvent être ni vendues, ni cédées à des Tiers, de quelque manière que ce soit et ne doivent
                        pas être utilisées à des fins de sollicitation commerciale.<br/><br/>Article 17 – INCESSIBILITÉ
                        DU CONTRAT<br/>Le présent Contrat est conclu en considération de la personne des Parties,
                        lesquelles ne pourront substituer de Tiers pour l’accomplissement de leurs obligations
                        respectives.<br/><br/>Article 18 – MODIFICATION ET NON-RENONCIATION<br/>Tout amendement,
                        résiliation ou abandon de l'une quelconque des clauses du présent Contrat ne sera valable
                        qu'après accord écrit et signé entre les Parties. Le fait, pour l'une des Parties, de ne pas se
                        prévaloir d'un engagement par l'autre Partie à l'une quelconque des obligations visées par les
                        présentes, ne saurait être interprété́ pour l'avenir comme une renonciation à l'obligation en
                        cause.<br/><br/>Article 19 – DIVISIBILITÉ DES CLAUSES<br/>Le fait qu’une quelconque clause du
                        présent Contrat devienne nulle, inopposable, caduque, illégale ou inapplicable ne pourra
                        remettre en cause la validité́ du présent Contrat et n’exonéra pas les Parties de son exécution.<br/>Dans
                        un tel cas, les Parties s’engagent à substituer si possible à la disposition illicite ou
                        inapplicable, une disposition licite correspondant à l’esprit et à l’objet de
                        celle-ci.<br/><br/>Article 20 – NOTIFICATIONS<br/>Toutes notifications devant être effectuées
                        dans le cadre du présent Contrat seront considérées comme réalisées si elles sont faites par
                        lettre recommandée avec avis de réception aux adresses figurant en en-tête. Toute modification
                        d’adresse devra être notifiée suivant la même forme et aura vocation à s’appliquer pour la
                        suite de l’exécution du Contrat.<br/><br/>Article 21 – FORCE MAJEURE <br/>La responsabilité́ de
                        l'une ou l'autre des Parties ne pourra être recherchée si l'exécution du Contrat est retardée ou
                        empêchée en raison d'un cas de force majeure ou d’un cas fortuit telle que défini par la
                        jurisprudence française et ainsi, notamment mais pas seulement, les conflits sociaux,
                        intervention des autorités civiles ou militaires, catastrophes naturelles, incendies, dégâts des
                        eaux, interruption du réseau de télécommunications ou du réseau électrique. <br/><br/>Article 22
                        – LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION <br/>Le présent Contrat est pour sa validité́,
                        son interprétation et son exécution, soumis à la loi française.<br/>Tout différend, survenant
                        entre les Parties dans la validité́, l’exécution, l’interprétation ou la résiliation du présent
                        Contrat, sera soumis à la compétence des juridictions situées dans le ressort de la Cour
                        d’appel de Paris, nonobstant pluralité́ de défendeurs ou appel en garantie.
                    </div>
                    <div className="mt-4 text-center">
                        <button className="btn btn-danger mr-3"
                                onClick={() => !disabled ? onSubmit(false) : null}>Refuser
                        </button>
                        <button className="btn btn-primary" onClick={() => !disabled ? onSubmit(true) : null}>Valider
                        </button>
                    </div>
                </div>
            </div>
        </SimpleModal>
    )
}
