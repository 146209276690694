import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CardSetupForm from "./Stripe/card/cardSetupForm";
import LoaderSpinner from "../Others/loader/loader";

import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { apiService } from "../../_api/api.service";
import { infoTextConstants } from "_constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function FormStripeSetup({ disableTitle, className, urlToReload, isSetUp = false, allowed: allowedProps = false }) {
    const [options, setOptions] = useState(undefined);
    const [applicationFees, setApplicationFees] = useState(undefined);
    const [applicationFeesHide, setApplicationFeesHide] = useState(true);
    const customerId = useSelector(store => store.tah?.user?.customer?.id);
    const userId = useSelector(store => store?.authentication?.user?.id);
    const stripePromise = useSelector(store => store?.tah?.user?.stripePromise);
    const history = useHistory();
    const active = !disableTitle;

    useEffect(() => {
        allowed();
    }, [])

    useEffect(() => {
        getStripeClientSecret();
        initApplicationFeesProperties();
    }, [customerId]);

    useEffect(() => {
        initApplicationFees();
    }, [isSetUp])

    async function allowed() {
        if (!!userId && !!customerId) {
            let hasPaymentMethod = await apiService.getCustomerHasPaymentMethodByUserId(userId);
            if (hasPaymentMethod && !allowedProps) {
                history.push("/");
            }
        } else {
            history.push("/");
        }
    }

    async function initApplicationFees() {
        if (customerId) {
            const { applicationFees, _ } = await apiService.getApplicationFees(customerId)
            setApplicationFees(applicationFees);
        }
    }

    async function initApplicationFeesProperties() {
        if (customerId) {
            const { applicationFees, applicationFeesPaid } = await apiService.getApplicationFees(customerId)
            setApplicationFees(applicationFees);
            if (applicationFees == 0) {
                setApplicationFeesHide(true);
            } else if (applicationFees > 0 && applicationFeesPaid) {
                setApplicationFeesHide(true);
            } else if (applicationFees > 0 && !applicationFeesPaid) {
                setApplicationFeesHide(false);
            }
        }
    }

    async function getStripeClientSecret() {
        if (customerId) {
            const token = await apiService.getStripeCreateSetupIntent(customerId);
            setOptions({
                clientSecret: token,
                appearance: {
                    theme: 'stripe',
                },
                locale: 'fr'
            })
        }
    }

    if (!stripePromise || !options) {
        return <LoaderSpinner />
    }

    return (
        <div className={className ? className : "mx-md-5 px-md-5"} style={{overflow:"auto"}}>

        <div className='d-flex flex-column'>
            {active ?
                <h1 className="connexion-title pb-4 text-center mb-4">
                    INFORMATIONS BANCAIRES
                </h1>
                :
                null}
            {!applicationFeesHide ?
                <p className="mx-auto mb-4" style={{ maxWidth: 600, fontStyle: "italic" }}>{infoTextConstants.APPLICATION_FEES_MESSAGE(applicationFees)} </p>
                : null}

            <Elements stripe={stripePromise} options={options} >
                <CardSetupForm urlToReload={urlToReload} applicationFeesHide={!applicationFeesHide} />
            </Elements>
        </div>

        </div>
    )
}

FormStripeSetup.propTypes = {
    disableTitle: PropTypes.bool,
    reloadOnComplete: PropTypes.bool,
    className: PropTypes.string,
}

export default FormStripeSetup;
