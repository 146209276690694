import React from 'react';
import { IconButton, Popper } from '@material-ui/core';
import SimpleModal from 'components/Others/modal/modal';
import CloseIcon from '@material-ui/icons/Close';

export default function Wrapper({ children, closeable = true, open = true, anchorEl, loading = false, placement, onClose = () => { } }) {
    if (anchorEl === null) {
        return (
            open ? <>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", height: "100%", width: "100%", position: "absolute", zIndex: 1300, }} />
                <div aria-busy="true" className="h-100 w-100 d-flex justify-content-center align-items-center" style={{ position: "absolute", zIndex: 1301, top: "0px", left: "0px" }}>
                    <div className="form d-flex flex-column p-3 pb-4 form-container mb-3 mt-3 col-lg-4 col-md-8 col-sm-4 col-11 mx-auto" style={{ backgroundColor: "#ffffff", zIndex: 1300, position: "absolute" }}>
                        <IconButton className="p-1 outline-none align-self-end" onClick={onClose} hidden={!closeable} style={{ borderRadius: ".25rem", position: "absolute", right: "16px", top: "16px" }} >
                            <CloseIcon style={{ outline: "none !important", border: "none !important" }} />
                        </IconButton>
                        {children}
                    </div>
                </div>
            </>
            :
            <></>
        )
    } else if (anchorEl === undefined) {
        return <SimpleModal open={open} autoClose={false} onClose={onClose} loading={loading} />
    } else if (anchorEl?.offsetParent !== null) {
        return <SimpleModal open={open} autoClose={false} onClose={onClose} loading={loading}>
            <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 1301 }} placement={placement} >
                {/* Dekstop UX  */}
                <div className="d-none d-md-flex flex-column p-3 form-container mb-3 mt-4 w-100" style={{ backgroundColor: "#ffffff", maxWidth: "min-content" }}>
                    <IconButton className="p-1 outline-none align-self-end" onClick={onClose} hidden={!closeable} style={{ borderRadius: ".25rem", position: "absolute", right: "16px", top: "32px" }} >
                        <CloseIcon style={{ outline: "none !important", border: "none !important" }} />
                    </IconButton>
                    {children}
                </div>
                {/* Mobile UX  */}
                <div className="d-md-none d-flex flex-column p-3 form-container mb-3 mt-4 w-100" style={{ backgroundColor: "#ffffff"}}>
                    <IconButton className="p-1 outline-none align-self-end" onClick={onClose} hidden={!closeable} style={{ borderRadius: ".25rem", position: "absolute", right: "16px", top: "32px" }} >
                        <CloseIcon style={{ outline: "none !important", border: "none !important" }} />
                    </IconButton>
                    {children}
                </div>
            </Popper>
        </SimpleModal>
    } else {
        console.error("Anchor element provided is invalid. The anchor element should be part of the document layout. Make sure the element is present in the document or that it's not display none.")
        return <></>
    }
}