import React, { useEffect, useState } from "react";
import TextFormGroupInput from "../../../components/Others/forms/components/textFormGroupInput";
import SelectFormGroupInput from "../../../components/Others/forms/components/selectFormGroupInput";
import SimpleModal from "../../../components/Others/modal/modal";
import ButtonAction from "components/Others/button/buttonAction/buttonAction";

import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GetAppIcon from "@material-ui/icons/GetApp";

import { useSelector } from "react-redux";
import { apiService } from "_api/api.service";

import { appConstants, buttonConstants, modalConstants, tooltipConstants } from "../../../_constants";

import FormModel from "../../../_models/form.model";
import loading from "components/Others/process/loading";

class Form extends FormModel {
    constructor(student, start, end) {
        super();
        this.student = student;
        this.start = start;
        this.end = end;
    }

    toForm() {
        return {
            student_id: this.student,
            start: this.start,
            end: this.end,
        }
    }
}

export default function StudentReportModal({ open: openProps = false, onClose: onCloseProps = () => { return } }) {
    const [open, setOpen] = useState(openProps);
    const [form, setForm] = useState(new Form());
    const [datesAccepted, setDatesAccepted] = useState();
    const studentOptions = useSelector(store => store?.tah?.user?.customer?.students?.map(elt => elt.toOptionModel()));
    const lessons = useSelector(store => store?.tah?.user?.requests?.map(elt => elt.lesson)?.flat());

    useEffect(() => {
        setOpen(openProps)
    }, [openProps])

    useEffect(() => {
        if (lessons?.length > 0 && typeof datesAccepted === "undefined") {
            let dates = lessons.map(lesson => new Date(lesson.date));
            const max = new Date(Math.max.apply(null, dates));
            const min = new Date(Math.min.apply(null, dates));
            setDatesAccepted({ min: min?.toISOString().split('T')[0], max: max?.toISOString().split('T')[0] });
        }
    }, [lessons])

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_STUDENT:
                setForm(new Form().buildFromInstance({ ...form, student: value }));
                break;

            case appConstants.FORM_LABEL_STARTING_DATE:
                setForm(new Form().buildFromInstance({ ...form, start: value }));
                break;

            case appConstants.FORM_LABEL_ENDING_DATE:
                setForm(new Form().buildFromInstance({ ...form, end: value }));
                break;

            default:
                break;
        }
    }

    async function onSubmit() {
        loading(true);
        const fileName = `${studentOptions.find(elt => parseInt(elt.value) === parseInt(form.student))?.label}_${form.start}_${form.end}`
        await apiService.downloadCustomerReport(form.toForm(), fileName)
        loading(false);
    }

    return (
        <SimpleModal
            open={open}
            onClose={onCloseProps}
            loading={typeof studentOptions === "undefined"}
        >
            <div className="form d-flex flex-column p-3 pb-4 form-container mb-3 mt-3 col-lg-6 col-md-8 col-sm-4 col-11 mx-auto w-100" style={{ maxHeight: "65vh", overflow: "hidden scroll" }}>
                <div className="d-flex justify-content-center">
                    <AssessmentOutlinedIcon />
                    <h4 className="text-center mb-4 mx-2">{modalConstants.STUDENT_REPORT_TITLE}</h4>
                    <AssessmentOutlinedIcon />
                </div>
                <div className="row w-100 m-0">
                    <div className="col-xl-4 col-12 px-0">
                        <div className="form-group d-flex flex-column" >
                            <SelectFormGroupInput className="mx-3" label={appConstants.FORM_LABEL_STUDENT} value={form.student} options={studentOptions} onChange={onChange} />
                        </div>
                    </div>
                    <div className="col-xl-4 col-12 px-0">
                        <div className="form-group d-flex flex-column" >
                            <TextFormGroupInput className="mx-3" id="date-start" type="date" min={datesAccepted?.min} max={datesAccepted?.max} label={appConstants.FORM_LABEL_STARTING_DATE} value={form.start} onChange={onChange} disabled={!!!form.student} toolTip={{ title: tooltipConstants.DISABLED_NO_STUDENT_SELECTED, disabled: !!form.student }} />
                        </div>
                    </div>
                    <div className="col-xl-4 col-12 px-0">
                        <div className="form-group d-flex flex-column">
                            <TextFormGroupInput className="mx-3" id="date-end" type="date" min={form.start || datesAccepted?.min} max={datesAccepted?.max} label={appConstants.FORM_LABEL_ENDING_DATE} value={form.end} onChange={onChange} disabled={!!!form.student} toolTip={{ title: tooltipConstants.DISABLED_NO_STUDENT_SELECTED, disabled: !!form.student }} />
                        </div>
                    </div>
                </div>

                <ButtonAction className="mx-2" onClick={onSubmit} disabled={!form.completed()} >
                    {buttonConstants.DOWNLOAD}
                    <GetAppIcon className="align-middle ml-1" fontSize="small" style={{ color: "color(--purple-tah-color)" }} />
                </ButtonAction>
            </div>
        </SimpleModal>
    )
}