import { apiConstants } from "_constants";
import AddressModel from "./address.model";
import OptionModel from "./option.model";

export default class SchoolModel {
    constructor(id, name, types = [], address = new AddressModel(), country) {
        this.id = id;
        this.name = name;
        this.types = types;
        this.address = address;
        this.country = country;
    }

    buildFromInstance(instance = {}) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }

    clear() {
        return this.buildFromInstance();
    }

    toOptionModel() {
        return new OptionModel(this.id, `${this.name} - ${this.address.postcode || ""}`, this)
    }

    toForm() {
        return {
            id: this.id,
            name: this.name,
            types: this.types,
            address: `${this.address.number} ${this.address.street}`,
            postalCode: this.address.zipcode,
            city: this.address.city,
            country: this.country,
            types: this.types?.map(typeOpt => `${apiConstants.API_SCHOOL_TYPES}/${typeOpt.value}`),
        }
    }
}