import jwt_decode from "jwt-decode";
import moment from "moment";
import { userConstants } from "../_constants";
import { appConstants } from "../_constants/app.constants";

export default extractJWTToken;
/**
 * Convert date from yyyy/mm/dd to dd/mm/yyyy
 * @param {date} date Date to convert (yyyy/mm/ddThh:mm:ss || yyyy-mm-ddThh:mm:ss)
 * @returns converted date if succeed, otherwise returns DEFAULT_NO_DATE.
 */
export function dateDispatch(date) {
    if (!date) {
        return appConstants.DEFAULT_NO_DATE;
    }
    let regex = /^[1-9][0-9]\d{2}(-)\d{2}(-)\d{2}/i;
    let finalDate = "", year = "", month = "", day = "";
    let match = date.match(regex);

    if (match) {
        for (var i = 0; i < match[0].length; i++) {
            if (i < 4) {
                year += match[0][i];
            }
            else if (i < 7 && i > 4) {
                month += match[0][i];
            }
            else if (i > 7) {
                day += match[0][i];
            }
        }

        finalDate = day + "/" + month + "/" + year

    }
    else {
        finalDate = appConstants.DEFAULT_NO_DATE;
    }
    return finalDate;
}

export function timeDispatch(date) {
    if (date) {
        return date.slice(date.indexOf("T") + 1, date.indexOf("T") + 6); // Get chars after letter T (yyyy-mm-ddThh:mm:ss -> hh:mm)
    } else return "No date";
}

export function JSdateToInputDate(date) {
    if (!(date instanceof Date && !isNaN(date))) {
        return "No date";
    } else return date.toISOString().split("T")[0];
}

export function inputDateToFormDate(date) {
    if (date) {
        let rawDate = new Date(date);
        return rawDate.getFullYear() + "/" +
            ("00" + (rawDate.getMonth() + 1)).slice(-2) + "/" +
            ("00" + rawDate.getDate()).slice(-2) + " " +
            ("00" + rawDate.getHours()).slice(-2) + ":" +
            ("00" + rawDate.getMinutes()).slice(-2) + ":" +
            ("00" + rawDate.getSeconds()).slice(-2);
    } else return date;
}

export function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}


export function calculateAge(birthday) { // birthday is a date or a string date.
    if (!birthday) {
        return null;
    }
    var birthdate = new moment(birthday);
    var diff = moment.duration(new moment().diff(birthdate));
    return diff?._data?.years;
}

/*** Extract data hold by the JWT token. Return an object with all decoded data.
 * @argument JWTToken Token to extract data.
 * @returns Decoded data if token exist, otherwise returns undefined.
 */
export function extractJWTToken(tokens) {
    let user = tokens ? jwt_decode(tokens.token) : undefined;
    const role = user?.roles?.includes(userConstants.ROLE_TUTOR) ? userConstants.ROLE_TUTOR : user?.roles?.includes(userConstants.ROLE_CUSTOMER) ? userConstants.ROLE_CUSTOMER : user?.roles?.includes(userConstants.ROLE_ADMIN) ? userConstants.ROLE_ADMIN : undefined;
    if (user) {
        // Delete "roles" and "exp" and replace it by "role" and "date_of_token".
        delete Object.assign(user, { ["role"]: role })["roles"];
        delete Object.assign(user, { ["date_of_token"]: user.exp })["exp"];
    }
    return user;
}

export function checkZero(data) {
    if (data?.length == 1) {
        data = "0" + data;
    }
    return data;
}


/**
 * Convert a object address ({number: string, street: string, city: string, postcode: string}) into a string.
 * @param {object} address 
 * @returns address stringify.
 */
export function flatAddress(address) {
    if (!address) {
        return;
    }
    let result = "";
    for (const [key, value] of Object.entries(address)) {
        if (value && key !== "id" && typeof value !== "function") {
            result = result.concat(" ", value);
        }
    }
    return result;
}


/**
 * Check if the object passed in parameter has as least one key which its value is null
 * @param  {Object} object Object to check
 * @param  {Array[String]} fieldToIgnore Array of string with key to ignore
 * @return {[type]} Returns 1 if no item is missing otherwise returns 0
 */
export function checkMissingData(object, fieldToIgnore) {
    // Check if all data wanted are registered
    for (const key in object) {
        if ((fieldToIgnore && fieldToIgnore.includes(key)) || typeof object[key] === "function") {// Check if current field needs to be ignored or not
            continue;
        }
        if (!object[key] || object[key]?.length === 0) { // Check if object is empty
            //console.log("Missing data:", key, "in object :", object);
            return 0; // Stop loop if data is missing
        }
        if (typeof object[key] === 'object') {
            if (!checkMissingData(object[key], fieldToIgnore)) {
                return 0;
            }
        }
    }
    return 1;
}

export function checkDataRequired(object, fieldRequired = []) {
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            if (fieldRequired.includes(key)) {
                const element = object[key];
                if (!element || element?.length === 0) { // Check if object is empty
                    return false; // Stop loop if data is missing
                } else if (typeof element === 'object') {
                    const objectFieldRequired = [];
                    fieldRequired.forEach(elt => {
                        if (elt.includes(`${key}.`)) {
                            objectFieldRequired.push(elt.split(".")[1]);
                        }
                    })
                    if (!checkDataRequired(element, objectFieldRequired)) {
                        return false;
                    }
                }
            } else continue;
        }
    }
    return true;
}


/**
 * Remove undefined keys from the form provided.
 * @param {object} form 
 * @returns form without undefined key
 */
export function deleteUndefinedKeys(form) {
    if (typeof form === "object") {
        for (const key in form) {
            if (Object.hasOwnProperty.call(form, key)) {
                const element = form[key];
                if (typeof element === "undefined") {
                    delete form[key];
                } else {
                    try {
                        if (element?.includes("undefined")) {
                            delete form[key];
                        }
                    } catch (_error) {
                        continue;
                    }
                }
            }
        }
    }
    return form;
}

/**
 * Convert numerical hour into hours | minutes time.
 * @param {number} time
 * @example 1.25 will be proceed to get 1h15
 */
export function hoursConvertor(time) {
    if (!time) {
        return appConstants.DEFAULT_NO_TIME;
    }

    let decimalTime = parseFloat(time);

    if (decimalTime) {
        const hours = Math.trunc(decimalTime);
        let minutes = Math.floor((decimalTime - hours) * 60);
        if (minutes === 0) {
            minutes = "00";
        } else if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        return `${hours}h${minutes}`;
    } else {
        return appConstants.DEFAULT_NO_TIME;
    }
}

/**
 * Generate duration list for selector (format {value: X, label: ""}).
 * @param {number} start time for first option (in minutes).
 * @param {number} end time for last option (in minutes).
 * @param {number} interval time interval between each option (in minutes).
 * @returns a list of duration's options.
 */
export function durationListGenerator(start, end, interval) {
    let list = [];

    for (let counter = start; counter <= end; counter += interval) {
        const label = `${hoursConvertor(counter / 60)}`;
        list.push({ value: counter, label: label });
    }

    return list;
}

export function phoneNumberConvert(phoneNum) {
    if (!phoneNum) {
        return "";
    }
    if (phoneNum[0] === "+") {
        return phoneNum.replace("+", "");
    } else if (phoneNum[0] === "0") { // For phone number registered in format 0X XX XX XX XX
        return ("33" + phoneNum.substr(1))
    }
    else return phoneNum;
}


export function lessonNbrString(lessonNbr) {
    if (lessonNbr === 1) {
        return `${lessonNbr} ${appConstants.ONE_LESSON_INPUT}`;
    } else if (lessonNbr > 1) {
        return `${lessonNbr} ${appConstants.SEVERAL_LESSONS_INPUT}`;
    }
    else {
        return appConstants.NO_LESSON_INPUT;
    }
}

export function amountString(amount) {
    if (typeof amount === "number" || parseInt(amount)) {
        return `${amount} €`;
    }
    else {
        return appConstants.NO_AMOUNT_INPUT;
    }
}
export function parseTotalAmount(amount) {
    if (amount === 0) {
        return "- €";
    } else if (amount > 10000) {
        return `${parseInt(amount)} €`;
    } else {
        return `${amount} €`;
    }
}

export function genderFormating(gender) {
    switch (gender) {
        case "female":
            return "Madame"

        case "male":
            return "Monsieur"
            
        default:
            return "Inconnue";
    }
}

/**
 * Takes an string address and returns each element of the address in an array as follow: [number, street, city, zipcode]
 * @param {object} data = {address: "", city: "", zipcode= ""}
 * @returns 
 */
 export function extractAddress(data) {
    //Extract individual data of an address : output = [fullNumber, street address]
    let address = data.address, regexNumber = /\b(0*(?:[1-9][0-9]{0,3}|1000))\b/;
    if (!address) {
        return ["", ""]
    }
    let number = address.match(regexNumber) ? address.match(regexNumber)[0] : "";
    let matching = address.match(/\b[Bb][Ii][Ss]\b|[Tt][Ee][Rr]\b|[Qq][Uu][Aa][Tt][Ee][Rr]\b/); // Search for bis/ter/quater
    let infoNumber = matching ? matching[0] : "";
    let regexCity = new RegExp(data.city, "gi");
    let regexPostcode = new RegExp(data.zipcode, "gi");
    let fullNumber = number + " " + infoNumber;

    address = address.replace(regexCity, "");
    address = address.replace(regexPostcode, "");
    if (number) {
        address = address.replace(fullNumber, "");
    } else {
        fullNumber = "";
    }
    return [fullNumber.trim(), address.trim()]
}