export const titleConstants = {
   /** TUTOR'S DASHBOARD */
   COURSE_AVAILABLE: "En recherche",
   MY_COURSES: "Mes cours",
   MY_COURSES_SM: "Cours",
   DECLARE_COURSE: "Déclarer un cours",
   DECLARE_COURSE_SM: "Déclarer",
   MY_STUDENTS: "Mes élèves",
   MY_STUDENTS_SM: "Élèves",
   SPONSOR: "Parrainer",
   SPONSOR_CUSTOMER: "Gagnez 50€",
   COURSE_AVAILABLE_DEMO: "Dans le détail",

   /** TUTOR'S PROFILE */
   PERSONNAL_FORM: "Fiche personnelle",
   BILLS: "Factures",
   /*** SUBTITLES */
   PERSONNAL_INFORMATION: "Informations personnelles",
   CHARACTERISTICS: "Caractéristiques",

   /** TUTOR'S ADMINISTRATIV */
   BILLING_STATEMENT: "Facturation",
   BILLING_INFORMATION: "Informations fiscales",

   BURGER_MENU: "Menu Burger",
   BURGER_MENU_ADVISOR: "Mon conseiller",
   BURGER_MENU_HELP: "Besoin d'aide",
   BURGER_MENU_LOGOUT: "Déconnexion",

   /** REPORT FORM */
   REPORT_FORM_SECTION_1: "GÉNÉRAL",
   REPORT_FORM_SECTION_2: "NOTES",
   REPORT_FORM_SECTION_3: "DÉROULEMENT DU COURS",

   /** CUSTOMER'S FORM */
   SOS_REQUEST_FORM: "Déposer une demande",
   REVIEW_FORM: "Déposer un avis",

   /** CUSTOMER'S DASHBOARD */
   COURSE_HISTORY: "HISTORIQUE DES COURS",
   MY_TUTORS: "MES TUTEURS",
   
};
