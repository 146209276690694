import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers';
import jwt_decode from "jwt-decode";
import extractJWTToken from './data_formating';

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);

/**
 * 
 * @param {Object} tokens - {token: "", refresh_token: ""}
 * @param {Object} store - redux store
 */
export function updateTokens(tokens, nextStore = store.getState()) {
    const decodedToken = jwt_decode(tokens.token);

    // Check if nextStore has not missing values and populate it with necessary data if missing.
    if (!!!nextStore?.authentication?.user) {
        let user = extractJWTToken(tokens);
        user = {
            ...user,
            id: user.userId
        };
        delete user.userId;

        nextStore.authentication = {
            user: user
        }
    }

    if (nextStore?.authentication?.user && tokens.refresh_token && tokens.token && decodedToken.exp) {
        let user = nextStore.authentication?.user;
        user.refreshToken = tokens.refresh_token;
        user.token = tokens.token;
        user.date_of_token = decodedToken.exp;
        // Updates local storage.
        const localStorageUser = (({ refreshToken, token, date_of_token }) => ({ refreshToken, token, date_of_token }))(user);
        localStorage.setItem("user", JSON.stringify(localStorageUser));
        nextStore.dispatch ? nextStore.dispatch({ type: "UPDATE_USER", user: user }) : store.dispatch({ type: "UPDATE_USER", user: user });
    }
}