import { apiConstants } from "_constants";
import AddressModel from "./address.model";
import SkillModel from "./skill.model";
import UserModel from "./user.model";

export default class TutorModel extends UserModel {

    constructor(id, firstname, lastname, gender, phone, address, birthdate, nationalities, hasAcceptedConditions, school, files, email, disabled, subjects = new SkillModel(), hobbies = new SkillModel()) {
        super(id, firstname, lastname, gender, email, disabled);

        this.phone = phone;
        this.address = address || new AddressModel();
        this.nationalities = nationalities;
        this.birthdate = birthdate;
        this.school = school;
        this.files = files;
        this.subjects = subjects;
        this.hobbies = hobbies;
        this.hasAcceptedConditions = hasAcceptedConditions;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }

    isCompleted() {
        if (this.phone && this.address.isCompleted(["id"])) return true
        else return false;
    }

    toForm() {
        const addressToForm = this.address.toObject();
        return {
            phone: this.phone,
            ...addressToForm,
            zipcode: addressToForm.postcode,
            address: addressToForm.street,
            school: `${apiConstants.API_SCHOOLS}/${this.school.value}`,
        }
    }
}
