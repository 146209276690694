import React from 'react';

export default function Step({ title = "", description = "" }) {

    return <>
        <div className="d-flex flex-column justify-content-between w-100 mb-4">
            <div className="w-100 mb-3 text-center step-title" style={{height: "1rem"}}>{title}</div>
            {description}
        </div>
    </>
}