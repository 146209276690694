export default class SkillModel {
    constructor(id, title, isASubject, isAHobby) {
        this.id = id;
        this.title = title;
        this.isASubject = isASubject;
        this.isAHobby = isAHobby;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
          this[key] = instance[key];
        });
        return this;
      }
}