import React, { useEffect, useState } from "react";
import LoaderSpinner from "../loader/loader";
import PropTypes from 'prop-types';

const BORDER_SIZE = 3; // Border of btn submit;

function Button({ children, onClick, className, spinColor, submitted, disabled }) {
    const [submitBtnRef, _setSubmitBtnRef] = useState(new React.createRef());
    const [submitBtnDim, setSubmitBtnDim] = useState({ height: "auto", width: "auto" });
    const [submit, setSubmit] = useState(submitted);

    useEffect(() => {
        setSubmit(submitted)
    }, [submitted])

    useEffect(() => {
        if (children) {
            setSubmitBtnDim({height: submitBtnRef.current.clientHeight + BORDER_SIZE * 2, width: submitBtnRef.current.clientWidth + BORDER_SIZE * 2 + 5})
        }
    }, [submitBtnRef]);
    
    async function handleOnClick(event){
        if (!disabled) {
            event.preventDefault();
            setSubmit(true);
            await onClick(event);
            setSubmit(false);
        }
    }
    return (
        <button ref={submitBtnRef} type="submit" className={(className ? className : "btn custom-btn px-4") + (submitted ? " py-2" : "")} style={{ height: submitBtnDim.height, width: submitBtnDim.width, pointerEvents: submitted ? "none" : null }} onClick={handleOnClick} disabled={disabled} >
            {submit ? <LoaderSpinner className="h-100 d-flex align-items-center justify-content-center" height={27.5} color={spinColor}/> : children}
        </button>
    )
}


Button.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
    spinColor: PropTypes.any,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    onClick: () => { return }
}

export default Button;
