import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';

import "./modal.css";
import LoaderSpinner from '../loader/loader';

export default function SimpleModal({ open: openProps = false, children, loading = false, style = {}, onClose: onCloseProps = () => { return }, autoClose = true }) {
    const [open, setOpen] = useState(openProps);

    useEffect(() => {
        setOpen(openProps);
    }, [openProps]);

    const handleClose = () => {
        if (autoClose) {
            onCloseProps();
            setOpen(false);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={style}
            >
                {loading ?
                    <LoaderSpinner />
                    :
                    children
                }
            </Modal>
        </div>
    );
}
