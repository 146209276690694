import React, { Suspense, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import LoaderSpinner from '../components/Others/loader/loader';
import Wrapper from '../components/Wrapper';

import { useSelector } from 'react-redux';
import { apiService } from '../_api/api.service';

import { urlConstants, userConstants } from '../_constants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [data, setData] = useState({
        allow: false,
        redirect: urlConstants.LOGIN,
    });
    const user = useSelector(store => store?.authentication?.user);
    const userStore = useSelector(store => store?.tah?.user);

    // Uses to allow redirection.
    useEffect(() => {
        if (rest.path === data?.redirect) {
            setData({ allow: true, redirect: false })
        }
    }, [rest.path, data.redirect]);

    useEffect(() => {
        const worker = async () => {
            let hasPaymentMethod;

            if (user != null) { //if a user is save in local storage
                if (user.role === rest.role) { //if the user role is good
                    if (user.role === userConstants.ROLE_CUSTOMER) {
                        hasPaymentMethod = await apiService.getCustomerHasPaymentMethodByUserId(user?.id);
                        if (rest.path !== urlConstants.STRIPE_SETUP && hasPaymentMethod === false) {
                            setData({ allow: true, redirect: urlConstants.STRIPE_SETUP });
                        }
                        else {
                            setData({ allow: true });
                        }
                        //we log in
                    }
                    else {
                      /*  if (user.role === userConstants.ROLE_TUTOR && [urlConstants.TUTOR_DECLARE_COURSE, urlConstants.TUTOR_DASHBOARD_TAB_3].includes(rest.location?.pathname) && userStore.disabled === true) {
                            setData({allow: true, redirect: urlConstants.TUTOR_HOME})
                        } else*/
                            setData({ allow: true });
                    }
                }
                else {
                    if (user.role === userConstants.ROLE_TUTOR) {
                        setData({ allow: true, redirect: urlConstants.TUTOR_DASHBOARD_RAW });
                    } else if (user.role === userConstants.ROLE_CUSTOMER) {
                        hasPaymentMethod = await apiService.getCustomerHasPaymentMethodByUserId(user?.id);
                        if (rest.path !== urlConstants.STRIPE_SETUP && hasPaymentMethod === false) {
                            setData({ allow: true, redirect: urlConstants.STRIPE_SETUP });
                        } else {
                            setData({ allow: true, redirect: urlConstants.CUSTOMER_DASHBOARD });
                        }
                    }
                }
            } else {
                setData({
                    allow: true,
                    redirect: urlConstants.LOGIN,
                })
            }
        };
        worker();
    }, [rest.path, rest.role]);

    if (!data.allow) {
        return (<LoaderSpinner />)
    }

    return (
        <Suspense fallback={<LoaderSpinner />}>
            <Route {...rest} render={props => (
                data?.redirect
                    ? <Redirect to={{ pathname: data?.redirect, state: { from: props.location } }} />
                    : user?.role === userConstants.ROLE_TUTOR ?
                        <Wrapper type='tutor'>
                            <Component {...props} />
                        </Wrapper>
                        : user?.role === userConstants.ROLE_CUSTOMER ?
                            <Wrapper type='customer'>
                                <Component {...props} />
                            </Wrapper>
                            : null
            )} />
        </Suspense>

    )
}
