import { userConstants } from "../_constants";
import extractJWTToken from "../_helpers/data_formating";

const user = JSON.parse(localStorage.getItem('user'));
const decodedToken = extractJWTToken(user);

const initialState = user
  ? { loggedIn: true, user: { id: decodedToken.userId, role: decodedToken.role, token: user.token, refreshToken: user.refreshToken, date_of_token: user.date_of_token, hasPaymentMethod: decodedToken.hasPaymentMethod } }
  : { forgetPassword: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        forgetPassword: true,
      };
    case userConstants.LOGOUT:
      return {
        forgetPassword: state.forgetPassword,
      };
    case userConstants.FORGET_PASSWORD:
      return {
        forgetPassword: true,
      };
    case "UPDATE_USER":
      return {
        user: action.user,
      };
    default:
      return state;
  }
}
