import { Button } from "react-bootstrap";

import React, { useEffect, useState } from "react";

import { appConstants, colorsConstants } from "../../../../_constants/app.constants";

import "./styles.scss";

function ButtonAction({ children, className: classNameProps = "", onClick, color, style: styleProps, filled = false, disabled = false }) {
    const [className, setClassName] = useState(colorToClassName(color));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        setClassName(colorToClassName(color));
    }, [color]);


    function colorToClassName(color) {
        switch (color) {
            case colorsConstants.PURPLE:
                return (`tah-purple-btn${filled ? "-filled" : ""}`);
            case colorsConstants.LIGHT_BLUE:
                return (`tah-light-blue-btn${filled ? "-filled" : ""}`);
            case colorsConstants.BLACK:
                return (`tah-black-btn${filled ? "-filled" : ""}`);
            case colorsConstants.RED:
                return (`tah-red-btn${filled ? "-filled" : ""}`);
            case colorsConstants.GREEN:
                return (`tah-green-btn${filled ? "-filled" : ""}`);
            default:
                return (`tah-purple-btn${filled ? "-filled" : ""}`);
        }
    }

    function handleClick(event) {
        onClick(event);
    }

    return (
        <Button
            variant="text"
            className={classNameProps ? `${className} ${classNameProps}` : `${className} w-100 align-self-center scrollbar-hidden`}
            style={{
                overflow: !styleProps ? "auto hidden" : "",
                fontSize:
                    windowWidth > appConstants.SCREEN_WIDTH_EXTRA_LARGE
                        ? 1 + "vw"
                        : windowWidth > 992
                            ? 1 + "vw"
                            : windowWidth >= appConstants.SCREEN_WIDTH_MEDIUM
                                ? 1.3 + "vw"
                                : 2.4 + "vw",
                ...styleProps,
            }}
            onClick={handleClick}
            disabled={disabled}
        >
            {children ? children : null}
        </Button>
    )
}


ButtonAction.propTypes = {

}


ButtonAction.defaultProps = {
    onClick: () => { return },
}

export default ButtonAction;