import React, { useEffect } from "react";
import PopoverMenu from "components/Others/menuWithButtons/menuPopover";
import { Button } from "@material-ui/core";
import { ToggleFormGroupInput } from "../../../Others/forms/components/toggleFormGroupInput";

import { confirmModal } from "../../../Others/modal/confirmModal";
import { store } from "../../../../_helpers";
import { userActions } from "../../../../_actions";
import { apiService } from "_api/api.service";
import { tutorActions } from "_actions/tutor.actions";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RedeemIcon from '@material-ui/icons/Redeem';

import { appConstants, idConstants, modalConstants, titleConstants, urlConstants } from "../../../../_constants";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./burgerMenu.css";

export default function TutorBurgerMenu({ id }) {
    const notificationEnabled = useSelector((store) => store?.tah?.user?.tutor?.notificationEnabled);
    const tutorId = useSelector((store) => store?.tah?.user?.tutor?.id);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (tutorId) {
            updateNotifications()
        }
    }, [history.location.pathname])

    async function updateNotifications() {
        const enabled = await apiService.getTutorNotifications(tutorId);
        dispatch(tutorActions.setNotifications(enabled));
    }
    function onConfirm() {
        store.dispatch(userActions.logout());
        window.location.reload();
    }

    async function onChange(value, _label) {
        await apiService.setTutorNotifications(tutorId, value);
        dispatch(tutorActions.setNotifications(value));
    }

    return (
        <PopoverMenu className="d-flex" id={id}>
            <div id={idConstants.tutorial.BURGER_MENU}>
                <a href={urlConstants.TUTOR_SPONSORSHIP} className="navbar-link">
                    <Button
                        variant="text"
                        className="menu-burger-tutor-opt"
                    >
                        <span className="police-gte-font">{titleConstants.SPONSOR}</span>
                        <RedeemIcon style={{ color: "var(--blue-tah-color)" }} />
                    </Button>
                </a>
                <Button className="menu-burger-tutor-opt" variant="text">
                    <ToggleFormGroupInput className="d-flex align-items-center justify-content-between p-0 police-gte-font" hideIcons label={appConstants.BURGER_MENU_LABEL_NOTIFICATIONS} value={notificationEnabled} onChange={onChange} colors={{ false: "var(--red-tah-color)", true: "var(--green-tah-color)" }} />
                </Button>
                <a href={urlConstants.HELP_LINK} className="navbar-link">
                    <Button className="menu-burger-tutor-opt" variant="text">
                        <span className="police-gte-font">{titleConstants.BURGER_MENU_HELP}</span>
                        <HelpOutlineIcon style={{ color: "var(--blue-tah-color)" }} />
                    </Button>
                </a>
                <Button

                    variant="text"
                    className="menu-burger-logout-opt"
                    onClick={() => confirmModal(modalConstants.LOGOUT_TITLE, onConfirm)}
                >
                    <span className="police-gte-font font-weight-bold" >{titleConstants.BURGER_MENU_LOGOUT}</span>
                    <ExitToAppIcon style={{ color: "var(--red-tah-color)" }} />
                </Button>
            </div>
        </PopoverMenu>
    )
}