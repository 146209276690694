import React, { useEffect, useRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { appConstants } from '../../../../_constants';

function MultiSelectFormGroupInput({ value: valueProps, options, label, onChange, selectionLimit = -1, className, placeholder="", style = {}, ref: refProps = null, showCheckbox = false, invalidFeedBackMessage = appConstants.DEFAULT_INVALID_FEEDBACK, loading, loadingMessage = appConstants.DEFAULT_LOADING_MESSAGE, emptyRecordMsg = appConstants.EMPTY_OPTIONS, disable, submitted, defaultSingleSelect = false, hideLabel = false }) {
    const [value, setValue] = useState(valueProps ? valueProps : []);
    const [selectStyle, setSelectStyle] = useState();
    const ref = useRef(refProps);

    useEffect(() => {
        setValue(valueProps ? valueProps : []);
    }, [valueProps]);

    /**
     * Set style object according to select choosen.
     */
    useEffect(() => {
        let style;
        let disableClass = disable ? "#e9ecef" : "#e6f0ff";
        if (defaultSingleSelect) {
            style = {
                multiselectContainer: { backgroundColor: disableClass, opacity: submitted || disable ? 1 : null, border: "solid 1px #4a25aa", borderRadius: 0, height: 35 + 'px', maxWidth: 100 + '%', fontFamily: "Rota" },
                searchBox: { border: "none", maxHeight: 100 + '%', overflow: 'scroll', display: "flex", alignContent: "center", paddingLeft: "12px" },
                inputField: { height: 21 + 'px', padding: 0 },
                chips: { margin: 0 }
            };
        } else {
            style = {
                multiselectContainer: { backgroundColor: disableClass, opacity: submitted || disable ? 1 : null, border: "solid 1px #4a25aa", borderRadius: 0, height: 35 + 'px', maxWidth: 100 + '%', fontFamily: "Rota" },
                searchBox: { border: "none", maxHeight: 100 + '%', paddingBottom: 0, overflow: 'scroll', display: "flex", alignContent: "center", paddingLeft: "12px" },
                chips: { backgroundColor: "#4a25aa", display: style?.searchBox?.chips?.display || "" },
                inputField: { height: 21 + 'px', padding: 0 },
            };
        }
        setSelectStyle(style);
    }, [defaultSingleSelect, disable]);

    async function handleChange(optionArray) {
        const newValueRef = ref?.current?.state?.selectedValues;
        const newValue = newValueRef?.length === 0 && optionArray.length > 0 ? optionArray : newValueRef;
        setValue(newValue);
        await onChange(newValue, label, ref);
    };

    return (
        <div className={"form-group " + (className ? className : "w-100 mx-3")} style={style}>
            {!hideLabel ?
                <label className="text-input-font" htmlFor="students">{label}</label>
                :
                null
            }
            <div id={!defaultSingleSelect ? "container-arrow" : ""} style={{ color: '#282828' }}>
                <Multiselect
                    ref={ref}
                    options={options}
                    selectedValues={value}
                    displayValue="label"
                    showCheckbox={showCheckbox}
                    isObject
                    avoidHighlightFirstOption
                    placeholder={placeholder}
                    selectionLimit={selectionLimit}
                    onSelect={handleChange}
                    onRemove={handleChange}
                    style={selectStyle}
                    disable={disable}
                    loading={loading}
                    loadingMessage={loadingMessage}
                    emptyRecordMsg={emptyRecordMsg}
                    singleSelect={defaultSingleSelect}
                />
                <div className={"invalid-feedback" + (submitted && value?.length < 1 ? "-custom" : "")}>{invalidFeedBackMessage}</div>
            </div>
        </div>
    );
}

MultiSelectFormGroupInput.defaultProps = {
    onChange: () => { return }
}

export default MultiSelectFormGroupInput;