import React from "react";
import CustomerBurgerMenu from "./customer/burgerMenu";
import TutorBurgerMenu from "./tutor/burgerMenu";

export default function BurgerMenu({id, customer = false}) {
    if (customer) {
        return <CustomerBurgerMenu id={id} />
    } else {
        return <TutorBurgerMenu id={id} />
    }
}