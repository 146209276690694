import React from 'react';
import { Alert } from '@material-ui/lab';
import MobileWrapper from './mobile';
import NavbarMenu from './navBar/navBar';
import Tutorial from 'components/Tutorial/tutorial';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';

import { navbarMenuConstants, urlConstants } from '../../_constants';
import { STEPS } from 'components/Tutorial/Steps/customer';
import { apiService } from '_api/api.service';
import { tahActions } from '_actions/tah.actions';

export default function CustomerWrapper({ children }) {
    const lastErrorPayment = useSelector(store => store?.tah?.user?.customer?.lastErrorPayment);
    const guide = useSelector((store) => store?.tah?.user?.guide)
    const customerId = useSelector((store) => store?.tah?.user?.customer?.id)
    const openTutorial = false; //guide?.active && (STEPS.length > guide?.step ? true : STEPS.length <= guide?.step ? false : true)
    let closeable = guide?.step > STEPS.length
    const history = useHistory();
    const dispatch = useDispatch();

    function onClickAlert(event) {
        event.preventDefault();
        history.push(urlConstants.CUSTOMER_PROFILE);
    }

    async function onChange(step) {
        if (step > guide?.step) {
            const nextStep = await apiService.patchCustomerStepGuide(customerId, { stepGuide: step }) || step
            dispatch(tahActions.setStepGuide(nextStep))
        }
        if (step === STEPS.length) {
            history.push("/")
        }
    }

    return (
        <div className="window-size d-flex flex-column">

            <Alert hidden={!lastErrorPayment} variant="filled" severity="error" className="alert-payment" style={{ borderRadius: 0 }} onClick={onClickAlert} >
                {lastErrorPayment} (Cliquez ici)
            </Alert>

            <Tutorial open={openTutorial} closeable={closeable} activeStep={guide?.step} steps={STEPS} onChange={onChange} />

            <NavbarMenu customer/>
            {/* Mobile Wrapper handles desktop & mobile wrapper */}
            <MobileWrapper tabs={navbarMenuConstants.CUSTOMER} customer>
                {children}
            </MobileWrapper>
        </div>
    )
}
