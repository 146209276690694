import React, { useEffect, useState } from 'react';
import ButtonAction from '../../../../components/Others/button/buttonAction/buttonAction';

import createStripeAccount from 'components/Others/process/createStripeAccount';
import loading from '../../../../components/Others/process/loading';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import { colorsConstants, modalConstants } from "../../../../_constants";

export default function NeedStripeVerif({ onRedirect = () => { return} }) {
    const [tutorId, setTutorId] = useState();
    const [stripe, setStripe] = useState(undefined);
    let storeTutorId = useSelector(store => store?.tah?.user?.tutor?.id);


    useEffect(() => {
        init();
    }, [storeTutorId])

    async function init() {
        if (storeTutorId) {
            setTutorId(storeTutorId);
            if (!stripe) {
                let newStripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                setStripe(newStripe);
            }
        }
    }

    async function onClick(event) {
        loading(true);
        await createStripeAccount({id: tutorId }, stripe)
        loading(false);
        onRedirect(event);
    }

    return (
        <div className="form d-flex flex-column p-3 form-container mb-3 mt-3 col-lg-6 col-md-8 col-sm-4 col-11 mx-auto" style={{ maxHeight: "65vh", overflow: "hidden scroll" }}>
            <h4 className="text-center mb-4">{modalConstants.ACCOUNT_STRIPE_NEEDS_VERIF_TITLE}</h4>
            <h6 className="text-center mb-4">{modalConstants.ACCOUNT_STRIPE_NEEDS_VERIF}</h6>
            <ButtonAction className="mt-3 w-100" color={colorsConstants.LIGHT_BLUE} onClick={onClick} disabled={!(!!tutorId)} filled>Récupérer 💰</ButtonAction>
        </div>

    )
}