import React, { Component } from 'react';
import { Navbar, Image} from 'react-bootstrap';

import { Link } from 'react-router-dom';

import LOGO from '../../images/logo.png';

import './header.css';

class Header extends Component {
  render() {
    return (
      <Navbar className="header navbar-expand-lg sticky-top justify-content-between w-100">
        <Link to="/login" className="d-flex h-100 py-3" style={{maxWidth: "50%"}}>
          <Image
            src={LOGO}
            className="ml-sm-4 d-block py-1"
            alt="Eddmon"
            style={{
              overflow: "hidden"
            }}
          />
        </Link>
      </Navbar>
    );
  }
}

export default Header;
