import React, { useEffect, useState } from "react";
import { Navbar, Image, Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Item from "./item";
import BurgerMenu from "../burgerMenu";

import LOGO from "../../../images/logo-white.png";
import { idConstants, navbarMenuConstants, urlConstants } from "../../../_constants";

import "./navbar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const tutorConfig = {
    logo: {
        src: LOGO,
        url: navbarMenuConstants.TUTOR[0].path,
    },
    items: navbarMenuConstants.TUTOR,
}

const customerConfig = {
    logo: {
        src: LOGO,
        url: urlConstants.CUSTOMER_DASHBOARD,
    },
    items: navbarMenuConstants.CUSTOMER,
}

export default function NavbarMenu({customer = false}) {
    const [tab, setTab] = useState(1);
    const [config, _setConfig] = useState(customer ? customerConfig : tutorConfig);
    const location = useLocation();

    useEffect(() => {
        if (customer) {
            switch (location.pathname) {
                case urlConstants.CUSTOMER_DASHBOARD:
                    setTab(1);
                    break;
                case urlConstants.CUSTOMER_PROFILE:
                    setTab(2);
                    break;
                default:
                    // By default.
                    setTab(null);
                    break;
            }
        } else {
            if (location.pathname.includes(urlConstants.TUTOR_DASHBOARD_RAW)) {
                setTab(1);
            } else {
                switch (location.pathname) {
                    case urlConstants.TUTOR_PROFILE:
                        setTab(2);
                        break;
                    case urlConstants.TUTOR_MY_FINANCES:
                        setTab(3);
                        break;
                    default:
                        // By default.
                        setTab(null);
                        break;
                }
            }
        }
    }, [location?.pathname])

    return (
        <Navbar className={"navbar-header navbar-expand justify-content-between " + (customer ? "customer" : "")}>
            <Container fluid className="h-100">
                <Row className="w-100 m-0 h-100">
                    <Col className="col-xl-4 col-6 h-100 px-0">
                        <Link to={config.logo.url} className="d-flex h-100 py-3 w-100" >
                            <Image
                                src={config.logo.src}
                                className="ml-4 py-1 d-block"
                                style={{
                                    overflow: "hidden"
                                }}
                                alt="Eddmon"
                            />
                        </Link>
                    </Col>
                    <Col className="col-xl-8 col px-0">
                        <div className="d-flex justify-content-between h-100 mr-xl-5">
                            <div className="align-self-center d-none d-xl-block">
                                {config.items.map((menu, index) => <Item menu={menu} index={index} active={tab === index + 1} />)}
                            </div>

                            <div className="align-self-center ml-auto">
                                <BurgerMenu id={idConstants.tutorial.BURGER_MENU_BTN} customer={customer}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    )
}