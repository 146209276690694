import React, { useEffect, useState } from "react";
import LoaderSpinner from "components/Others/loader/loader";
import { useLocation, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { apiService } from "_api/api.service";
import { useDispatch } from "react-redux";
import { tahActions } from "_actions/tah.actions";

export default function AutoLogin({}) {
    const [redirect, setRedirect] = useState(false);
    const { token: tokenAutoLogin} = useParams();
    const search = useLocation().search;
    const redirectTo = new URLSearchParams(search).get('redirectTo');
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(tahActions.setGuide({
            active: false
        }))
    }, [])
    
    useEffect(() => {
        autoLogin();
    }, [tokenAutoLogin])

    async function autoLogin() {
        await apiService.autoLogin(tokenAutoLogin).then(_response => {
            setRedirect(true);
        });    
    }

    if (redirectTo && redirect) {
        return <Redirect to={`/${redirectTo}`} />
    } else {
        return  <LoaderSpinner />
    }
}