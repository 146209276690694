import React from 'react';
import { Button, Icon } from '@material-ui/core';

import StepModel, { TYPE } from "_models/step.model";

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader } from '@fortawesome/free-solid-svg-icons'

import { idConstants, navbarMenuConstants, titleConstants, urlConstants, buttonConstants } from "_constants";
import LOGO from '../../../images/logo.png';
import ButtonAction from 'components/Others/button/buttonAction/buttonAction';
import SendIcon from '@material-ui/icons/Send';
import CrossedEuro from "../../../images/crossed-euro-symbol.svg"

export const STEPS = [
    new StepModel().buildFromInstance({
        title: () => <div>
            <FontAwesomeIcon className="mx-2" icon={faBookReader} style={{ color: "var(--blue-tah-color)" }} />
            TUTORIEL
            <FontAwesomeIcon className="mx-2" icon={faBookReader} style={{ color: "var(--blue-tah-color)" }} />
        </div>,
        description: (options) => <div>
            Bienvenue sur votre compte <img className="mb-1" src={LOGO} width="100" />
            <br />
            <br /> Dans ce tutoriel, nous allons vous présenter les fonctionnalités de l'application à travers <b>{options?.steps} étapes</b>.
            <br />
            <br />
            <div className="d-sm-none">
                Clique sur le bouton
                <Button variant="contained" className="unselectable mx-1 d-sm-none" size="small" style={{ color: "var(--blue-tah-color)", backgroundColor: "var(--tr-light-gray-tah-color)" }} ><KeyboardArrowRight /></Button> pour commencer !
            </div>
            <div className="d-none d-sm-flex align-items-center">
                Clique sur le bouton
                <Button variant="contained" className="unselectable mx-1  d-sm-flex" size="small" style={{ color: "var(--blue-tah-color)", backgroundColor: "var(--tr-light-gray-tah-color)" }} >{buttonConstants.NEXT} <KeyboardArrowRight /></Button>  pour commencer !
            </div>
        </div>,
        url: urlConstants.CUSTOMER_DASHBOARD,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.COURSE_HISTORY_SECTION,
        description: (_options) =>
            <div>
                Cliquez sur <span className="step-title">{titleConstants.COURSE_HISTORY}</span> pour accéder à la liste des cours de vos enfants.
            </div>,
        userAction: { element: (anchor) => { return anchor.children[0] } },
        type: TYPE.CLICK,
        url: urlConstants.CUSTOMER_DASHBOARD,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.COURSE_HISTORY_SECTION,
        title: () => titleConstants.COURSE_HISTORY,
        description: (_options) =>
            <div>
                Vous retrouvez ici la liste des cours de vos enfants (du plus récents aux plus anciens). Si l'icone <Icon >
                    <img src={CrossedEuro} style={{ color: "var(--warning-tah-color)", height: '20px' }} />
                </Icon> appairaît, cela indique que le cours n'a pas encore été réglé. Les paiements ont lieu tous les mardis à 19h !
            </div>,
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            const container = document.getElementById("scroll-container")
            container && container.scrollTo({ behavior: "smooth", top: 0 })
            return anchor
        },
        url: urlConstants.CUSTOMER_DASHBOARD,
        placement: ["bottom", "right-start"],
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.TUTOR_SECTION,
        description: (_options) => <div>
            Cliquez sur <span className="step-title">{titleConstants.MY_TUTORS}</span> pour retrouver les tuteurs de vos enfants.
        </div>,
        userAction: { element: (anchor) => { return anchor.children[0] } },
        type: TYPE.CLICK,
        url: urlConstants.CUSTOMER_DASHBOARD,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.TUTOR_SECTION,
        title: () => titleConstants.MY_TUTORS,
        description: (options) =>
            <div>
                Retrouvez ici les informations des tuteurs de vos enfants: <strong >nom/prénom, numéro de téléphone, passions, son école et plus encore !</strong>
                <br />
                {!!!options?.screenSize ? <i >Vous allez être redirigé vers le formulaire de demande.</i> : null}
            </div>,
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            const container = document.getElementById("scroll-container")
            container && container.scrollTo({ behavior: "smooth", top: 0 })
            return anchor
        },
        url: urlConstants.CUSTOMER_DASHBOARD,
        placement: ["bottom", "right-start"],
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.SEARCH_TUTOR,
        title: () => null,
        description: () => <div>
            Cliquez sur <span className="step-title">{navbarMenuConstants.CUSTOMER[2].title.toLocaleUpperCase()}</span> pour déposer une <strong>demande de cours "S.O.S"</strong>.
        </div>,
        userAction: { element: (anchor) => { return anchor }, collapse: false },
        type: TYPE.CLICK,
        mobileSkip: true,
        url: urlConstants.CUSTOMER_DASHBOARD,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_SOS_REQUEST,
        title: () => navbarMenuConstants.CUSTOMER[2].title.toLocaleUpperCase(),
        description: () => <div>
            Ce formulaire vous permet de déposer une demande de cours qui alertera nos tuteurs de façon ciblée : un contrôle de dernière minute ?
            C’est le moment !
            <br />
            <i>PS : aucun besoin de le faire si vous avez déjà une demande en recherche.</i>
            <br />
        </div>,
        url: urlConstants.CUSTOMER_SOS_REQUEST_FORM,
        placement: ["bottom", "left-start"],
        onLoad: (anchor) => {
            const container = document.getElementById(anchor.id)
            container && container.scrollTo({ behavior: "smooth", top: 0 })
            return anchor
        },
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_SOS_REQUEST,
        title: () => navbarMenuConstants.CUSTOMER[2].title.toLocaleUpperCase(),
        description: () => <div>
            Cliquez sur <ButtonAction className="unselectable m-2 px-3 py-2" style={{ fontSize: "10px" }} >
                {buttonConstants.FORM_SUBMIT_REPORT}
                <SendIcon fontSize="small" className="align-middle ml-2" />
            </ButtonAction>
            pour notifier les tuteurs à moins de 30 minutes de chez vous. <strong>Les tuteurs enseignant les matières demandées sont notifiés !</strong>
        </div>,
        url: urlConstants.CUSTOMER_SOS_REQUEST_FORM,
        placement: ["bottom", "left-start"],
        onLoad: (anchor) => {
            const container = document.getElementById(anchor.id)
            container && container.scrollTo({ behavior: "smooth", top: container.scrollHeight })
            return anchor
        },
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.BURGER_MENU_BTN,
        title: () => null,
        description: () => <div>
            Cliquez sur le <span className="step-title">Menu</span> pour accéder aux autres fonctionnalités.
        </div>,
        userAction: { element: (anchor) => { return anchor } },
        type: TYPE.CLICK,
        placement: ["bottom", "right-start"],
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.BURGER_MENU,
        title: () => "Menu",
        description: () => <div>
            Pour finir, vous pouvez :
            <ul>
                <li>Réserver un appel directement sur l’agenda de votre conseiller.</li>
                <li>Gagner 50€ en parrainant un proche → tous vos filleuls auront 10€ de réduction également !</li>
            </ul>
            Nous restons disponibles par email à eddmon@eddmon.fr
        </div>,
        placement: ["top", "top"],
        type: TYPE.MODAL,
        onLoad: (anchor) => {
            let clickEl = document.getElementById(idConstants.tutorial.BURGER_MENU_BTN)
            if (!!!clickEl) {
                clickEl.click()
            }
            if (clickEl && !clickEl?.className.includes("active")) {
                clickEl.click()
            }
            return anchor
        },
    }),
];