
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { appConstants } from '../../../../_constants';

function SelectFormGroupInput({ onChange, label, value, options = [], className, placeholder = "", style = {}, invalidFeedBackMessage = appConstants.DEFAULT_INVALID_FEEDBACK, submitted = false, disabled = false, hideLabel = false }) {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    function handleOnChange(event) {
        setInputValue(event.target.value);
        onChange(str2bool(event.target.value), label);
    }

    const str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }

    return (
        <div className={"form-group " + (className ? className : "w-100 mx-3")} style={style}>
            {!hideLabel ?
                <label className="text-input-font">
                    {label}
                </label>
                : null}
            <select
                className={
                    "form-control input-font" +
                    (submitted && (typeof value === "undefined" || value === null || value === "")
                        ? " is-invalid"
                        : "")
                }
                value={typeof inputValue === "undefined" || inputValue === null ? "" : inputValue}
                style={{ height: 35 + "px" }}
                onChange={handleOnChange}
                disabled={disabled}
            >
                <option disabled={true} value="">
                    {placeholder}
                </option>
                {options?.length > 0 ? options.map((opt, key) => {
                    return <option key={key} value={opt?.value} disabled={opt?.disabled}>{opt?.label}</option>
                }) : null}
            </select>
            <div className="invalid-feedback">{invalidFeedBackMessage}</div>
        </div>
    )
}


SelectFormGroupInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })),
    submitted: PropTypes.bool,
}

SelectFormGroupInput.defaultProps = {
    onChange: () => { return },
}

export default SelectFormGroupInput;