export const apiConstants = {
  //API_URL: 'https://www.dev.tuteuralheure.fr'
  API_URL: 'http://localhost:8000',

  /***** ROUTES *****/
  USER_CREATE_PASSWORD: "/user/recover_password",
  USER_CHANGE_PASSWORD: "/user/change_password",
  USER_FORGET_PASSWORD: "/user/forget_password",
  API_LOGIN: "/login_check",
  API_USERS: "/api/users",
  API_CUSTOMERS: "/api/customers",
  API_TUTORS: "/api/tutors",
  API_TUTOR: "/api/tutor",
  API_STUDENTS: "/api/students",
  API_GRADES: "/api/grades",
  API_SKILLS: "/api/skills",
  API_REQUESTS: "/api/requests",
  API_APPLICATIONS: "/api/applications",
  API_ADDRESSES: "/api/addresses",
  API_DISLIKES: "/api/dislikes",
  API_LESSONS: "/api/lessons",
  API_AMOUNT_TO_PAID: "/api/customers/amount-to-be-paid",
  API_CREATE_FILE: "/api/files",
  API_UPDATE_FILE: "/api/files/patch",
  API_CREATE_SETUP_INTENT: "/api/stripe/customer/create_setup_intent",
  API_CREATE_STRIPE_CONNECT_ACCOUNT: "/api/stripe/tutor/create_connect_account",
  API_STRIPE_CUSTOMER: "/api/stripe/customer",
  API_STRIPE_TUTOR: "/api/stripe/tutor",
  API_CREATE_STRIPE_ACCOUNT_LINK: "/api/stripe/tutor/get_account_link",
  API_REFRESH_STRIPE_ACCOUNT_LINK: "/api/stripe/tutor/refresh_account_link",
  API_INVOICES: "/api/invoices",
  API_CREATE_SETUP_INTENT_CONNECT: "/api/stripe/tutor/create_setup_intent",
  API_CREATE_STRIPE_BANK_ACCOUNT: "/api/stripe/tutor/create_bank_account",
  API_HOMEWORK: "/api/homework",
  API_PAYOUTS_GRAPH: "/api/tutor/payouts/graph",
  API_PAYOUTS: '/api/tutor/payouts',
  API_CERTIFICATE_YEARS: "/api/customers/tax-certificates/years",
  API_CERTIFICATES: "/api/customers/tax-certificates",
  API_TRANSPORT: "/api/transports",
  API_CHARACTERISTICS: "/api/characteristics",
  API_AUTO_LOGIN: "/user/autologin",
  API_TUTOR_REVIEW: "/api/tutor_reviews",
  API_TUTORS_SKILLS : "/api/tutor_skills",
  API_ADVISOR : "/api/advisors",
  API_REPORT: "/api/customers/report",
  API_LADDERS: "/api/ladders",
  API_SCHOOLS: "/api/schools",
  PASSWORD_ADDED: "/user/password_added",
    API_TUTOR_PRICES: "/api/tutor/prices",

  /***** HOOKS *****/
  HOOK_SWYPE_SURVEY: "api/lessons/feedback",

  /***** API RETURN STATE *****/
  SUCCESS: true,
  ERROR: false,

  /***** TUTOR'S FILE TYPES *****/
  FILES: {
    AVATAR: { fileType: "avatar", defaultFileName: "Avatar" },
    SCHOOL_PROOF: { fileType: "schoolProof", defaultFileName: "Certificat de scolarité" },
    BUSINESS_PROOF: { fileType: "businessProof", defaultFileName: "Preuve d'auto-entreprise" },
    ID: { fileType: "identityProof", defaultFileName: "Pièce d'identité" },
    BAC_MARKS: { fileType: "bacMarks", defaultFileName: "Relevé du bac" },
  },

  /***** API ERROR CODES *****/
  ERROR_CODE_INCORRECT_TOKEN: "1704",

  /***** API PAGINATION ID *****/
  PENDING_LESSONS: "PENDING_LESSONS",
  LESSONS_INVOICES: "LESSONS_INVOICES",
}
