import axiosMain from "axios";
import Swal from "sweetalert2";
import { apiConstants, userConstants } from "../_constants";
import { appConstants } from "../_constants/app.constants";
import extractJWTToken from "../_helpers/data_formating";

const axios = axiosMain.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const userService = {
  login,
  logout,
};

async function login(username, password) {
  return await axios
    .post(apiConstants.API_LOGIN, {
      email: username,
      password: password,
    })
    .then(function (response) {
      const user = response.data.message;

      const { role, date_of_token } = extractJWTToken(user);
      if (role !== userConstants.ROLE_TUTOR && role !== userConstants.ROLE_CUSTOMER) {
        Swal.fire({
          icon: appConstants.ICON_ERROR,
          title: appConstants.DEFAULT_SWAL_TITLE_ACCOUNT_NOT_ALLOWED,
          text: appConstants.SWAL_MESSAGE_CONTACT_ERROR,
        })
        return
      } else {
        user["date_of_token"] = date_of_token;
        localStorage.setItem("user", JSON.stringify(user));

        return user;
      }
    })
    .catch(function (error) {
      Swal.fire({
        icon: appConstants.ICON_ERROR,
        title: appConstants.DEFAULT_SWAL_TITLE_LOGIN_ERROR,
        text: error.response.data.message || appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
      });
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
