export default class OptionModel {
  constructor(value, label = "", extra) {
    this.value = value;
    this.label = label;
    this.extra = extra;
  }

  buildFromInstance(instance) {
    Object.keys(this).forEach((key) => {
      this[key] = instance[key];
    });
    return this;
  }
}