import { store } from "../_helpers/store.js";
import { userConstants } from "../_constants";
import { apiService } from "./api.service";
import { loadStripe } from "@stripe/stripe-js";

export const tahService = {
    tah_update_all,
    tah_delete_all,
};

async function tah_update_all() {
    //fetch the data for a user
    var storeData = store.getState(), nextStore, stepGuide;
    if (storeData.authentication.hasOwnProperty("user")) {
        //si on a un user
        var user = storeData.authentication.user;
        if (user.role === userConstants.ROLE_TUTOR) {
            nextStore = await apiService.getTutorById(user.id)
            stepGuide = await apiService.getTutorStepGuide(nextStore.value?.tutor?.id);
        } else if (user.role === userConstants.ROLE_CUSTOMER) {
            nextStore = await apiService.getCustomerByUserId(user.id)
            stepGuide = await apiService.getCustomerStepGuide(nextStore.value?.customer?.id);
        }
        // Make sure to call `loadStripe` outside of a component's render to avoid
        // recreating the `Stripe` object on every render.
        const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
        nextStore.value = {
            ...nextStore.value,
            stripePromise: stripePromise,
            guide: {
                active: storeData?.tah?.user?.guide?.active === false ? false : true,
                step: stepGuide,
            }
        }
        return nextStore;
    }

    //inject the data in the reducer
}

async function tah_delete_all() {
    //delete all the data
    await store.dispatch({ type: userConstants.TAH_DELETE_ALL });
}
