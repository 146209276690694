export const idConstants = {
    tutorial: {
        FORM_COURSE_REPORT: "form-course-report",
        FORM_COURSE_REPORT_SECTION_1: "form-course-report-section-1",
        FORM_COURSE_REPORT_SECTION_2: "form-course-report-section-2",
        FORM_COURSE_REPORT_SECTION_3: "form-course-report-section-3",
        PROFIL: "profile",
        CHARACTERISTICS_SECTION: "characteristics-section",
        CHARACTERISTICS_SECTION_PART_1: "characteristics-section-part-1",
        CHARACTERISTICS_SECTION_PART_2: "characteristics-section-part-2",
        BURGER_MENU_BTN: "burger-menu-btn",
        BURGER_MENU: "burger-menu",
        COURSES_ARRAY_TAB: "courses-array-section",
        FIRST_COURSE_AVAILABLE: "first-course-available",

        COURSE_FORM_TAB: "course-form-tab",
        COURSE_HISTORY_SECTION: "course-history-section",
        TUTOR_SECTION: "tutor-section",
        SEARCH_TUTOR: "search-tutor",
        FORM_SOS_REQUEST: "form-sos-request",
    },
    COURSES_AVAILABLE_ARRAY: "COURSES_AVAILABLE_ARRAY",
    COURSE_MANAGER_ARRAY: "COURSE_MANAGER_ARRAY"
}