import { notificationConstants } from '../_constants';

export function notification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.ADD_ADMINISTRATIF:
      var administratif = state.administratif;
      administratif.push(action.message);
      var nextState = {
        ...state,
        administratif: administratif
      };
      return nextState;
    case notificationConstants.CLEAR:
      return {};
    default:
      return state
  }
}
