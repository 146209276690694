import React, { useState } from 'react';

import "./carousel.css";

export default function Carousel({ className = "", items = [], activeByDefault = 0, customer = false, onChange = () => { return } }) {
    const [activeItem, setActiveItem] = useState(activeByDefault); // By default activate the first item.

    function manageCarousel(direction) {
        let index = activeItem;
        if (direction === "next") {
            if (index + 1 >= items.length) {
                index = 0;
            } else {
                index++;
            }
        } else {
            if (index - 1 < 0) { // Get back -> last element of onglet list here
                index = items.length - 1;
            } else {
                index--;
            }
        }

        setActiveItem(index);
        onChange(items[index].title, items[index].path);
    }

    return (
        <div id="carousel" className={"carousel py-3 police-gilroy-font " + (className)} style={{ color: customer ? "var(--blue-tah-color)" : "var(--purple-tah-color)", borderBottom: 'solid 1px', borderTop: 'solid 1px' }}>
            <div className="carousel-inner text-center" style={{ fontSize: 30 + 'px' }}>
                {items?.map((item, index) => 
                    <div className={"carousel-item" + (index === activeItem ? " active" : "")} key={index}>
                        {item?.title?.toLocaleUpperCase()}
                    </div>
                )}
            </div>
            <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev" onClick={(e) => { manageCarousel('previous') }}>
                <span className={`carousel-control-prev-icon ${customer ? "carousel-control-prev-icon-blue" : ""}`} aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carousel" role="button" data-slide="next" onClick={(e) => { manageCarousel('next') }}>
                <span className={`carousel-control-next-icon ${customer ? "carousel-control-next-icon-blue" : ""}`} aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    )
}