export class XYChartModel  {
    constructor(x, y, nb_customers, duration) {
        this.x = x;
        this.y = y;
        this.nb_customers = nb_customers;
        this.duration = duration;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }
}