export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  FORGET_PASSWORD: "USERS_FORGET_PASSWORD",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  TAH_UPDATE_ALL: "TAH_UPDATE_ALL_DATA",
  TAH_DELETE_ALL: "TAH_DELETE_ALL_DATA",
  TAH_UPDATE_LESSONS: "TAH_UPDATE_ALL_LESSONS",
  TAH_UPDATE_REQUESTS_TO_REPORT: "TAH_UPDATE_REQUESTS_TO_REPORT",
  TAH_UPDATE_TUTOR_DETAILS: "TAH_UPDATE_TRANSPORTS_&_TAH_UPDATE_CHARACTERISTICS",
  TAH_SET_SUBJECTS: "TAH_SET_SUBJECTS",
  TAH_UPDATE_MULTIPLE: "TAH_UPDATE_MULTIPLE",
  TAH_UPDATE_LESSONS: "TAH_UPDATE_LESSONS",
  TAH_SET_OPTIONS: "TAH_SET_OPTIONS",
  TAH_SET_STEP_GUIDE: "TAH_SET_STEP_GUIDE",
  TAH_SET_GUIDE: "TAH_SET_GUIDE",

  ROLE_TUTOR: "ROLE_TUTOR",
  ROLE_CUSTOMER: "ROLE_CUSTOMER",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_STUDENT: "ROLE_STUDENT",
  ROLE_COACH: "ROLE_COACH",

  STUDENT_PROFILE: 1,
  PARENT_PROFILE: 0,

  PROFILE_UPDATE: "PROFILE_UPDATE",

  FILESTYPE_BY_ID :{
    statutAutoEntrepreneur: {id: 9, title: "statut auto-entrepreneur"},
    nova: {id: 10, title: "statut NOVA"},
    chgmtDomain: {id: 11, title: "Changement de domaine"},
    id: {id: 12, title: "Pièce d'identité"},
    avatar: {id: 13, title: "Avatar"},
    bac: {id: 14, title: "Notes au bac"},
    certifScola: {id: 15, title: "Preuve de scolarité"},
    certifAutoentreprise: {id: 16, title: "Preuve d'auto-entreprise"},
  },

  GENDER_FEMALE: "female",
  GENDER_MALE: "male",

  SET_STRIPE_PROMISE: "SET_STRIPE_PROMISE",
};
