import Swal from "sweetalert2";
import loading from "../process/loading";

/**
 * Display a Swal pop up and disable global loading.
 * @param {object} params Swal parameters object.
 */
async function globalSwal(params) {
    loading(false);
    await Swal.fire(params);
}

export default globalSwal;