import React, { useEffect, useState } from "react";
import PopoverMenu from "components/Others/menuWithButtons/menuPopover";
import AdvisorModal from "components/ParentPage/Modals/advisorModal";
import StudentReportModal from "components/ParentPage/Modals/studentReportModal";
import { Button } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmModal } from "../../../Others/modal/confirmModal";
import { store } from "../../../../_helpers";
import { userActions } from "../../../../_actions";
import { apiService } from "_api/api.service";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { customerConstants, idConstants, modalConstants, titleConstants, urlConstants } from "../../../../_constants";

import RedeemIcon from '@material-ui/icons/Redeem';

import "./burgerMenu.css";

export default function CustomerBurgerMenu({ id }) {
    const [advisorModalOpen, setAdvisorModalOpen] = useState(false);
    const [studentReportModalOpen, setStudentReportModalOpen] = useState(false);
    const customerStore = useSelector(store => store?.tah?.user?.customer);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        storeManager();
    }, [customerStore])

    /** Update store when new data found */
    async function storeManager() {
        if (!!customerStore?.id) {
            const advisor = await apiService.getAdvisor(customerStore.id);
            const students = await apiService.getCustomerStudents(customerStore.id);

            if (!advisor.isEqual(customerStore?.advisor)) {
                dispatch({
                    type: customerConstants.SET_ADVISOR,
                    value: advisor,
                });
            }
            if (!!!customerStore?.students || !students.every((student, index) => student.isEqual(customerStore?.students[index]))) {
                dispatch({
                    type: customerConstants.SET_STUDENTS,
                    value: students,
                });
            }

        }
    }

    function onConfirm() {
        store.dispatch(userActions.logout());
        window.location.reload();
    }

    return (
        <PopoverMenu id={id} className="police-gte-font d-flex">
            <div id={idConstants.tutorial.BURGER_MENU}>
                <AdvisorModal open={advisorModalOpen} onClose={_event => setAdvisorModalOpen(false)} advisor={customerStore?.advisor} />
                <StudentReportModal open={studentReportModalOpen} onClose={_event => setStudentReportModalOpen(false)} />

                {/* <Button className="menu-burger-customer-opt" variant="text" onClick={(_event) => setStudentReportModalOpen(true)}>
                <span className="police-gte-font">Suivi des élèves</span>
                <AssessmentOutlinedIcon style={{ color: "var(--blue-tah-color)" }} />
            </Button> */}
                <Button className="menu-burger-customer-opt" variant="text" onClick={(_event) => setAdvisorModalOpen(true)}>
                    <span className="police-gte-font">{titleConstants.BURGER_MENU_ADVISOR}</span>
                    <AccessibilityNewOutlinedIcon style={{ color: "var(--blue-tah-color)" }} />
                </Button>
                <a href={urlConstants.HELP_LINK} className="navbar-link">
                    <Button className="menu-burger-customer-opt" variant="text">
                        <span className="police-gte-font">{titleConstants.BURGER_MENU_HELP}</span>
                        <HelpOutlineIcon style={{ color: "var(--blue-tah-color)" }} />
                    </Button>
                </a>
                <a href={urlConstants.CUSTOMER_SPONSORSHIP} className="navbar-link">
                    <Button className="menu-burger-customer-opt" variant="text">
                        <span className="police-gte-font">{titleConstants.SPONSOR_CUSTOMER}</span>
                        <RedeemIcon style={{ color: "var(--blue-tah-color)" }} />
                    </Button>
                </a>
                <Button
                    variant="text"
                    className="menu-burger-logout-opt"
                    onClick={() => confirmModal(modalConstants.LOGOUT_TITLE, onConfirm)}
                >
                    <span className="police-gte-font font-weight-bold" >{titleConstants.BURGER_MENU_LOGOUT}</span>
                    <ExitToAppIcon style={{ color: "var(--red-tah-color)" }} />
                </Button>
            </div>
        </PopoverMenu>
    )
}