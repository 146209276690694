import React from "react";
import { Link } from "react-router-dom";

export default function Item({ menu, index, active }) {
    return (
        <Link
            to={menu.path}
            className="navbar-link mr-5"
            style={{ textDecoration: "none" }}
            key={index}
        >
            <span
                id={menu.id}
                className={
                    "navbar-link" +
                    (active
                        ? " navbar-link-active"
                        : "")
                }
            >
                {menu.title}
            </span>
        </Link>
    )
}