import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import FormStripeSetup from "./formStripeSetup";
import Button from "../Others/button/button";
import { MixinSwal } from "../Others/alert/mixinSwal";

import { useDispatch, useSelector } from "react-redux";
import { extractJWTToken } from "../../_helpers";
import { apiService } from "../../_api/api.service";
import { userActions } from "../../_actions";

import { userConstants, appConstants } from "../../_constants";
import join from "../../images/join.png";
import join2x from "../../images/join@2x.png";
import join3x from "../../images/join@3x.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoaderSpinner from "components/Others/loader/loader";


export default function FormCreatePassword({ recoverPassword, onPasswordChanged: onPasswordChangedProps = () => { return } }) {
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [isCustomer, setIsCustomer] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [disabled, setDisabled] = useState();
    const [redirect, setRedirect] = useState();
    const { token: passwordToken } = useParams();
    const userTokenJWT = useSelector(store => store.authentication?.user)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        init()
    }, [])

    async function init() {
        if (!!!recoverPassword) {
            const redirect = await apiService.isPasswordAlreadyAdded({token: passwordToken});
            setRedirect(redirect);
        } else setRedirect(false);
    }

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitted(true);
        setDisabled(true);

        const body = {
            token: passwordToken,
            newPassword: password,
            secondNewPassword: confirmedPassword,
        }

        if (password && confirmedPassword && password === confirmedPassword) {
            await apiService.createPassword(body).then(async response => {
                if (!response?.error) {
                    // Local storage fill with token and user data (minimal).
                    dispatch(
                        await userActions.login(response.email, password).then(action => {
                            MixinSwal({
                                title: recoverPassword ? appConstants.DEFAULT_SWAL_TITLE_PASSWORD_CHANGED_SUCCESS : appConstants.DEFAULT_SWAL_TITLE_PASSWORD_CREATED_SUCCESS,
                                icon: appConstants.ICON_SUCCESS,
                            }).then(_ => {
                                if (!recoverPassword) {
                                    // Extract data from JWT token to get Role (only in creation password mode).
                                    const tokenJWT = userTokenJWT;
                                    const decodedToken = extractJWTToken(tokenJWT);
                                    const isCustomer = decodedToken?.role === userConstants.ROLE_CUSTOMER;
                                    setIsCustomer(isCustomer);
                                    setSubmitted(false);

                                } else {
                                    onPasswordChangedProps();
                                }
                            });
                            return action;
                        })
                    );


                } else {
                    Swal.fire({
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: response.message,
                        icon: appConstants.ICON_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                    this.setState({
                        submitted: false,
                        disabled: false,
                    });
                }
            })

        } else if (password !== confirmedPassword) {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_PASSWORDS_DIFFERENT_ERROR,
                icon: appConstants.ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
            setSubmitted(true);
            setDisabled(true);
        } else {
            setSubmitted(true);
            setDisabled(true);
        }
    }

    // If customer, go to Stripe form, other wise go back to login page.
    if (typeof isCustomer !== "undefined" && !recoverPassword) {
        if (isCustomer) {
            history.replace("/stripe-setup");
            return (
                <FormStripeSetup isSetUp/>
            )
        } else {
            history.push("/login");
        }
    }

    if (typeof redirect === "undefined") {
        return (<LoaderSpinner />)
    }
    if (!passwordToken || redirect) {
        history.push("/login");
    }

    return (
        <Form className="d-flex flex-column justify-content-center m-auto h-100">
            <h1 className="connexion-title pb-4 text-center">
                {recoverPassword ? "NOUVEAU MOT DE PASSE" : "CREATION DU MOT DE PASSE"}
            </h1>
            <Form.Group controlId="password" className="w-100 mx-auto mt-4" style={{ maxWidth: 600 }}>
                <Form.Label className="connexion-label-input">
                    Mot de passe
                </Form.Label>
                <Form.Control
                    className={"connexion-form-input" + (submitted && (!password) ? " is-invalid" : "")}
                    type="password"
                    placeholder="Entrer votre mot de passe"
                    value={password}
                    onChange={(event) => { setPassword(event.target.value) }}
                    style={{ height: 40 + "px" }}
                    disabled={disabled}
                >
                </Form.Control>
                <div className="invalid-feedback">Veuillez remplir ce champ.</div>

            </Form.Group>
            <Form.Group controlId="confirmedPassword" className="w-100 mx-auto mt-4" style={{ maxWidth: 600 }}>
                <Form.Label className="connexion-label-input">
                    Confirmer mot de passe
                </Form.Label>
                <Form.Control
                    className={"connexion-form-input" + (submitted && (!confirmedPassword) ? " is-invalid" : "")}
                    type="password"
                    placeholder="Confirmer votre mot de passe"
                    value={confirmedPassword}
                    onChange={(event) => { setConfirmedPassword(event.target.value) }}
                    style={{ height: 40 + "px" }}
                    disabled={disabled}
                ></Form.Control>
                <div className="invalid-feedback">Veuillez remplir ce champ.</div>
            </Form.Group>

            <div className="text-center mt-5">
                <Link to={"tutor-home/tableau-de-bord"}>
                    <Button
                        className="btn connexion-button rounded-border mb-3 px-4 py-2"
                        onClick={onSubmit}
                        spinColor="white"
                    >
                        CRÉER MOT DE PASSE
                        <Image
                            src={join}
                            srcSet={join + " 1x," + join2x + " 2x," + join3x + " 3x"}
                            className="ml-2 connexion-button-img h-100"
                            alt="Eddmon"
                        ></Image>
                    </Button>
                </Link>
            </div>
        </Form>
    )
}