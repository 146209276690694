import DEFAULT_PROFILE_PICTURE from "../images/default-profil-picture.jpeg";
import OptionModel from "../_models/option.model";
import { idConstants } from "./id.constants";
import { urlConstants } from "./url.constants";

export const appConstants = {
    /***** FILTERS *****/
    LEVEL_FILTER: "level_filter",
    SUBJECT_FILTER: "subject_filter",
    FILTER_VALUE_ALL: "",
    FILTER_VALUE_HOME: "home",
    FILTER_VALUE_ONLINE: "online",

    /***** SWAL *****/
    // ICONS
    ICON_ERROR: "error",
    ICON_SUCCESS: "success",
    ICON_WARNING: "warning",
    ICON_INFO: "info",

    // TITLES
    DEFAULT_SWAL_TITLE_SUCCESS: "C'est fait !",
    DEFAULT_SWAL_TITLE_ERROR: "Une erreur est survenue.",
    DEFAULT_SWAL_TITLE_PROCESS_FAILED: "Demande intraitable",
    DEFAULT_SWAL_TITLE_LOGIN_ERROR: "Erreur de connexion.",
    DEFAULT_SWAL_TITLE_EMPTY_FIELDS: "Veuillez remplir tout les champs.",
    DEFAULT_SWAL_TITLE_PASSWORD_CHANGED_SUCCESS: "Votre mot de passe a été modifié.",
    DEFAULT_SWAL_TITLE_ACCOUNT_NOT_ALLOWED: "Votre compte ne vous permet pas d'accèder à ce service.",
    DEFAULT_SWAL_TITLE_MAIL_PASSWORD_SEND: "Email envoyé !",
    DEFAULT_SWAL_TITLE_PASSWORD_CREATED_SUCCESS: "Votre mot de passe a bien été créé.",
    DEFAULT_SWAL_TITLE_PASSWORDS_DIFFERENT_ERROR: "Les deux mots de passe doivent être identiques.",
    DEFAULT_SWAL_TITLE_ACCESS_DENIED: "Accès refusé",
    DEFAULT_SWAL_TITLE_APPLICATION_ALREADY_REGISTERED: "Vous avez déjà candidaté à cette demande.",
    DEFAULT_SWALL_TITLE_APPLICATION_REGITERED: "Votre candidature a bien été prise en compte.",
    DEFAULT_SWALL_TITLE_HOMEWORK_UPDATE_SUCCESS: "Enregistré",
    DEFAULT_SWAL_CVG_REQUIRED: "Veuillez lire et accepter les conditions générales de vente",

    // MESSAGES
    DEFAULT_SWAL_MESSAGE_ERROR: "Veuillez réessayer plus tard ou nous contacter via : eddmon@eddmon.fr",
    DEFAULT_SWAL_MESSAGE_CHANGE_PASSWORD_ERROR: "Votre mot de passe n'a pas été modifié.",
    DEFAULT_SWAL_MESSAGE_ACCESS_DENIED: "Accès refusé. Vous allez être redirigé vers la page de connexion.",
    SWAL_MESSAGE_CONTACT_ERROR: "Vous pouvez nous contacter via  : eddmon@eddmon.fr",
    SWAL_MESSAGE_COURSE_DECLARATION_SUCCESS: "Votre déclaration a bien été prise en compte.",
    STRIPE_CARD_ADDED_SUCCESS: "La carte bancaire a bien été ajoutée.",
    STRIPE_IBAN_ADDED_SUCCESS: "L'IBAN a bien été ajouté.",
    SWAL_MESSAGE_DISLIKE_SUCCESS: "Cette demande a été supprimée de votre espace.",
    DEFAULT_SWAL_MESSAGE_MAIL_PASSWORD_SEND: (email) => { return (`Un email vous a été envoyé à l'addresse ${email} pour rénitialiser votre mot de passe. `) },
    INCORRECT_TOKEN_MESSAGE_ERROR: "Lien invalide ou expiré.",
    DEFAULT_SWAL_MESSAGE_APPLICATION_ALREADY_REGISTERED: "Votre candidature est déjà en cours de traitement par nos équipes !",
    DEFAULT_SWALL_MESSAGE_APPLICATION_REGITERED: "Votre candidature sera traitée par nos équipes !",
    DEFAULT_SWALL_MESSAGE_HOMEWORK_UPDATE_SUCCESS: "Le statut du devoir a été mis à jour.",
    SOS_REQUEST_SUBMITTED_SUCCESS: "Votre demande a bien été soumise et sera traitée dans les plus brefs délais.",
    REVIEW_SUBMITTED: "Votre avis a bien été soumis. Vous allez être redirigé.",

    // BUTTONS'S TEXT
    DEFAULT_SWAL_TEXT_BTN: "OK",
    /***** *****/

    /***** OTHERS **/
    DEFAULT_NO_DATE: "--/--/----",
    DEFAULT_NO_TIME: "--",
    DEFAULT_NO_TUTOR_NAME: "?",
    PREFIX_BILLS_TITLE: "Facture n° ",
    DEFAULT_TIME_OUT_STRIPE: 1000,
    DEFAULT_CURRENCY_SYMBOL: "€",
    OUTSTANDINGBALANCE_UNKNOW: "Inconnue",
    DEFAULT_NO_FILE: "Introuvable",
    DEFAULT_INVALID_FEEDBACK: "Veuillez remplir ce champ.",
    DEFAULT_INVALID_FEEDBACK_MIN_LENGTH: (currentLength, minLength) => `Veuillez remplir ce champ. (${currentLength} / ${minLength})`,
    DEFAULT_ACCOUNT_STRIPE_NEEDS_VERIF: "A activer",
    INVALID_FEEDBACK_PHONE_FORMAT_ERROR: "Format incorrect.",
    UNAVAILABLE_PAGE_MSG: "Page non disponible.",
    EMPTY_DATA: "Aucune",
    EMPTY_OPTIONS: "Aucune donnée à afficher",
    EMPTY_OPTIONS_STUDENT: "Aucun cours à déclarer",
    INVALID_FEEDBACK_ONE_OPTION: "Veuillez sélectionner une option.",
    INVALID_FEEDBACK_ONE_STUDENT: "Veuillez sélectionner au moins un élève.",
    NEED_STUDENT_SELECTED: "Veuillez sélectionner un élève pour afficher ses précédents devoirs.",
    NO_REVENUES: "Aucune donnée à afficher (donnez des cours et les revenus de ces derniers s'afficheront ici à condition d'avoir créer un compte Stripe 😉).",
    INVALID_FEEDBACK_SELECT_SKILLS_MIN_1: "Veuillez sélectionner au moins 1 matière.",

    /***** FORMS *****/
    FORM_NO_CARD_REGISTERED: "A renseigner ci dessous ",
    FORM_LABEL_INVOICE: "Facture",
    FORM_LABEL_FIRSTNAME: "Prénom",
    FORM_LABEL_LASTNAME: "Nom",
    FORM_LABEL_CIVILITY: "Civilité",
    FORM_LABEL_ADDRESS: "Adresse",
    FORM_LABEL_ADDRESS_NUMBER: "N°",
    FORM_LABEL_ADDRESS_STREET: "Avenue, rue, etc.",
    FORM_LABEL_ADDRESS_CITY: "Ville",
    FORM_LABEL_ADDRESS_POSTCODE: "Code Post.",
    FORM_LABEL_PHONE: "Téléphone",
    FORM_LABEL_CREATION_DATE: "Date de création",
    FORM_LABEL_MAIL: "Email",
    FORM_LABEL_SIRET: "SIRET",
    FORM_LABEL_IBAN: "IBAN",
    FORM_LABEL_FILE_BAC: "Relevé du Baccalauréat",
    FORM_LABEL_FILE_ID: "Pièce d'identité",
    FORM_LABEL_FILE_SCHOOL_PROOF: "Certificat de scolarité",
    FORM_LABEL_BILLING_ADDRESS: "Addresse de facturation",
    FORM_LABEL_CLASS: "Classe",
    FORM_LABEL_SCHOOL: "École",
    FORM_LABEL_COLLEGE: "Etablissement du supérieur",
    FORM_LABEL_BIRTHDATE: "Date de naissance",
    FORM_LABEL_PASSIONS: "Passion(s)",
    FORM_LABEL_NOTES: "Notes",
    FORM_LABEL_SEARCH: "RECHERCHE",
    FORM_LABEL_LESSON_DURATION: "Durée (heure)",
    FORM_LABEL_LESSON_TYPE: "Status du cours",
    FORM_LABEL_SUBJECTS: "Matière(s)",
    FORM_LABEL_DATE: "Date",
    FORM_LABEL_PAYMENT_STATE: "État",
    //FORM_LABEL_COURSE_TYPE: "Type de cours",
    FORM_LABEL_COURSE_DURATION: "Durée du cours",
    FORM_LABEL_COURSE_DURATION_2: "Durée de chaque cours",
    FORM_LABEL_ADD_CHILDREN: "Ajouter un élève au cours",
    FORM_LABEL_COMMENT: "Axe d’amélioration",
    FORM_LABEL_COURSE_DATE: "Date du cours",
    FORM_LABEL_TRANSFER_TYPE: "Type de transfert",
    FORM_LABEL_COURSE_SUMMARY: "Retour factuel",
    FORM_LABEL_ADD_HOMEWORK: "Devoirs:",
    FORM_LABEL_ADD_HOMEWORK_REQUIRED: "Obligatoire",
    FORM_LABEL_ADD_HOMEWORK_OPTIONAL: "Facultatif",
    FORM_LABEL_HOMEWORK_ENTITLED: "Intitulé",
    FORM_LABEL_DESCRIPTION: "Description",
    FORM_LABEL_HOMEWORK_DEADLINE: (windowWidth, minWidth) => { return (windowWidth < minWidth ? "Date limite" : "Date limite pour réaliser le devoir") },
    FORM_LABEL_PREVIOUS_HOMEWORK: "Devoirs précédents:",
    FORM_LABEL_HOMEWORK_CHECK_IN_DATE: "Date de réalisation",
    FORM_LABEL_HOMEWORK_CHECK_IN_COMMENT: "Commentaire sur le devoir",
    FORM_LABEL_UNDERSTANDING_RATING: "Compréhension",
    FORM_LABEL_SPEED_RATING: "Vitesse",
    FORM_LABEL_MOTIVATION_RATING: "Motivation",
    FORM_LABEL_COURSE_TYPE: "À domicile / En ligne",
    FORM_LABEL_PREVIOUS_PASSWORD: "Ancien mot de passe",
    FORM_LABEL_NEW_PASSWORD: "Nouveau mot de passe",
    FORM_LABEL_CONFIRM_NEW_PASSWORD: "Confirmer nouveau mot de passe",
    FORM_LABEL_COURSE_ON_SWYPE: "Cours effectué sur Swype ?",
    FORM_LABEL_COURSE_ON_SWYPE_OK: "La séance s'est elle bien déroulée (micro, son, image ...) ?",
    FORM_LABEL_SWYPE_ISSUES: "Décris nous le(s) problème(s) rencontré(s)",
    FORM_LABEL_SWYPE_NOT_USED_REASON: "Décris nous pourquoi tu n'as pas utilisé Swype",
    FORM_LABEL_DECLARE_COURSE_WITHOUT_HOMEWORK: "Valider ma déclaration sans devoir malgré la recommandation.",
    FORM_LABEL_NATIONALITIES: "Nationalités",
    FORM_LABEL_STUDENT: "Élève",
    FORM_LABEL_STARTING_DATE: "Date de début",
    FORM_LABEL_ENDING_DATE: "Date de fin",
    FORM_LABEL_AVAILABILITIES: "Disponibilités",
    FORM_LABEL_OPTIONS: "Options",
    FORM_LABEL_REVIEW_GRADE: "Étoiles",
    FORM_LABEL_NBR_LESSONS: "Nombre de séances par semaine",
    FORM_LABEL_TROUBLES_MANAGED: "Maîtrise les troubles",
    FORM_LABEL_COURSES_TO: "Je veux donner des cours aux",
    FORM_LABEL_TRANSPORTS_USED: "Tu peux te déplacer en",
    FORM_LABEL_CGV: ["Veuillez lire et accepter les ", "conditions générales de vente", "."],
    FORM_LABEL_GENDER_PREFERENCE: "Préférence du tuteur",
    /***** *****/

    /***** OTHER LABELS *****/
    BURGER_MENU_LABEL_NOTIFICATIONS: "Notifications",
    /***** *****/

    /***** DIMENSIONS *****/
    SCREEN_WIDTH_EXTRA_LARGE: 1200,
    SCREEN_WIDTH_LARGE: 992,
    SCREEN_WIDTH_MEDIUM: 768,
    SCREEN_WIDTH_SMALL: 576,
    /***** *****/

    /***** FILES *****/
    DEFAULT_PROFILE_PICTURE: DEFAULT_PROFILE_PICTURE,
    /***** *****/

    /***** OTHERS *****/
    DEFAULT_MESSAGE_COPY_TO_CLIPBOARD: "Copié !",
    DEFAULT_TEXT_MENTORING_TUTOR: "Des amis ou proches qui souhaitent donner des cours ? Envoie-leur ton code et gagne 5€* sur ton prochain cours.",
    DEFAULT_SUBTEXT_MENTORING_TUTOR: "*Sous réserve que l'étudiant(e) conseillé(e) ait au minimum dispensé un cours.",
    DEFAULT_SUBTEXT_MENTORING_CUSTOMER: ["Un parent dans votre entourage souhaite prendre des cours ? Avec votre code, ", "gagnez 50€ à chaque premier cours ", "pris par une nouvelle famille !"],
    DEFAULT_LESSON_TYPE_OPTIONS: [{ value: false, label: "Domicile" }],
    DEFAULT_LESSON_TYPE_NO_OPTION: label => { return [{ value: null, label: label, disabled: true }] },
    DEFAULT_LOADING_MESSAGE: "Chargement...",
    HOURS_TO_DECLARE_COURSE: 148,
    HOMEWORK_CHECK_IN_COMMENT_MIN_LENGTH: 50,
    NO_STRIPE_ACCOUNT_ID: "Il semble que tu n'as pas de compte Stripe associé à ton compte Eddmon. Clique sur le bouton suivant pour créer un compte Stripe et débloquer de nouvelles fonctionnalités !",
    STRIPE_ACCOUNT_CAN_RECEIVE_PAYMENT: "Ton compte Stripe semble incomplet ou indisponible. Complète les informations manquantes sur Stripe en cliquant sur ce lien.",
    SIRET_NOT_PROVIDED: "Tu n'as pas encore de SIRET enregistré. Si tu l'as reçu, renseigne le en cliquant sur le bouton ci dessous. ",
    FORM_RADIO_NO: false,
    FORM_RADIO_YES: true,
    /***** *****/

    /***** FINANCES TAB *****/
    NO_LESSON_INPUT: "-",
    NO_AMOUNT_INPUT: "- €",
    ONE_LESSON_INPUT: "leçon",
    SEVERAL_LESSONS_INPUT: "leçons",
    TRANSFER_TYPE_OPTIONS: [new OptionModel(0, "En cours"), new OptionModel(1, "Effectués"), new OptionModel(-1, "En attente de paiement")],
    TRANSFER_TYPE_IN_PROGRESS: 0,
    TRANSFER_TYPE_DONE: 1,
    TRANSFER_TYPE_PENDING: -1,
}

/***** COLORS *****/
export const colorsConstants = {
    COLOR_WHITE: "white",
    PURPLE: "#927BDB",
    LIGHT_BLUE: "#91AFE5",
    BLACK: "black",
    RED: "red",
    GREEN: "green"
}

export const navbarMenuConstants = {
    TUTOR: [
        {
            path: urlConstants.TUTOR_DASHBOARD_RAW,
            title: "Tableau de bord",
        },
        {
            id: idConstants.tutorial.PROFIL,
            path: urlConstants.TUTOR_PROFILE,
            title: "Profil",
        },
        {
            path: urlConstants.TUTOR_MY_FINANCES,
            title: "Mes finances",
        },
    ],
    CUSTOMER: [
        {
            path: urlConstants.CUSTOMER_DASHBOARD,
            title: "Tableau de bord",
        },
        {
            path: urlConstants.CUSTOMER_PROFILE,
            title: "Profil",
        },
        {
            id: idConstants.tutorial.SEARCH_TUTOR,
            path: urlConstants.CUSTOMER_SOS_REQUEST_FORM,
            title: "🔍 Tuteur",
        },
    ],
}

export const popoverMessagesConstants = {
    MESSAGE_POPOVER_UNDERSTANDING: "Compréhension des notions vues pendant le cours",
    MESSAGE_POPOVER_SPEED: "L'élève est-il rapide dans ce que tu lui demandes de faire, ou plutôt lent ?",
    MESSAGE_POPOVER_MOTIVATION: "Note la motivation de l'élève pendant le cours",
    MESSAGE_POPOVER_GRADUATION_OUTCOMES: (hours) => {return `Dès que tu auras donné ${hours} heures de cours et effectué un parrainage tu obtiendras une rémunération majorée sur tes cours !`},
    MESSAGE_POPOVER_GRADE: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    CUSTOMER_UNPAID_LESSON: "Non réglée",//"Cette leçon n'a pas encore été payée"
}

export const tooltipConstants = {
    REPORT_LABEL_HOME: "À domicile",
    REPORT_LABEL_ONLINE: "En ligne",
    TOTAL_PAYOUT_TYPE_IN_PROGRESS: "En cours",
    TOTAL_PAYOUT_TYPE_DONE: "Effectués",
    TOTAL_PAYOUT_TYPE_PENDING: "En attente de paiement",
    DISABLED_NO_STUDENT_SELECTED: "Aucun élève sélectionné"
}

export const homeworkConstants = {
    FORM_CREATE: 0,
    FORM_CHECK_IN: 1,
}

export const reportConstants = {
    FORM_HOMEWORK_REQUIRED_CHECK: 0,
    FORM_NO_HOMEWORK_REQUIRED_CHECK: 1,
}

export const modalConstants = {
    LOGOUT_TITLE: "Es-tu sûr(e) de vouloir te déconnecter ?",
    DISLIKE_TITLE: "Supprimer cette demande de votre espace ?",
    REPORT_VALIDATION_TITLE: "Déclarer le cours sans laisser de devoir ? 📚",
    REPORT_VALIDATION_SUBTITLE: "Nous te recommandons de laisser un devoir à tes élèves afin de mieux suivre leur progression et leur apprentissage.",
    SWYPE_TITLE: "🚧 Un instant ! Réponds à 2 questions avant de partir, afin de nous aider à améliorer nos services 🚧",
    ADVISOR_TITLE: "Mon conseiller",
    STUDENT_REPORT_TITLE: "Suivi des élèves",
    CONTEST_CONFIRM: "Contester cette leçon ?",
    REMOVE_ITEM_CONFIRM: "Confirmer la suppression ?",
    LADDER_TITLE: "🏆 Classement Général 🏆",
    NEW_CONDITIONS: "😇 Nous avons apporté quelques modifications à nos conditions pour toutes les prochaines mise en relation 😇",
    NEW_CONDITIONS_SUBTITLE: "Les voici résumées en 3 points principaux. Pour candidater, valide les conditions avec le bouton ci-dessous !",
    CONDITION_1: "👌 En parrainant 1 nouveau tuteur (entretien validé) et en faisant 35 nouvelles heures de cours, tu gagneras un bonus sur chaque heure de cours (check ton dashboard pour les valeurs exactes) !",
    CONDITION_2: "👌 Pour tes futures mises en relation, il faudra effectuer 5 cours pour recevoir le premier paiement (sauf avec accord de la famille), le paiement hebdomadaire reste ensuite le même.",
    CONDITION_3: "👌 En fonction des clients, tu pourras payer moins de charges sociales avec l'avance immédiate de crédit d'impôt (tu as le choix) 😉",

    /** EVENTS */
    ACCOUNT_STRIPE_NEEDS_VERIF_TITLE: "⚠️ Compte Stripe nécessaire ⚠️",
    ACCOUNT_STRIPE_NEEDS_VERIF: "Il semblerait que tu aies des cours déclarés mais que ton compte Stripe ne soit pas activé. Stripe est une plateforme de paiement où tu trouveras les versements des cours que tu as donnés.",

}

export const revenuesTableConstants = {
    MONTH_COL: "Mois",
    REVENUES_COL: (windowWidth) => windowWidth > appConstants.SCREEN_WIDTH_SMALL ? "Chiffre d'affaires (en €)" : "CA (€)",
    FAMILIES_COL: (windowWidth) => windowWidth > appConstants.SCREEN_WIDTH_SMALL ? "Total de familles" : "Familles",
    HOURS_COL: (windowWidth) => windowWidth > appConstants.SCREEN_WIDTH_SMALL ? "Total d'heures" : "Heures",
}

export const infoTextConstants = {
    APPLICATION_FEES_MESSAGE: (amount) => `Frais d'inscription Eddmon : ${amount}€`
}

export const placeHolderConstants = {
    FILL_WITH_NUMBER: "Entre un chiffre",
    FILL_COMMENTARY: "Exemple : Mon fils a besoin d'un soutien ciblé en mathématiques, il bloque en ce moment sur les fonctions linéaires.",
    AVAILABILITIES: "Lundi soir à 18h, mercredi entre 14h et 18h et dimanche de 10h à 12h",
}
