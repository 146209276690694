import React from 'react';
import { Button } from '@material-ui/core';

import StepModel, { TYPE } from "_models/step.model";

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader } from '@fortawesome/free-solid-svg-icons'

import { idConstants, titleConstants, urlConstants, buttonConstants, colorsConstants } from "_constants";
import LOGO from '../../../images/logo.png';
import InfoIcon from '@material-ui/icons/Info';
import ButtonSquare from 'components/Others/button/buttonSquare/buttonSquare';

export const STEPS = [
    new StepModel().buildFromInstance({
        title: () => <div>
            <FontAwesomeIcon className="mx-2" icon={faBookReader} style={{ color: "var(--blue-tah-color)" }} />
            TUTORIEL
            <FontAwesomeIcon className="mx-2" icon={faBookReader} style={{ color: "var(--blue-tah-color)" }} />
        </div>,
        description: (options) => <div>
            Bienvenue sur ton compte <img className="mb-1" src={LOGO} width="100" />
            <br />
            <br /> Dans ce tutoriel, nous allons t'initier aux fonctionnalités de l'application à travers <b>{options?.steps} étapes</b>.
            <br />
            <br />
            <div className="d-sm-none">
                Clique sur le bouton
                <Button variant="contained" className="unselectable mx-1 d-sm-none" size="small" style={{ color: "var(--blue-tah-color)", backgroundColor: "var(--tr-light-gray-tah-color)" }} ><KeyboardArrowRight /></Button> pour commencer !
            </div>
            <div className="d-none d-sm-flex align-items-center">
                Clique sur le bouton
                <Button variant="contained" className="unselectable mx-1  d-sm-flex" size="small" style={{ color: "var(--blue-tah-color)", backgroundColor: "var(--tr-light-gray-tah-color)" }} >{buttonConstants.NEXT} <KeyboardArrowRight /></Button>  pour commencer !
            </div>
        </div>,
        url: urlConstants.TUTOR_DASHBOARD_TAB_1,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FIRST_COURSE_AVAILABLE,
        title: () => titleConstants.COURSE_AVAILABLE_DEMO,
        description: () => <div>
            <div className="d-sm-flex align-items-center">
                <p className="text-nowrap m-0">Clique sur le bouton</p>
                <div className="ml-2" style={{ width: 40 }}><ButtonSquare inline className="unselectable">i</ButtonSquare></div>
                <p className="text-nowrap m-0">pour accéder aux détails du cours</p>
                <p className="text-nowrap m-0 ml-1"><i>(date, rémunération, adresse)</i>.</p>
            </div>
            <div className="d-sm-flex align-items-center">
                <p className="text-nowrap m-0">Tu peux également retirer</p>
                <p className="text-nowrap m-0 ml-1">les cours qui ne t'intéressent</p>
                <p className="text-nowrap m-0 ml-1">de ton espace <img className="mb-1 mx-2" src={LOGO} width="100" /></p>
                <p className="text-nowrap m-0 ml-1">avec le bouton</p>
                <div style={{ width: 40 }}><ButtonSquare className="unselectable d-flex justify-content-end" color={colorsConstants.RED}>✕</ButtonSquare></div>
            </div>
            <br />
        </div>,
        onLoad: (anchor) => {
            const container = document.getElementById("scroll-container")
            container && container.scrollTo({ behavior: "smooth", top: 0 })
            return anchor
        },
        url: urlConstants.TUTOR_DASHBOARD_TAB_1,
        placement: ["top"],
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.COURSE_FORM_TAB,
        title: () => titleConstants.DECLARE_COURSE.toLocaleUpperCase(),
        description: (_options) => "Clique sur cet onglet pour accéder au formulaire de déclaration de cours...",
        url: urlConstants.TUTOR_DASHBOARD_TAB_1,
        userAction: { element: (anchor) => { return anchor }, collapse: false },
        type: TYPE.CLICK,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_COURSE_REPORT,
        title: () => titleConstants.DECLARE_COURSE.toLocaleUpperCase(),
        description: (_options) => <div>
            Nous apportons beaucoup d’importance aux retours que tu fais des sessions, c’est la scolarité des élèves qui est en jeu !
            <br />
            Ci-dessous découvre les informations que l’on te demande pour bien les suivre 🙂
            <br />
        </div>,
        url: urlConstants.TUTOR_DASHBOARD_TAB_3,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_COURSE_REPORT_SECTION_1,
        title: () => titleConstants.DECLARE_COURSE.toLocaleUpperCase(),
        description: (_options) => <div>
            Dans la première section <span className='police-gilroy-font mx-1'>{titleConstants.REPORT_FORM_SECTION_1}</span> renseigne les informations générales du cours: <i>élève, date, matière(s)</i> et <i>durée du cours</i>.
        </div>,
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            anchor.scrollIntoView({ behavior: "smooth" })
            return anchor
        },
        url: urlConstants.TUTOR_DASHBOARD_TAB_3,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_COURSE_REPORT_SECTION_2,
        title: () => titleConstants.DECLARE_COURSE.toLocaleUpperCase(),
        description: (_options) => <div>
            Regarde bien la description de chaque note (le petit <InfoIcon style={{ position: "relative", top: "5px", color: "var(--purple-tah-color)" }} />). Si tu as indiqué plus haut avoir fait des mathématiques et que l’élève a une mauvaise note de compréhension, nous pourrons mieux cibler les efforts à faire avec l’élève !</div>,
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            anchor.scrollIntoView({ behavior: "smooth" })
            return anchor
        },
        url: urlConstants.TUTOR_DASHBOARD_TAB_3,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.FORM_COURSE_REPORT_SECTION_3,
        title: () => titleConstants.DECLARE_COURSE.toLocaleUpperCase(),
        description: (_options) => <div>
            Ici dissocie bien la description factuelle “3 exercices de mathématiques en trigonométrie” de l’impression générale “L’élève est super motivé, continue comme ça !”. Le retour est envoyé à la famille ainsi que la moyenne des 3 notes 🙂
        </div>,
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            setTimeout(() => anchor.scrollIntoView({ behavior: "smooth" }), 100)
            return anchor
        },
        url: urlConstants.TUTOR_DASHBOARD_TAB_3,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.PROFIL,
        title: () => null,
        description: () => <div>
            Viens voir comment personnaliser ton profil et les demandes de cours avec lesquelles tu pourras matcher !
        </div>,
        userAction: { element: (anchor) => { return anchor }, collapse: false },
        type: TYPE.CLICK,
        mobileSkip: true,
        url: urlConstants.TUTOR_DASHBOARD_TAB_3,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.CHARACTERISTICS_SECTION,
        title: () => null,
        description: () => <div>
            Clique sur <span className="step-title">{titleConstants.CHARACTERISTICS.toLocaleUpperCase()}</span> pour paramétrer ton profil.
        </div>,
        userAction: { element: (anchor) => { return anchor.children[0] } },
        type: TYPE.CLICK,
        url: urlConstants.TUTOR_PROFILE,
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.CHARACTERISTICS_SECTION,
        title: () => titleConstants.CHARACTERISTICS,
        description: () => <div>
            C'est ici que tout se passe !
            <br />
            Chaque information que tu ajoutes nous permet de te proposer des élèves qui te correspondent davantage. Si tu as des passions en commun, c’est encore mieux !
        </div>,
        url: urlConstants.TUTOR_PROFILE,
        placement: ["bottom", "right-start"],
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            const container = document.getElementById("scroll-container")
            container && container.scrollTo({ behavior: "smooth", top: 0 })
            return anchor
        },
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.CHARACTERISTICS_SECTION,
        title: () => titleConstants.CHARACTERISTICS,
        description: () => <div>
            Sélectionne ici tes moyens de transports, cela nous aidera à t'adresser des élèves proches de chez toi 🙂
        </div>,
        url: urlConstants.TUTOR_PROFILE,
        placement: ["bottom", "right-start"],
        onLoad: (anchor) => {
            if (!Object.values(anchor.children)?.some(el => el.className.includes("active"))) {
                anchor.children[0].click()
            }
            const container = document.getElementById("scroll-container")
            container && container.scrollTo({ behavior: "smooth", top: container.scrollHeight })
            return anchor
        },
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.BURGER_MENU_BTN,
        title: () => null,
        description: () => <div>
            Clique sur le <span className="step-title">Menu Burger</span> pour accéder aux autres fonctionnalités.
        </div>,
        userAction: { element: (anchor) => { return anchor } },
        type: TYPE.CLICK,
        url: urlConstants.TUTOR_PROFILE,
        placement: ["bottom", "right-start"],
    }),
    new StepModel().buildFromInstance({
        id: idConstants.tutorial.BURGER_MENU,
        title: () => titleConstants.BURGER_MENU,
        description: () => <div>
            Enfin, si tu ne souhaites pas recevoir de propositions proches de chez toi, désactive les notifications.
            <br />
            Pour chaque tuteur qui s’inscrit avec ton code, vous gagnez 5€ chacun sur vos cours 🤑
        </div>,
        type: TYPE.MODAL,
        placement: ["top", "top"],
        onLoad: (anchor) => {
            let clickEl = document.getElementById(idConstants.tutorial.BURGER_MENU_BTN)
            if (!!!clickEl) {
                clickEl.click()
            }
            if (clickEl && !clickEl?.className.includes("active")) {
                clickEl.click()
            }
            return document.getElementById(idConstants.tutorial.BURGER_MENU)
        },
    }),
];