import LessonModel from "./lesson.model";

export default class PayoutsModel {
    /**
     * 
     * @param {string} arrival_date 
     * @param {Array<LessonModel>} lessons 
     * @param {number} amount 
     * @param {number} status 0: in progess | 1: done | -1: waiting for payment
     */
    constructor(created, arrival_date, lessons = [], amount, status) {
        this.created = created;
        this.arrival_date = arrival_date;
        this.lessons = lessons;
        this.amount = amount;
        this.status = status;
    }

    buildFromInstance(instance) {
        Object.keys(instance).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }
}