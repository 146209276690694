export const TYPE = {
  DEFAULT: "default",
  CLICK: "click",
  MODAL: "modal",
}

export class UserActionModel {
  constructor(element = (anchor) => { return anchor }, collapse) {
    this.element = element;
    this.collapse = typeof collapse !== "boolean" ? true : collapse;
  }

  buildFromInstance(instance) {
    Object.keys(this).forEach((key) => {
      if (key === "collapse") {
        this[key] = typeof instance[key] !== "boolean" ? this[key] : instance[key];
      } else {
        this[key] = instance[key] || this[key];
      }
    });
    return this;
  }
}
export default class StepModel {
  constructor(id = null, disabledChildrenId = [], title = () => null, description, userAction = new UserActionModel(), url = null, placement = [], type = TYPE.DEFAULT, mobileSkip = false, onLoad = (anchor) => { return anchor }) {
    this.id = id;
    this.disabledChildrenId = disabledChildrenId;
    this.title = title;
    this.description = description;
    this.userAction = userAction;
    this.url = url;
    this.placement = placement;
    this.type = type;
    this.onLoad = onLoad;
    this.mobileSkip = mobileSkip;
  }

  buildFromInstance(instance) {
    Object.keys(this).forEach((key) => {
      if (key === "userAction" && !!instance["userAction"]) {
        this[key] = new UserActionModel().buildFromInstance(instance["userAction"])
      } else {
        this[key] = instance[key] || this[key];
      }
    });
    return this;
  }
}