import React, { useState } from 'react';
import ButtonAction from 'components/Others/button/buttonAction/buttonAction';
import { MixinSwal } from '../../../Others/alert/mixinSwal';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import globalSwal from '../../../Others/alert/globalSwal';
import loading from '../../../Others/process/loading';

import { appConstants, colorsConstants } from '../../../../_constants/app.constants';
import CheckboxFormGroupInput from 'components/Others/forms/components/checkboxFormGroupInput';
import { urlConstants } from '_constants';

const SetupForm = ({ urlToReload, applicationFeesHide }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [disabled, setDisabled] = useState(true);
    const [CGVAccepted, setCGVAccepted] = useState(false);
    const [paymentElementReady, setPaymentElementReady] = useState(false);
    
    async function onSubmit(event) {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Disable fields.
        loading(true);
        setDisabled(true);

        if (!CGVAccepted && !applicationFeesHide) {
            globalSwal({
                icon: appConstants.ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_CVG_REQUIRED,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            }).then(_ => {
                loading(false);
                setDisabled(false);
            });
            return
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: urlToReload || process.env.REACT_APP_URL,
            }
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (e.g., payment
            // details incomplete)
            globalSwal({
                icon: appConstants.ICON_ERROR,
                title: error.message,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
            setDisabled(false);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            loading(false);

            MixinSwal({
                icon: appConstants.ICON_SUCCESS,
                title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                text: appConstants.STRIPE_CARD_ADDED_SUCCESS,
            }).then(_ => {
                setDisabled(false);
            });
        }
    }

    async function onChecked(checked) {
        setCGVAccepted(checked);
    }
    return (
        <form className="d-flex flex-column justify-content-center m-auto" style={{ maxWidth: 600, minHeight: "100%" }} onSubmit={onSubmit} >

            <PaymentElement onReady={() => { setPaymentElementReady(true) }} />

            {!applicationFeesHide ?
                <CheckboxFormGroupInput className="my-4" label={<div>{appConstants.FORM_LABEL_CGV[0]}<a href={urlConstants.CGV_LINK} target="_blank">{appConstants.FORM_LABEL_CGV[1]}</a>{appConstants.FORM_LABEL_CGV[2]}</div>} onChange={onChecked} activeLabel={false} />
                : null}

            {paymentElementReady ?
                <ButtonAction
                    className="mt-5 mb-3 px-4 py-2 align-self-center"
                    onClick={onSubmit}
                    spinColor={colorsConstants.COLOR_WHITE}
                    style={{ fontSize: "15px" }}
                    submitted={disabled}
                    disabled={!stripe}
                >
                    Enregistrer ma carte
                </ButtonAction>
                :
                null
            }
        </form>
    );
};

export default SetupForm;