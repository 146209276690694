import FileModel from "./file.model";
import RequestModel from "./request.model";
import UserModel from "./user.model";

export default class LessonModel {
    /**
     * 
     * @param {string} id 
     * @param {string} date 
     * @param {RequestModel} request string with names of parent and student of the lesson (e.g: Mr.Dupond / J.Dupond)
     * @param {number} duration in hours
     * @param {*} price in €
     * @param {boolean} isPaidByCustomer 
     * @param {object} tutorGain 
     */
    constructor(id, date, request, duration, price, isPaidByCustomer, tutorGain = { home: undefined, online: undefined }, invoice = new FileModel(), isOnline, student = new UserModel(), tutor = new UserModel(), subjects, isBlocked) {
        this.id = id;
        this.date = date;
        this.request = request;
        this.duration = duration;
        this.price = price;
        this.isPaidByCustomer = isPaidByCustomer;
        this.tutorGain = tutorGain;
        this.invoice = invoice;
        this.isOnline = isOnline;
        this.student = student;
        this.tutor = tutor;
        this.subjects = subjects;
        this.isBlocked = isBlocked;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            this[key] = instance[key];
        })
        return this;
    }
}