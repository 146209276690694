import { Form } from "react-bootstrap";
import ButtonLoading from "../../Others/button/button";

import React, { useState } from "react";
import Swal from "sweetalert2";
import loading from "../../Others/process/loading";
import globalSwal from "../../Others/alert/globalSwal";
import { apiService } from "../../../_api/api.service";
import { apiConstants, appConstants } from "../../../_constants";

function PasswordReset({ onSuccess = () => {} }) {
    const [email, setEmail] = useState("");

    function onChange(event) {
        event.preventDefault();
        setEmail(event.target.value)
    }

    async function onSubmit(event) {
        event.preventDefault();
        if (email) {
            loading(true);
            await apiService.forgetPassword(email).then(response => {
                if (response === apiConstants.SUCCESS) {
                    globalSwal({
                        icon: appConstants.ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_MAIL_PASSWORD_SEND,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_MAIL_PASSWORD_SEND(email),
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(_ => {
                        onSuccess();
                    })
                } else {
                    globalSwal({
                        icon: appConstants.ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: response?.message ? response?.message : appConstants.DEFAULT_SWAL_MESSAGE_CHANGE_PASSWORD_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            })
        } else {
            Swal.fire({
                icon: appConstants.ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            })
        }
    }

    return (
        <Form className="d-flex flex-column justify-content-center m-auto h-100">
            <h1 className="connexion-title pt-5 pb-4 text-center">
                RÉNITIALISER LE MOT DE PASSE
            </h1>
            <Form.Group controlId="email" className="w-100 mx-auto" style={{maxWidth: 600}}>
                <Form.Label className="connexion-label-input">Email</Form.Label>
                <Form.Control
                    className="connexion-form-input"
                    type="email"
                    placeholder="prenom.nom@domaine.fr"
                    value={email}
                    onChange={onChange}
                    style={{ height: 40 + "px" }}
                ></Form.Control>
            </Form.Group>
            <div className="text-center">
                <ButtonLoading className="connexion-button rounded-border text-center mt-4 mb-4 px-4 py-2" onClick={onSubmit}>
                    ENVOYER
                </ButtonLoading>
            </div>
        </Form>
    );
}

export default PasswordReset;