export const urlConstants = {
  // CONNEXION PAGE
  SUBSCRIBE_COURSES: "https://www.tuteuralheure.fr/formulaire-cours-particuliers",
  BECOME_TUTOR: "https://www.tuteuralheure.fr/inscription-tuteur/",

  HELP_LINK: "https://rebrand.ly/besoin-aide",
  OTHER_SERVICE_LINK: "https://rebrand.ly/autres-services",
  SWYPE_LINK: "https://www.swype.fr/",
  INFO_LINK: "https://www.notion.so/Guides-Tuteurs-e3ef3831c1c54f6ebf72dca05f4bf5c1",
  SIRET_FORM_LINK: "https://airtable.com/shrYsQKzr8pjPNhYG",
  CGV_LINK: "https://www.eddmon.fr/cgv",

  CREATE_PASSWORD: "/create-password/:token",
  RECOVER_PASSWORD: "/recovery/:token",
  STRIPE_SETUP: "/stripe-setup",
  LOGIN: "/login",
  SETUP_CHECKING: "/setup-checking",
  AUTOLOGIN: "/autologin/:token",

  // CUSTOMER / PARENTS URLs
  CUSTOMER_DASHBOARD: "/parent-home/tableau-de-bord",
  CUSTOMER_PROFILE: "/parent-home/profil",
  CUSTOMER_SPONSORSHIP: "/parent-home/parrainer",
  CUSTOMER_LESSON_DETAILS: (id = ":id") => `/parent-home/details-support-course/${id}`,
  CUSTOMER_SOS_REQUEST_FORM: "/parent-home/request-form",
  CUSTOMER_REVIEW_FORM: "/lesson/:id",
  CUSTOMER_CARD_FORM: "/card-form",

  // TUTOR URLs
  TUTOR_HOME: "/tutor-home",
  TUTOR_DASHBOARD: "/tutor-home/tableau-de-bord/:tab?",
  TUTOR_DASHBOARD_RAW: "/tutor-home/tableau-de-bord",
  TUTOR_DASHBOARD_TAB_1: "/tutor-home/tableau-de-bord/1",
  TUTOR_DASHBOARD_TAB_2: "/tutor-home/tableau-de-bord/2",
  TUTOR_DASHBOARD_TAB_3: "/tutor-home/tableau-de-bord/3",
  TUTOR_DASHBOARD_TAB_4: "/tutor-home/tableau-de-bord/4",
  TUTOR_PROFILE: "/tutor-home/profil",
  TUTOR_MY_FINANCES: "/tutor-home/mes-finances",
  TUTOR_REQUEST_DETAILS: "/tutor-home/details-demande/:requestId",
  TUTOR_DECLARE_COURSE: "/tutor-home/declare-home-course",
  TUTOR_SPONSORSHIP: "/tutor-home/parrainer",
};
