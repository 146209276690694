export default class FormModel {
    constructor() {
        
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }

    completed() {
        return Object.keys(this).every(key => this[key] != undefined)
    }
}