import Swal from "sweetalert2";
import loading from "../process/loading";

export const MixinSwal = async ({...props}) => {
    const Toast = Swal.mixin({
        toast: props.toast || true,
        position: props.position || 'center',
        showConfirmButton: props.showConfirmButton || false,
        timer: props.timer || 1500,
        timerProgressBar: props.timerProgressBar || true,
    });

    loading(false);

    await Toast.fire({
        icon: props.icon,
        title: props.title || "",
        text: props.text || "",
    })
}