import { checkMissingData, flatAddress } from "../_helpers";
import OptionModel from "./option.model";

class AddressModel {
    constructor(id, number, street, city, postcode) {
        this.id = id ? id : null;
        this.number = number ? number : null;
        this.street = street ? street : null;
        this.city = city ? city : null;
        this.postcode = postcode ? postcode : null;
    }

    isCompleted(ignoreKey) {
        return checkMissingData(this, ignoreKey);
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach(key => {
            if (key === "zipcode") {       
            this["postcode"] = instance[key];
        }
            this[key] = instance[key];
        })
        return this;
    }

    toOptionModel() {
        return new OptionModel(this.id, this.stringify(), this);
    }

    /**
     * Stringify address.
     * @returns string address.
     */
    stringify() {
        return flatAddress(this);
    }

    /**
     * Remove all data of object.
     * @returns cleared object;
     */
    clear() {
        this.id = null;
        this.number = null;
        this.street = null;
        this.city = null;
        this.postcode = null;
        return this;
    }

    toObject() {
        return {
            id: this.id,
            number: this.number,
            street: this.street,
            city: this.city,
            postcode: this.postcode,
        }
    }

}

export default AddressModel;