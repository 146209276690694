import globalSwal from "../alert/globalSwal";

const { apiService } = require("_api/api.service");
const { appConstants } = require("_constants");

export default async function createStripeAccount(tutor, stripe) {
    const result = await apiService.createStripeConnectAccount(tutor, stripe);

    if (result?.error) {
        globalSwal({
            icon: appConstants.ICON_ERROR,
            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
            text: result.message,
        });
        //disable = false;
    } else if (result) {
        const link = await apiService.getStripeConnectAccountLink(tutor?.id);
        if (link) {
            window.location.href = link;
        }
        else { // ERROR
            globalSwal({
                icon: appConstants.ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
            });
            //disable = false;
        }
    } else { // ERROR
        globalSwal({
            icon: appConstants.ICON_ERROR,
            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
            text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
        });
        //disable = false;
    }
}