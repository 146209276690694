import {
    dateDispatch,
    extractAddress,
    hoursConvertor,
    phoneNumberConvert,
    updateTokens,
} from "../_helpers";
import axios from "./axios.service";

import ErrorModel from "../_models/error.model";
import PayoutsModel from "../_models/payouts.model";
import LessonModel from "../_models/lesson.model";
import RequestModel from "../_models/request.model";
import UserModel from "../_models/user.model";
import { StudentModel } from "../_models/student.model";
import AddressModel from "../_models/address.model";
import FileModel from "../_models/file.model";
import HomeworkModel from "../_models/homework.model";

import {
    apiConstants,
    userConstants,
    dbConstants,
    appConstants,
    requestConstants,
} from "../_constants";
import { XYChartModel } from "../_models/XYchart.model";
import TutorModel from "_models/tutor.model";
import SkillModel from "_models/skill.model";
import OptionModel from "_models/option.model";
import AdvisorModel from "_models/advisor.model";
import LadderModel from "_models/ladder.model";
import PaginationModel from "_models/pagination.model";
import SchoolModel from "_models/school.model";

export const apiService = {
    getTutorById,
    getTutorFile,
    getPayoutsByTutorId,
    updateTutorFile,
    getCustomerByUserId,
    getCustomerIdByUserId,
    getGrades,
    getApiSearchingStudents,
    getFileDownloadURL,
    getLessonById,
    getRequestById,
    applyToRequest,
    dislikeRequest,
    deleteApplicationById,
    getApplicationByRequestAndTutor,
    createFile,
    updateFile,
    getLessonsWithFilter,
    postLesson,
    getStripeCreateSetupIntent,
    createPassword,
    forgetPassword,
    changePassword,
    getStripeCardByCustomerId,
    patchCustomerById,
    createStripeConnectAccount,
    getStripeConnectAccountLink,
    refreshStripeConnectAccountLink,
    refreshStripeConnectAccount,
    getStripeConnectAccountIdByTutorId,
    getInvoicesDownloadLink,
    deleteStripeCardByCustomerId,
    getStripeAccountStatus,
    getStripeConnectCreateSetupIntent,
    createStripeBankAccount,
    getStripeBankAccount,
    getStripeBalance,
    getCustomerHasPaymentMethodByUserId,
    getAmountToBePaid,
    postAddress,
    deleteAddressById,
    patchTutorById,
    getRequestsCustomersLastname,
    getRequestsToReport,
    getHomeworksByRequestId,
    updateHomework,
    deleteHomework,
    getTutorRevenues,
    getTutorsLessons,
    postSwypeSurvey,
    getCustomersTutors,
    getCertificateYears,
    downloadCertificates,
    getSkills,
    getTransports,
    getTutorTransports,
    getTutorCharacteristics,
    getTutorLevels,
    autoLogin,
    patchReview,
    lessonOpposition,
    getCharacteristics,
    patchTutorSkills,
    getAdvisor,
    postRequest,
    downloadCustomerReport,
    getCustomerStudents,
    getTutorStudents,
    getLadders,
    setTutorNotifications,
    getTutorNotifications,
    getCustomerAddresses,
    getTutorLadder,
    getTutorsSkills,
    getSchools,
    getSchoolWithCallback,
    getApplicationFees,
    getTutorSchool,
    getStripeVerified,
    isPasswordAlreadyAdded,
    getTutorStepGuide,
    patchTutorStepGuide,
    patchCustomerStepGuide,
    getCustomerStepGuide,
    patchTutorAcceptedConditions,
    getTutorStatsMajored,
    getTutorPrices
};

/**
 * Get grades.
 * @returns list of grades : [{value: string, title: string}] if succeed, otherwise returns undefined.
 */
async function getGrades(addAllOpt = true) {
    return await axios
        .get(`${apiConstants.API_GRADES}?properties[]=id&properties[]=title`)
        .then((response) => {
            let levelList = addAllOpt ? [new OptionModel().buildFromInstance({ value: appConstants.FILTER_VALUE_ALL, label: "Tout" })] : [];
            response.data["hydra:member"].forEach((element) => {
                levelList.push(new OptionModel(element.title, element.title, element.id));
            });
            return levelList;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
        });
}

/**
 * Get skills according to parameter passed.
 * @param {bool} isASubject
 * @param {bool} isAHobby
 * @returns skills object if succeed, otherwise returns ERROR.
 */
async function getSkills(isASubject, isAHobby, pagination = true) {
    return await axios
        .get(`${apiConstants.API_SKILLS}?${isASubject ? `isASubject=${isASubject}&` : ""}${isAHobby ? `isAHobby=${isAHobby}&` : ""}properties[]=id&properties[]=title&pagination=${pagination}`)
        .then((resp) => {
            let skillsList = [];

            for (var j = 0; j < resp.data["hydra:member"].length; j++) {
                skillsList.push(new OptionModel(resp.data["hydra:member"][j].id, resp.data["hydra:member"][j].title));
            }
            return skillsList;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get students looking for a course according to filter provided.
 * @param {number} tutorId
 * @param {array} filterLevel
 * @param {array} filterSubject
 * @param page
 * @returns an array of students looking for a course if succeed, otherwise returns ERROR.
 */
async function getApiSearchingStudents(tutorId, filterLevel, filterSubject, page = 1) {
    return axios
        .get(
            `${apiConstants.API_REQUESTS}?page=${page}&state=enabled&exists[selectedTutor]=false&exists[tutor]=false&orderApplication&properties[]=id&properties[]=beginAt&properties[address][]=zipcode&properties[onlinePrice][]=id&properties[price][]=id&properties[student][grade][]=title&properties[student][]=firstname&properties[student][]=lastname&properties[requestSkills][skill][]=title&properties[requestSkills][skill][]=isASubject&properties[dislikes][tutor][]=id&properties[applications][]=status&properties[]=sos`
        )
        .then((response) => {
            const searchingStudent = [];
            let subject;
            for (var i = 0; i < response.data["hydra:member"]?.length; i++) {
                let subjectList = [];
                let subjectFilterRes = false;

                // Looking for subjects of the request. If any found, request won't be return.
                for (var j = 0; j < response.data["hydra:member"][i]?.requestSkills?.length; j++) {
                    if (response.data["hydra:member"][i]?.requestSkills[j]?.skill?.isASubject) {
                        subject = response.data["hydra:member"][i]?.requestSkills[j]?.skill?.title;
                        subjectList.push(subject);
                        subjectFilterRes = !subjectFilterRes ? filterSubject?.includes(subject) : true;
                    }
                }

                // Apply filters on data here
                if ((filterLevel ? filterLevel.includes(response.data["hydra:member"][i]?.student?.grade?.title) : true) && (typeof subjectFilterRes !== "undefined" ? subjectFilterRes : true)) {
                    // Check if student searching request has been already dislikes by the tutor.
                    if (!tutorId || response.data["hydra:member"][i]?.dislikes?.find((elt) => elt?.tutor?.id === tutorId)) {
                        continue;
                    } else {
                        searchingStudent.push({
                            id: response.data["hydra:member"][i]?.id,
                            date: dateDispatch(response.data["hydra:member"][i]?.beginAt),
                            student: {
                                firstname: response.data["hydra:member"][i]?.student?.firstname,
                                lastname: response.data["hydra:member"][i]?.student?.lastname,
                            },
                            level: response.data["hydra:member"][i]?.student?.grade?.title,
                            subject: subjectList,
                            dislikes: response.data["hydra:member"][i]?.dislikes,
                            candidatesCount: response.data["hydra:member"][i]?.applications?.filter((application) =>
                                application.status !== dbConstants.APPLICATION_TYPE_REJECTED_DB
                            )?.length,
                            location: response.data["hydra:member"][i]?.onlinePrice
                                ? response.data["hydra:member"][i]?.price
                                    ? requestConstants.LOCATION.HOME_ONLINE(
                                        response.data["hydra:member"][i]?.address?.zipcode
                                    )
                                    : requestConstants.LOCATION.ONLINE
                                : response.data["hydra:member"][i]?.price
                                    ? requestConstants.LOCATION.HOME(
                                        response.data["hydra:member"][i]?.address?.zipcode
                                    )
                                    : requestConstants.LOCATION.UNKNOWN,
                            sos: response.data["hydra:member"][i]?.sos,
                        });
                    }
                }
            }
            // Used to sort searchingStudent according to candidatesCount.
            /* searchingStudent.sort((a, b) =>
                a?.candidatesCount > b?.candidatesCount ? 1 : -1
            ); */
            return new PaginationModel().buildFromInstance({ values: searchingStudent, page: page, pageTotal: Math.ceil(response.data["hydra:totalItems"] / 30) });
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get URL of profile picture.
 * @param {string} downloadUrl Url to download picture.
 * @returns URL if link is available, otherwise returns undefined.
 */
async function getFileDownloadURL(downloadUrl) {
    let imgURL;

    if (!downloadUrl) return;

    await axios
        .get(downloadUrl)
        .then((response) => {
            // Success
            imgURL = response.data?.url;
        })
        .catch((error) => {
            // handle error : use default picture (set to false).
            console.error(error);
        });
    return imgURL;
}

async function getCustomersTutors(customerId) {
    return await axios
        .get(
            `${apiConstants.API_LESSONS}?customer=${customerId}&groupByTutor&properties[tutor]`
        )
        .then(async (response) => {
            const tutors = response.data["hydra:member"].map((elt) =>
                new TutorModel().buildFromInstance({
                    id: elt.tutor.id,
                    firstname: elt.tutor.user?.firstname,
                    lastname: elt.tutor.user?.lastname,
                    birthdate: new Date(elt.tutor.birthdate),
                    school: elt.tutor.school,
                    email: elt.tutor.user?.email,
                    phone: elt.tutor.phone,
                    files: [
                        new FileModel(
                            apiConstants.FILES.AVATAR.defaultFileName,
                            new Promise((resolve, _reject) =>
                                getTutorFile(elt.tutor.id, apiConstants.FILES.AVATAR.fileType).then((url) => {
                                    resolve(url);
                                })
                            ),
                            apiConstants.FILES.AVATAR.fileType,
                            elt.tutor.id
                        ),
                    ],
                    subjects: elt.tutor.tutorSkills?.filter(tutorSkill => tutorSkill.skill.isASubject).map(tutorSkill => new SkillModel().buildFromInstance(tutorSkill.skill)),
                    hobbies: elt.tutor.tutorSkills?.filter(tutorSkill => tutorSkill.skill.isAHobby).map(tutorSkill => new SkillModel().buildFromInstance(tutorSkill.skill)),
                })
            );

            for (const elt of tutors) {
                elt.files.find(elt => elt.fileType === apiConstants.FILES.AVATAR.fileType).downloadUrl.then(url => {
                    elt.files.find(elt => elt.fileType === apiConstants.FILES.AVATAR.fileType).downloadUrl = url
                });
            }

            return tutors;
        })
        .catch((error) => {
            // handle error : use default picture (set to false).
            console.error(error);
        });
}

/**
 * Get lessons from a customer (according to customerId) with filter on lesson's format ("home" or "online").
 * @param {number} customerId
 * @param {object} formatFilter {value: string, label: string}
 * @returns List of lessons if succeed, otherwise returns undefined.
 */
async function getLessonsWithFilter(customerId, formatFilter) {
    let isOnline;

    if (formatFilter?.includes(appConstants.FILTER_VALUE_ALL)) {
        isOnline = null;
    } else if (formatFilter?.includes(appConstants.FILTER_VALUE_ONLINE)) {
        isOnline = 1;
    } else if (formatFilter?.includes(appConstants.FILTER_VALUE_HOME)) {
        isOnline = 0;
    } else return [];

    return await axios
        .get(
            `${apiConstants.API_LESSONS}?customer=${customerId}${isOnline !== null ? `&isOnline=${isOnline}` : ""
            }&properties[]=isOnline&properties[]=id&properties[]=start&properties[]=duration&properties[request][student][]=firstname&properties[request][student][]=lastname&properties[tutor][user][]=firstname&properties[tutor][user][]=lastname&properties[]=isPaidByCustomer`
        )
        .then((response) => {
            return response.data["hydra:member"]?.map(
                (lesson) =>
                    new RequestModel(
                        null,
                        new UserModel().buildFromInstance(lesson?.request?.student),
                        null,
                        new UserModel().buildFromInstance(lesson?.tutor?.user),
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        [
                            new LessonModel().buildFromInstance({
                                ...lesson,
                                date: lesson.start,
                            }),
                        ]
                    )
            );
        })
        .catch((error) => {
            // handle error : use default picture (set to false).
            console.error(error);
            return;
        });
}

/**
 * Post new lesson.
 * @param {object} form
 * @returns lesson created if succeed, otherwise return ERROR.
 */
async function postLesson(form) {
    return await axios
        .post(
            `${apiConstants.API_LESSONS}?properties[]=isOnline&properties[request][]=id&properties[]=price&properties[]=start&properties[]=duration&properties[]=comment&properties[]=summary&properties[]=motivationMark&properties[]=understandingMark&properties[]=progressMark&properties[tutor][]=id&properties[customer][]=id&properties[skills][]=id&properties[homeworks][]=title&properties[homeworks][]=description&properties[homeworks][]=deadline&properties[homeworks][]=isCompleted&properties[homeworks][skills][]=id`,
            JSON.stringify(form),
            { headers: { "Content-Type": "application/ld+json" } }
        )
        .then((response) => {
            return response.data;
        });
}

/**
 * Get a lesson from API according to lesson's ID provided.
 * @param {number} lessonId Lesson's ID to get.
 * @returns Lessons object if succeed, otherwise return undefined.
 */
async function getLessonById(lessonId) {
    return await axios
        .get(
            `${apiConstants.API_LESSONS}/${lessonId}?properties[]=isBlocked&properties[request][student][]=firstname&properties[request][student][]=lastname&properties[]=price&properties[]=start&properties[]=duration&properties[skills][]=title&properties[tutor][user][]=firstname&properties[tutor][user][]=lastname&properties[invoice][]=invoiceCustomer`
        )
        .then(async (response) => {
            const invoiceCustomerUrl = response.data.invoice?.invoiceCustomer
                ? await getInvoicesDownloadLink(response.data.invoice?.invoiceCustomer)
                : appConstants.ERROR;

            let subjects = response.data.skills?.map((skill) => skill.title);

            return new LessonModel().buildFromInstance({
                id: lessonId,
                student: new UserModel().buildFromInstance({
                    firstname: response.data.request?.student?.firstname,
                    lastname: response.data.request?.student?.lastname,
                }),
                tutor: new UserModel().buildFromInstance({
                    firstname: response.data.tutor?.user?.firstname,
                    lastname: response.data.tutor?.user?.lastname,
                }),
                subjects: subjects,
                duration: response.data.duration,
                price: response.data.price,
                date: dateDispatch(response.data.start),
                invoice: new FileModel(
                    invoiceCustomerUrl
                        ? `${appConstants.PREFIX_BILLS_TITLE}${response.data.invoice?.invoiceCustomer}`
                        : appConstants.DEFAULT_NO_FILE,
                    invoiceCustomerUrl
                ),
                isBlocked: response.data.isBlocked,
            })
        })
        .catch(function (error) {
            // handle error
            console.error(error);
        });
}

/**
 * Get request from API by request's ID.
 * @param {number} requestId ID of request to get.
 * @returns Request object if succeed, otherwise return undefined.
 */
async function getRequestById(requestId) {
    return await axios
        .get(
            `${apiConstants.API_REQUESTS}/${requestId}?properties[onlinePrice][]=tutorGain&properties[tutor][]=id&properties[]=selectedTutorGain&properties[]=beginAt&properties[]=endAt&properties[]=notes&properties[]=state&properties[dislikes][tutor][]=id&properties[student][grade][]=title&properties[student][]=firstname&properties[student][]=lastname&properties[requestSkills][skill][]=title&properties[requestSkills][skill][]=isASubject&properties[]=availability&properties[]=lessonsPerWeek&properties[]=lessonsDuration&properties[price][]=tutorGain&properties[address][]=number&properties[address][]=street&properties[address][]=city&properties[address][]=zipcode&properties[student][skills][]=title&properties[student][skills][]=isAHobby&properties[applications][]=id&properties[applications][tutor][]=id&properties[student][skills][]=isASubject`
        )
        .then((response) => {
            let subjects = [];

            for (var i = 0; i < response.data.requestSkills.length; i++) {
                if (response.data.requestSkills[i].skill?.isASubject) {
                    subjects.push(response.data.requestSkills[i].skill?.title);
                }
            }

            return new RequestModel(
                requestId,
                new StudentModel(
                ).buildFromInstance({
                    firstname: response.data.student?.firstname,
                    lastname: response.data.student?.lastname,
                    level: response.data.student.grade?.title,
                    passions: response.data.student?.skills?.filter((skill) => skill?.isAHobby).map(elt => new OptionModel(null, elt.title)),
                    options: response.data.student?.skills?.filter((skill) => skill?.isASubject).map(elt => new OptionModel(null, elt.title))
                }),
                null,
                new UserModel(response.data.tutor?.id, null, null),
                subjects?.map(elt => new OptionModel(null, elt)),
                dateDispatch(response.data.beginAt),
                dateDispatch(response.data.endAt),
                response.data.availability,
                new AddressModel(
                    null,
                    response.data.address?.number,
                    response.data.address?.street,
                    response.data.address?.city,
                    response.data.address?.zipcode
                ),
                response.data.lessonsPerWeek,
                new LessonModel(
                    null,
                    null,
                    null,
                    hoursConvertor(response.data.lessonsDuration),
                    null,
                    false,
                    {
                        home: response.data.price?.tutorGain,
                        online: response.data.onlinePrice?.tutorGain,
                    }
                ),
                response.data.notes,
                response.data.state,
                response.data.applications,
                response.data.dislikes,
                null,
                null,
                [],
                response.data?.selectedTutorGain
            );
        })
        .catch(function (error) {
            // handle error
            console.error(error);
        });
}

async function getRequestsCustomersLastname(requestId) {
    return await axios
        .get(
            `${apiConstants.API_REQUESTS}/${requestId}?properties[customer][user][]=lastname`
        )
        .then((response) => {
            return response.data.customer.user?.lastname;
        })
        .catch(function (err) {
            console.error(err);
            return apiConstants.ERROR;
        });
}

/**
 * Apply to a request.
 * @param {number} tutorId
 * @param {number} requestId
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function applyToRequest(tutorId, requestId) {
    const form = {
        tutor: `${apiConstants.API_TUTORS}/${tutorId}`,
        request: `${apiConstants.API_REQUESTS}/${requestId}`,
    };
    return await axios
        .post(apiConstants.API_APPLICATIONS, JSON.stringify(form), {
            headers: { "Content-Type": "application/ld+json" },
        })
        .then((_) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (err) {
            console.error(err);
            return apiConstants.ERROR;
        });
}

/**
 * Dislike a request. If an application is already submitted, delete it into DB.
 * @param {number} tutorId
 * @param {number} requestId
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function dislikeRequest(tutorId, requestId) {
    const form = {
        tutor: `${apiConstants.API_TUTORS}/${tutorId}`,
        request: `${apiConstants.API_REQUESTS}/${requestId}`,
    };
    return await axios
        .post(apiConstants.API_DISLIKES, JSON.stringify(form), {
            headers: { "Content-Type": "application/ld+json" },
        })
        .then(async (_) => {
            // Looking for application submitted by the tutor. If an application is found, delete it.
            const application = await getApplicationByRequestAndTutor(
                tutorId,
                requestId
            );

            if (application) {
                return await deleteApplicationById(application.id)
                    .then((_) => {
                        return apiConstants.SUCCESS;
                    })
                    .catch(function (err) {
                        console.error(err);
                        return apiConstants.ERROR;
                    });
            }
            return apiConstants.SUCCESS;
        })
        .catch(function (err) {
            console.error(err);
            return apiConstants.ERROR;
        });
}

/**
 * Delete application by ID.
 * @param {number} applicationId
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function deleteApplicationById(applicationId) {
    return await axios
        .delete(`${apiConstants.API_APPLICATIONS}/${applicationId}`)
        .then((_) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (err) {
            console.error(err);
            return apiConstants.ERROR;
        });
}

/**
 * Get application according to requestID and tutorID.
 * @param {number} tutorId
 * @param {number} requestId
 * @returns Application object if succeed, otherwise returns undefined.
 */
async function getApplicationByRequestAndTutor(tutorId, requestId) {
    return await axios
        .get(
            `${apiConstants.API_APPLICATIONS}?tutor=${tutorId}&request=${requestId}&properties[]=id`
        )
        .then((response) => {
            // Returns first element (should have only one element).
            return response.data["hydra:member"][0];
        })
        .catch(function (err) {
            console.error(err);
            return undefined;
        });
}

/**
 * Create a file in database.
 * @param {FormData} file
 * @returns URL direct download of file created if succeed, otherwise returns ERROR.
 */
async function createFile(file) {
    return await axios
        .post(apiConstants.API_CREATE_FILE, file)
        .then(async (response) => {
            return await getFileDownloadURL(response.data.downloadUrl);
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Update a file in database.
 * @param {number} fileId
 * @param {FormData} file
 * @returns URL direct download of file updated if succeed, otherwise returns ERROR.
 */
async function updateFile(fileId, file) {
    return await axios
        .post(`${apiConstants.API_UPDATE_FILE}/${fileId}`, file)
        .then(async (response) => {
            // Success : get file's direct download URL and return it.
            return await getFileDownloadURL(response.data.downloadUrl);
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get customer ID according to user ID provided.
 * @param {number} userId
 * @returns Customer ID if succeed, otherwise returns ERROR.
 */
async function getCustomerIdByUserId(userId) {
    return await axios
        .get(`/api/users/${userId}?properties[customer][]=id`)
        .then((response) => {
            return response.data.customer.id;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Create new password for user.
 * @param {object} form
 * @returns response object if succeed, otherwise returns ERROR.
 */
async function createPassword(form) {
    return await axios
        .post(apiConstants.USER_CREATE_PASSWORD, form)
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            const message =
                error?.response?.data?.code === apiConstants.ERROR_CODE_INCORRECT_TOKEN
                    ? appConstants.INCORRECT_TOKEN_MESSAGE_ERROR
                    : error?.response?.data?.message ||
                    appConstants.DEFAULT_SWAL_MESSAGE_ERROR;
            return new ErrorModel(error?.response?.status, message);
        });
}

/**
 * Change password.
 * @param {object} formData
 * @returns SUCCESS if succeed, otherwise returns error message.
 */
async function changePassword(formData) {
    return await axios
        .post(apiConstants.USER_CHANGE_PASSWORD, formData)
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.response.data;
        });
}

async function forgetPassword(email) {
    const form = {
        email: email,
    };

    return await axios
        .post(apiConstants.USER_FORGET_PASSWORD, form)
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error?.response?.data ? error?.response?.data : apiConstants.ERROR;
        });
}

/**
 * Get Stripe's token basic account.
 * @param {number} customerId
 * @returns Stripe's token if succeed, otherwise returns ERROR.
 */
async function getStripeCreateSetupIntent(customerId) {
    const form = {
        customer: `${customerId}`,
    };
    return await axios
        .post(apiConstants.API_CREATE_SETUP_INTENT, form)
        .then((response) => {
            return response.data.message;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get Stripe's token Connect account.
 * @param {number} customerId
 * @returns Stripe's token if succeed, otherwise returns ERROR.
 */
async function getStripeConnectCreateSetupIntent(tutorId) {
    const form = {
        tutor: `${tutorId}`,
    };
    return await axios
        .post(apiConstants.API_CREATE_SETUP_INTENT_CONNECT, form)
        .then((response) => {
            return response.data.message;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get customer's card saved on Stripe, according to customer ID provided.
 * @param {number} customerId
 * @returns Customer's card if succeed, otherwise returns ERROR.
 */
async function getStripeCardByCustomerId(customerId) {
    if (!customerId) {
        return apiConstants.ERROR;
    }
    return await axios
        .get(`${apiConstants.API_STRIPE_CUSTOMER}/${customerId}/card`)
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get customer's hasPaymentMethod, according to user ID provided.
 * @param {number} customerId
 * @returns a boolean if success, otherwise returns undefined.
 */
async function getStripeAccountStatus(tutorId) {
    return await axios
        .get(
            `${apiConstants.API_TUTORS}/${tutorId}?properties[]=canReceivePaymentOnStripe&properties[]=canPayoutOnStripe`
        )
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return;
        });
}

/**
 * Get customer's hasPaymentMethod, according to user ID provided.
 * @param {number} customerId
 * @returns a boolean if success, otherwise returns undefined.
 */
async function getCustomerHasPaymentMethodByUserId(userId) {
    return await axios
        .get(
            `${apiConstants.API_USERS}/${userId}?properties[customer][]=hasPaymentMethod`
        )
        .then((response) => {
            return response.data.customer.hasPaymentMethod;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return;
        });
}

/**
 * Patch customer, according to customer ID provided.
 * @param {number} customerId
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function patchCustomerById(customerId, form) {
    return await axios
        .patch(`${apiConstants.API_CUSTOMERS}/${customerId}`, form, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
        .then((_) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Create Stripe connect account.
 * @param {number} tutor Tutor's object
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function createStripeConnectAccount(tutor, stripe) {
    return await stripe
        .createToken("account", {
            individual: {
                first_name: tutor?.firstname,
                last_name: tutor?.lastname,
                email: tutor?.email,
                address: {
                    city: tutor?.address?.city,
                    country: "FR",
                    line1: tutor?.address?.address,
                    postal_code: tutor?.address?.zipcode,
                },
                dob: {
                    day: tutor?.birthdate?.getDate(),
                    month: tutor?.birthdate?.getMonth() + 1,
                    year: tutor?.birthdate?.getYear() + 1900, // getYear() return year - 1900.
                },
                phone: tutor?.phone,
            },
            business_type: "individual",
            tos_shown_and_accepted: true,
        })
        .then(async function (result) {
            // Handle result.error or result.token
            if (result?.error) {
                return { error: true, message: result.error?.message };
            } else if (result.token) {
                const form = {
                    token: result.token.id,
                    tutor: `${tutor.id}`,
                };
                return await axios
                    .post(apiConstants.API_CREATE_STRIPE_CONNECT_ACCOUNT, form)
                    .then((_) => {
                        return apiConstants.SUCCESS;
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                        return { error: true, message: result.message };
                    });
            } else {
                return { error: true };
            }
        });
}

/**
 * Get Stripe connect account's link.
 * @param {number} tutorId
 * @returns Link if succeed, otherwise returns ERROR.
 */
async function getStripeConnectAccountLink(tutorId) {
    const form = {
        tutor: `${tutorId}`,
    };
    return await axios
        .post(apiConstants.API_CREATE_STRIPE_ACCOUNT_LINK, form)
        .then((response) => {
            return response.data.url;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Add IBAN to a Stripe's connect account.
 * @param {number} tutorId
 * @returns Success response if succeed, otherwise returns ERROR.
 */
async function createStripeBankAccount(tutorId, fullname, iban) {
    const form = {
        tutor: tutorId,
        holder_name: fullname,
        account_number: iban,
    };
    return await axios
        .post(apiConstants.API_CREATE_STRIPE_BANK_ACCOUNT, form)
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get IBAN from a Stripe's connect account.
 * @param {number} tutorId
 * @returns IBAN object if succeed, otherwise returns status code error.
 */
async function getStripeBankAccount(tutorId) {
    return await axios
        .get(`${apiConstants.API_STRIPE_TUTOR}/${tutorId}/bank-account`)
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return { status: error?.response?.status, error: true };
        });
}

/**
 * Get Stripe balance according to tutor ID provided.
 * @param {number} tutorId
 * @returns Balance of account if succeed, otherwise returns ERROR.
 */
async function getStripeBalance(tutorId) {
    if (!tutorId) {
        return apiConstants.ERROR;
    }
    return await axios
        .get(`${apiConstants.API_STRIPE_TUTOR}/${tutorId}/balance`)
        .then((response) => {
            return response.data.available;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * URL to redirect user on Stripe verification process (on previous the stage of the process).
 * @param {number} tutorId
 * @returns Link if succeed, otherwise returns ERROR.
 */
async function refreshStripeConnectAccountLink(tutorId, stripeId) {
    return `${process.env.REACT_APP_API_URL}${apiConstants.API_REFRESH_STRIPE_ACCOUNT_LINK}/${tutorId}/${stripeId}`;
}

/**
 * Refresh Stripe's connect account.
 */
async function refreshStripeConnectAccount(tutor, form, stripe) {
    return await stripe
        .createToken("account", {
            individual: {
                address: {
                    city: tutor?.address?.city,
                    line1: tutor?.address?.number + ' ' + tutor?.address?.street,
                    postal_code: tutor?.address?.postcode
                },
                phone:  process.env?.REACT_APP_ENV === "dev" ? "0000000000" : (tutor?.phone?.startsWith("+") ? tutor?.phone : "+" + tutor?.phone),
            }
        })
        .then(function (result) {
            // Handle result.error or result.token
            if (result?.error) {
                return { error: true, message: result.error?.message };
            } else if (result.token) {
                form.token = result.token.id;
                return {error: false};
            } else {
                return { error: true };
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return { status: error?.response?.status, error: true };
        });
}

/**
 * Get Stripe Connect account ID according to tutor ID provided.
 * @param {number} id Tutor id.
 * @returns Stripe account ID if succeed, otherwise returns ERROR.
 */
async function getStripeConnectAccountIdByTutorId(id) {
    return await axios
        .get(`${apiConstants.API_TUTORS}/${id}?properties[]=stripeConnectAccountId`)
        .then((response) => {
            return response.data.stripeConnectAccountId;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Delete customer's card saved on Stripe, according to customer ID provided.
 * @param {number} customerId
 * @returns Customer's card if succeed, otherwise returns ERROR.
 */
async function deleteStripeCardByCustomerId(customerId) {
    return await axios
        .delete(
            `${apiConstants.API_STRIPE_CUSTOMER}/${customerId}/payment-method/delete`
        )
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get download link of a invoice (bill).
 * @param {string} invoice
 * @returns URL download link if succeed, otherwise returns ERROR.
 */
async function getInvoicesDownloadLink(invoice) {
    return await axios
        .get(`${apiConstants.API_INVOICES}/download/${invoice}`)
        .then((response) => {
            return response.data.url;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get tutor's file according to fileType provided.
 * @param {number} tutorId
 * @param {string} fileType
 * @returns file's URL.
 */
async function getTutorFile(tutorId, fileType) {
    return await axios
        .get(`${apiConstants.API_TUTORS}/${tutorId}/file/${fileType}/download`)
        .then((response) => {
            return response.data.url;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getPayoutsByTutorId() {
    return await axios
        .get(apiConstants.API_PAYOUTS)
        .then((response) => {
            let payouts = {
                done: [],
                inProgress: [],
            };

            for (let i = 0; i < response.data?.length; i++) {
                let payout = new PayoutsModel();

                const lessons = response.data[i].lessons?.map((lesson) => {
                    return new LessonModel().buildFromInstance({
                        id: null,
                        date: lesson.start,
                        request: new RequestModel().buildFromInstance({
                            id: null,
                            student: new UserModel(
                                null,
                                lesson?.student_firstname,
                                lesson?.student_lastname
                            ),
                            customer: new UserModel(
                                null,
                                lesson?.customer_firstname,
                                lesson?.customer_lastname
                            ),
                        }),
                        duration: lesson.duration,
                        tutorGain: {
                            home: lesson.amount,
                            online: lesson.amount
                        }
                    })
                });
                switch (true) {
                    case response.data[i]?.status >= appConstants.TRANSFER_TYPE_DONE:
                        payout.buildFromInstance(response.data[i]);
                        payout.lessons = lessons;
                        payouts.done.push(payout);
                        break;
                    case response.data[i]?.status >= appConstants.TRANSFER_TYPE_IN_PROGRESS:
                        payout.buildFromInstance(response.data[i]);
                        payout.lessons = lessons;
                        payouts.inProgress.push(payout);
                        break;
                    default:
                        break;
                }
            }
            return payouts;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Update tutor's file according to fileType provided.
 * @param {number} tutorId
 * @param {string} fileType
 * @param {ArrayBuffer} binaryFile
 * @returns success response if succeed, otherwise returns ERROR.
 */
async function updateTutorFile(tutorId, fileType, documentFile) {
    return await axios
        .post(
            `${apiConstants.API_TUTORS}/${tutorId}/file/${fileType}`,
            documentFile
        )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Get requests to report according tutor id.
 * @param {number} tutorId
 * @returns success response if succeed, otherwise returns ERROR.
 */
async function getRequestsToReport(tutorId = "") {
    return await axios
        .get(
            `${apiConstants.API_TUTORS}/${tutorId}?properties[requests][]=id&properties[requests][]=state&properties[requests][]=homeworkRequired&properties[requests][price][]=id&properties[requests][onlinePrice][]=id&properties[requests][student][]=firstname&properties[requests][student][]=lastname&properties[requests][customer][]=id&properties[requests][customer][]=gender&properties[requests][customer]=lastname`
        )
        .then((response) => {
            const result = [];
            for (let i = 0; i < response.data.requests?.length; i++) {
                const elt = response.data.requests[i];
                let type = requestConstants.TYPE.HOME; // It means 0;

                if (elt.state === requestConstants.ENABLED || elt.state === requestConstants.FINISHED) {
                    if (elt.onlinePrice) {
                        type++;
                    }
                    if (elt.price && elt.onlinePrice) {
                        type++;
                    }
                    result.push(
                        new RequestModel().buildFromInstance({
                            id: elt.id,
                            student: new UserModel(
                                null,
                                elt.student?.firstname,
                                elt.student?.lastname,
                                elt.customer?.gender
                            ),
                            customer: new UserModel(
                                elt.customer?.id,
                                null,
                                elt?.customer?.lastname,
                                elt.customer?.gender
                            ),
                            type: type,
                            homeworkRequired: elt?.homeworkRequired,
                        })
                    );
                }
            }
            return result;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getTutorsLessons(page = 1) {
    const itemsPerPage = 30;
    let pagination = `page=${page}&itemsPerPage=${itemsPerPage}`
    switch (page) {
        case apiConstants.PENDING_LESSONS:
            pagination = "pagination=false&isPaidByCustomer=0"
            break;

        case apiConstants.LESSONS_INVOICES:
            pagination = "pagination=false&exists[invoice][]=true" // DOES NOT WORK
            break;

        default:
            break;
    }
    return await axios
        .get(
            `${apiConstants.API_LESSONS}?${pagination}&properties[invoice][]=invoiceCustomer&properties[]=start&properties[]=isPaidByCustomer&properties[]=isOnline&properties[]=duration&properties[]=tutorGain&properties[request][student][]=firstname&properties[request][student][]=lastname&properties[request][customer][user][]=lastname&properties[request][customer][user][]=firstname`
        )
        .then((response) => {
            return new PaginationModel().buildFromInstance({
                page: page,
                pageTotal: Math.ceil(response.data["hydra:totalItems"] / itemsPerPage),
                values: response.data["hydra:member"].map(
                    (elt) =>
                        new LessonModel().buildFromInstance({
                            date: dateDispatch(elt.start),
                            request: new RequestModel().buildFromInstance({
                                student: new UserModel(
                                    null,
                                    elt.request?.student?.firstname,
                                    elt.request?.student?.lastname
                                ),
                                customer: new UserModel(
                                    null,
                                    elt.request?.customer?.user?.firstname,
                                    elt.request?.customer?.user?.lastname
                                ),
                            }),
                            duration: hoursConvertor(elt.duration),
                            tutorGain: {
                                home: elt.tutorGain,
                                online: elt.tutorGain
                            },
                            isPaidByCustomer: elt.isPaidByCustomer,
                            invoice: elt.invoice?.invoiceCustomer,
                        })
                )
            })
        }
        )
        .catch(function (error) {
            console.error(error);
        });
}

/**
 * Get the whole object Tutor.
 * @param {number} id
 * @returns Object with all the data of tutor, otherwise returns undefined.
 */
async function getTutorById(id) {
    return await axios
        .get(
            `${apiConstants.API_USERS}/${id}?properties[]=firstname&properties[]=lastname&properties[]=disabled&properties[]=email&properties[]=role&properties[]=id&properties[tutor][]=id&properties[tutor][]=sponsorCode&properties[tutor][]=address&properties[tutor][]=city&properties[tutor][]=zipcode&properties[tutor][]=siret&properties[tutor][]=gender&properties[tutor][]=birthdate&properties[tutor][]=phone&properties[tutor][]=hasAcceptedConditions&properties[tutor][nationalities][]=name&properties[tutor][]=number&properties[tutor][tutorSkills][]=id&properties[tutor][tutorSkills][]=level&properties[tutor][tutorSkills][skill][]=id&properties[tutor][tutorSkills][skill][]=title&properties[tutor][tutorSkills][skill][]=isASubject&properties[tutor][tutorSkills][skill][]=isAHobby&properties[tutor][files][]=id&properties[tutor][files][]=downloadUrl&properties[tutor][files][filesTypes][]=id&properties[tutor][files][filesTypes][]=title&properties[tutor][files][]=isValidated&properties[nationalities][]=id&properties[tutor][]=notificationEnabled`
        )
        .then(async (response) => {
            const tutorId = response.data?.tutor?.id;
            let subjectArray = [];
            let passionArray = [];

            for (var j = 0; j < response?.data?.tutor?.tutorSkills?.length; j++) {
                if (response?.data?.tutor?.tutorSkills[j]?.skill?.isASubject) {
                    // Subjects
                    subjectArray.push({
                        id: response.data.tutor?.tutorSkills[j].skill?.id,
                        title: response.data.tutor?.tutorSkills[j].skill?.title,
                        level: response.data.tutor?.tutorSkills[j].level,
                    });
                } else if (response?.data?.tutor?.tutorSkills[j]?.skill?.isAHobby) {
                    // Passions
                    passionArray.push({
                        id: response.data.tutor?.tutorSkills[j].skill?.id,
                        title: response.data.tutor?.tutorSkills[j].skill?.title,
                    });
                }
            }

            // Files.
            const avatarUrl = await getTutorFile(
                tutorId,
                apiConstants.FILES.AVATAR.fileType
            );
            const schoolProofUrl = await getTutorFile(
                tutorId,
                apiConstants.FILES.SCHOOL_PROOF.fileType
            );
            const bacMarksUrl = await getTutorFile(
                tutorId,
                apiConstants.FILES.BAC_MARKS.fileType
            );
            const IdUrl = await getTutorFile(tutorId, apiConstants.FILES.ID.fileType);

            // Stripe balance.
            //const stripeBankAccount = await apiService.getStripeBankAccount(tutorId);
            const stripeVerified = await apiService.getStripeVerified(tutorId);

            let balance = 0;

            /*if (stripeBankAccount?.error) {
                balance = 0; //appConstants.DEFAULT_ACCOUNT_STRIPE_NEEDS_VERIF;
            } else {
                balance = await apiService.getStripeBalance(tutorId);
            }*/


            const avatar = new FileModel(
                apiConstants.FILES.AVATAR.defaultFileName,
                avatarUrl ? avatarUrl : appConstants.DEFAULT_PROFILE_PICTURE,
                apiConstants.FILES.AVATAR.fileType,
                response.data?.tutor?.id
            );
            const schoolProof = new FileModel(
                apiConstants.FILES.SCHOOL_PROOF.defaultFileName,
                schoolProofUrl,
                apiConstants.FILES.SCHOOL_PROOF.fileType,
                response.data?.tutor?.id
            );
            const bacMarks = new FileModel(
                apiConstants.FILES.BAC_MARKS.defaultFileName,
                bacMarksUrl,
                apiConstants.FILES.BAC_MARKS.fileType,
                response.data?.tutor?.id
            );
            const ID = new FileModel(
                apiConstants.FILES.ID.defaultFileName,
                IdUrl,
                apiConstants.FILES.ID.fileType,
                response.data?.tutor?.id
            );

            response.data.tutor.tutorSkills = [
                { subjectList: subjectArray },
                { passionList: passionArray },
            ];

            response.data.tutor.files = {
                avatar,
                schoolProof,
                bacMarks,
                ID,
                bills: {},
            };
            response.data.tutor.phone = phoneNumberConvert(response.data.tutor.phone);
            response.data.tutor.stripeVerified = stripeVerified;

            return {
                type: userConstants.TAH_UPDATE_ALL,
                value: {
                    ...response.data,
                    balance: balance,
                },
            };
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            // returns by default undefined
        });
}

async function getTutorStepGuide(id) {
    return await axios.get(`${apiConstants.API_TUTORS}/${id}?properties[]=stepGuide`)
        .then(async response => {
            return response?.data?.stepGuide
        })
        .catch(function (error) {
            console.error(error);
        });
}

async function getCustomerStepGuide(id) {
    return await axios.get(`${apiConstants.API_CUSTOMERS}/${id}?properties[]=stepGuide`)
        .then(async response => {
            return response?.data?.stepGuide
        })
        .catch(function (error) {
            console.error(error);
        });
}

async function patchTutorStepGuide(id, form) {
    return await axios.patch(
        `${apiConstants.API_TUTORS}/${id}?properties[]=stepGuide`, JSON.stringify(form), { headers: { "Content-Type": "application/merge-patch+json" } })
        .then(async response => {
            return response?.data?.stepGuide
        })
        .catch(function (error) {
            console.error(error);
        });
}

async function patchTutorAcceptedConditions(id, hasAcceptedConditions) {
    return await axios.patch(
        `${apiConstants.API_TUTORS}/${id}?properties[]=hasAcceptedConditions`, JSON.stringify({
            hasAcceptedConditions: hasAcceptedConditions
        }), { headers: { "Content-Type": "application/merge-patch+json" } })
        .then(async response => {
            return response?.data?.hasAcceptedConditions
        })
        .catch(function (error) {
            console.error(error);
        });
}

async function patchCustomerStepGuide(id, form) {
    return await axios.patch(
        `${apiConstants.API_CUSTOMERS}/${id}?properties[]=stepGuide`, JSON.stringify(form), { headers: { "Content-Type": "application/merge-patch+json" } })
        .then(async response => {
            return response?.data?.stepGuide
        })
        .catch(function (error) {
            console.error(error);
        });
}

async function getInvoices() {
    return await axios.get(`${apiConstants.API_INVOICES}`)
        .then(async response => {
            return response.data["hydra:member"]
                .map(invoice => new Promise((resolve, _reject) => {
                    apiService.getInvoicesDownloadLink(invoice.invoiceCustomer).then(url =>
                        resolve(
                            new FileModel(
                                invoice.invoiceCustomer,
                                url,
                                null,
                                null
                            ))
                    )
                }));
        })
        .catch(function (error) {
            console.error(error);
        });
}

/**
 * Get the whole object Customer.
 * @param {number} id
 * @returns Object with all the data of customer, otherwise returns undefined.
 */
async function getCustomerByUserId(id) {
    return axios
        .get(
            `${apiConstants.API_USERS}/${id}?properties[]=firstname&properties[]=lastname&properties[]=email&properties[]=role&properties[]=id&properties[customer][]=hasPaymentMethod&properties[customer][]=lastErrorPayment&properties[customer][]=sponsorCode&properties[customer][]=id&properties[customer][]=phone&properties[customer][]=gender&properties[customer][billingAddress][]=id&properties[customer][billingAddress][]=number&properties[customer][billingAddress][]=street&properties[customer][billingAddress][]=city&properties[customer][billingAddress][]=zipcode&properties[customer][addresses][]=id&properties[customer][addresses][]=number&properties[customer][addresses][]=street&properties[customer][addresses][]=city&properties[customer][addresses][]=zipcode`
        )
        .then(async (response) => {
            const requests = await getLessonsWithFilter(response.data?.customer?.id, [
                appConstants.FILTER_VALUE_ALL,
            ]);
            const tutors = await getCustomersTutors(response.data?.customer?.id);

            const creditCard = await apiService.getStripeCardByCustomerId(
                response.data?.customer?.id
            );
            const amountToBePaid = await apiService.getAmountToBePaid();

            const invoices = await getInvoices();

            return {
                type: userConstants.TAH_UPDATE_ALL,
                value: {
                    ...response.data,
                    requests,
                    invoices: invoices,
                    tutors,
                    creditCard: creditCard,
                    amountToBePaid: amountToBePaid,
                },
            };
        })
        .catch(function (error) {
            // handle error
            console.error(error);
        });
}

/**
 * Get the customer's due to pay.
 * @param {number} id
 * @returns the customer's due to pay if succeed, otherwise returns ERROR.
 */
async function getAmountToBePaid() {
    return await axios
        .get(apiConstants.API_AMOUNT_TO_PAID)
        .then((response) => {
            return response.data?.amount;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Post an address.
 * @param {number} id
 * @param {object} form
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function postAddress(id, form) {
    return await axios
        .post(`${apiConstants.API_ADDRESSES}/${id}`, JSON.stringify(form), {
            headers: { "Content-Type": "application/ld+json" },
        })
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Delete an address by id.
 * @param {number} id
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function deleteAddressById(id) {
    return await axios
        .delete(`${apiConstants.API_ADDRESSES}/${id}`)
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Update Tutor's data according to id and form provided.
 * @param {number} id
 * @param {object} form
 * @returns SUCCESS if succeed, otherwise returns ERROR.
 */
async function patchTutorById(id, form) {
    return await axios
        .patch(
            `${apiConstants.API_TUTORS}/${id}?properties[]=phone&properties[]=address&properties[]=number&properties[]=city&properties[]=zipcode&properties[]=supportedGrades&properties[]=meansTransport&properties[]=supportedCharacteristics&properties[]=school&properties[]=hasAcceptedConditions`,
            JSON.stringify(form),
            { headers: { "Content-Type": "application/merge-patch+json" } }
        )
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

/**
 * Update tutor's data.
 * @param {number} tutorId
 * @param {object} formTutor
 * @returns SUCCESS if succeed, otherwise returns ERROR
 */
async function patchTutorSkills(tutorId, formSkills) {
    return await axios
        .post(`${apiConstants.API_TUTORS_SKILLS}/${tutorId}/update_multiple`, JSON.stringify(formSkills))
        .then(async _response => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getHomeworksByRequestId(id) {
    return await axios
        .get(
            `${apiConstants.API_HOMEWORK}?lesson.request.id=${id}&exists[isCompleted]=false&properties[]=id&properties[]=title&properties[]=description&properties[]=deadline&properties[]=completionDate&properties[]=comment&properties[]=mark&properties[skills][]=title&properties[skills][]=id`
        )
        .then((response) => {
            let homeworks = [];
            for (let i = 0; i < response.data["hydra:member"]?.length; i++) {
                homeworks.push(
                    new HomeworkModel().buildFromInstance(
                        response.data["hydra:member"][i]
                    )
                );
            }
            return homeworks;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function updateHomework(form) {
    const id = form.id;
    return await axios
        .patch(
            `${apiConstants.API_HOMEWORK}/${id}?properties[]=id&properties[]=completionDate&properties[]=comment&properties[]=isCompleted`,
            JSON.stringify(form),
            { headers: { "Content-Type": "application/merge-patch+json" } }
        )
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function deleteHomework(id) {
    return await axios
        .delete(`${apiConstants.API_HOMEWORK}/${id}`)
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getTutorRevenues() {
    return await axios
        .get(`${apiConstants.API_PAYOUTS_GRAPH}`)
        .then((response) => {
            let XYchart = [];
            if (response?.data) {
                response.data.forEach((elt) => {
                    XYchart.unshift(new XYChartModel().buildFromInstance(elt));
                });
                return XYchart;
            } else return apiConstants.ERROR;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function postSwypeSurvey(form) {
    return await axios
        .post(`${apiConstants.HOOK_SWYPE_SURVEY}`, JSON.stringify(form), {
            headers: { "Content-Type": "application/json " },
        })
        .then((_response) => {
            return apiConstants.SUCCESS;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getCertificateYears() {
    return await axios
        .get(apiConstants.API_CERTIFICATE_YEARS)
        .then(response => {
            return response.data.years.map(year => new OptionModel(year, `Attestations fiscales ${year}`))
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function downloadCertificates(year) {
    return await axios
        .post(`${apiConstants.API_CERTIFICATES}`, JSON.stringify({ year: year }), {
            headers: { "Content-Type": "application/json" }, responseType: 'blob'
        })
        .then(response => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.download = "attestations-ficales-X.zip";
            link.click();
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.error;
        });
}

async function getTransports() {
    return await axios.get(
        `${apiConstants.API_TRANSPORT}?properties[]=id&properties[]=name`)
        .then(response => {
            return response.data["hydra:member"]?.map(transport => new OptionModel(transport.id, transport.name));
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorLevels(id) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${id}?properties[supportedGrades][]=id&properties[supportedGrades][]=title`)
        .then(response => {
            return response.data.supportedGrades;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorTransports(id) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${id}?properties[meansTransport][]=id&properties[meansTransport][]=name`)
        .then(response => {
            return response.data.meansTransport?.map(mean => {
                return {
                    id: mean.id,
                    name: mean.name
                }
            })
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorCharacteristics(id) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${id}?properties[supportedCharacteristics][]=id&properties[supportedCharacteristics][]=name`)
        .then(response => {
            return response.data.supportedCharacteristics;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function autoLogin(token) {
    return await axios.post(`${apiConstants.API_AUTO_LOGIN}`, JSON.stringify({ token: token }), {
        headers: { "Content-Type": "application/json" }
    })
        .then(response => {
            updateTokens({ token: response.data.token, refresh_token: response.data.refreshToken });
            return response
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.error;
        });
}

async function patchReview(form) {
    return await axios.patch(`${apiConstants.API_LESSONS}/${form.lessonId}?properties[review][]=comment&properties[review][]=score`, JSON.stringify(form), {
        headers: { "Content-Type": "application/merge-patch+json" }
    })
        .then(response => {
            return response
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.response;
        });
}

async function lessonOpposition(lessonId) {
    return await axios.patch(`${apiConstants.API_LESSONS}/${lessonId}?properties[]=isBlocked&properties[request][student][]=firstname&properties[request][student][]=lastname&properties[]=price&properties[]=start&properties[]=duration&properties[skills][]=title&properties[tutor][user][]=firstname&properties[tutor][user][]=lastname&properties[invoice][]=invoiceCustomer`, JSON.stringify({ isBlocked: true }), {
        headers: { "Content-Type": "application/merge-patch+json" }
    })
        .then(response => {
            return new LessonModel().buildFromInstance({
                id: lessonId,
                student: new UserModel().buildFromInstance({
                    firstname: response.data.request?.student?.firstname,
                    lastname: response.data.request?.student?.lastname,
                }),
                tutor: new UserModel().buildFromInstance({
                    firstname: response.data.tutor?.user?.firstname,
                    lastname: response.data.tutor?.user?.lastname,
                }),
                duration: response.data.duration,
                price: response.data.price,
                date: dateDispatch(response.data.start),
            });
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.response;
        });
}

async function getCharacteristics() {
    return await axios.get(
        `${apiConstants.API_CHARACTERISTICS}?properties[]=id&properties[]=name`)
        .then(response => {
            return response.data["hydra:member"]?.map(characteristics => new OptionModel(characteristics.id, characteristics.name))
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getAdvisor(id) {
    return await axios.get(
        `${apiConstants.API_CUSTOMERS}/${id}?properties[advisor][user][]=firstname&properties[advisor][user][]=lastname&properties[advisor][user][]=email&properties[advisor][]=phone&properties[advisor][]=calendly`)
        .then(response => {
            return new AdvisorModel().buildFromInstance({
                ...response?.data?.advisor,
                firstname: response.data?.advisor?.user?.firstname,
                lastname: response.data?.advisor?.user?.lastname,
                email: response.data?.advisor?.user?.email,
            });
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getCustomerStudents(id) {
    return await axios.get(
        `${apiConstants.API_CUSTOMERS}/${id}?properties[students][]=id&properties[students][]=firstname&properties[students][]=lastname`)
        .then(response => {
            return response.data.students?.map(elt => new UserModel().buildFromInstance(elt));
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

/**
 * Post a request.
 * @param form
 * @returns SUCCESS message if succeed, otherwise returns ERROR.
 */
async function postRequest(form) {
    return await axios.post(`${apiConstants.API_REQUESTS}?properties[student][]=id&properties[address][]=id&properties[requestSkills][skill][]=id&properties[]=availability&properties[]=beginAt&properties[]=endAt&properties[]=notes&properties[]=lessonsPerWeek&properties[]=lessonsDuration&properties[]=preferences`, JSON.stringify(form), {
        headers: { "Content-Type": "application/ld+json" },
    })
        .then(response => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;

        });
}

async function downloadCustomerReport(form, fileName = "rapport") {
    return await axios.post(`${apiConstants.API_REPORT}`, JSON.stringify(form), {
        headers: { "Content-Type": "application/json" }, responseType: 'blob'
    })
        .then(response => {
            const extension = "pdf";
            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.download = `${fileName}.${extension}`;
            link.click();
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.error;
        });
}

async function getLadders() {
    return await axios.get(
        `${apiConstants.API_LADDERS}?properties[tutor][]=id&properties[tutor][]=firstname&properties[tutor][]=lastname&properties[]=points`)
        .then(async response => {
            return await Promise.all(response.data["hydra:member"].map(async (elt, index) => {
                let avatarUrl;
                try {
                    avatarUrl = await getTutorFile(
                        elt.tutor.id,
                        apiConstants.FILES.AVATAR.fileType
                    );
                } catch (error) {
                    avatarUrl = false
                }

                return new LadderModel().buildFromInstance({
                    rank: index + 1,
                    points: elt.points,
                    firstname: elt.tutor.firstname,
                    picture: avatarUrl,
                })
            }));
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorLadder(id) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${id}?properties[]=id&properties[user][]=firstname&properties[]=sumDurationLessons&properties[ladder][]=points`)
        .then(async response => {
            const rank = await axios.get(`${apiConstants.API_TUTOR}/ladder-position`)
                .then(resp => resp.data.position)
                .catch(_err => apiConstants.ERROR)
            return [
                new LadderModel().buildFromInstance({
                    rank: rank,
                    points: response.data.ladder.points,
                    firstname: response.data.user?.firstname,
                    picture: await getTutorFile(
                        response.data.id,
                        apiConstants.FILES.AVATAR.fileType
                    ),
                }),
                response.data.sumDurationLessons,
            ]
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorStatsMajored() {
    return await axios
        .get(`${apiConstants.API_TUTOR}/is-marjored`)
        .then((response) => {
            if (response?.data) {
                return response.data;
            } else
                return apiConstants.ERROR;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function setTutorNotifications(id, active) {
    return await axios.patch(`${apiConstants.API_TUTORS}/${id}?properties[]=notificationEnabled`, JSON.stringify({ notificationEnabled: active }), {
        headers: { "Content-Type": "application/merge-patch+json" }
    })
        .then(response => {
            return response
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return error.response;
        });
}

async function getTutorNotifications(id) {
    return await axios.get(`${apiConstants.API_TUTORS}/${id}?properties[]=notificationEnabled`)
        .then(response => {
            return response?.data?.notificationEnabled;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorStudents(page) {
    return await axios.get(
        `${apiConstants.API_STUDENTS}?page=${page}properties[]=id&properties[]=firstname&properties[]=lastname&properties[]=gender&properties[grade][]=title`)
        .then(response => {
            const regex = /(page=)(\d)/;
            const pagination = new PaginationModel().buildFromInstance({
                page: parseInt(regex.exec(response.data["hydra:view"]["@id"]) ? regex.exec(response.data["hydra:view"]["@id"])[2] : 0),
                values: response.data["hydra:member"].map(elt => new StudentModel().buildFromInstance(elt)),
                pageTotal: Math.ceil(response.data["hydra:totalItems"] / 30),
            })
            return pagination;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getCustomerAddresses(id) {
    return await axios.get(
        `${apiConstants.API_CUSTOMERS}/${id}?properties[addresses][]=id&properties[addresses][]=number&properties[addresses][]=street&properties[addresses][]=city&properties[addresses][]=zipcode`)
        .then(response => {
            return response.data.addresses?.map(elt => new AddressModel().buildFromInstance(elt));
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getTutorsSkills(id) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${id}?&properties[tutorSkills][]=id&properties[tutorSkills][]=level&properties[tutorSkills][skill][]=id&properties[tutorSkills][skill][]=title&properties[tutorSkills][skill][]=isASubject&properties[tutorSkills][skill][]=isAHobby`)
        .then(response => {
            let subjectArray = [];
            let passionArray = [];

            for (var j = 0; j < response?.data?.tutorSkills?.length; j++) {
                if (response?.data?.tutorSkills[j]?.skill?.isASubject) {
                    // Subjects
                    subjectArray.push({
                        id: response.data.tutorSkills[j].skill?.id,
                        title: response.data.tutorSkills[j].skill?.title,
                        level: response.data.tutorSkills[j].level,
                    });
                } else if (response?.data?.tutorSkills[j]?.skill?.isAHobby) {
                    // Passions
                    passionArray.push({
                        id: response.data.tutorSkills[j].skill?.id,
                        title: response.data.tutorSkills[j].skill?.title,
                    });
                }
            }

            return {
                subjects: subjectArray,
                hobbies: passionArray,
            }
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getSchools() {
    return await axios.get(
        `${apiConstants.API_SCHOOLS}?properties[]=id&properties[]=name&properties[]=postalCode&properties[types][]=name`)
        .then(response => {
            return (
                response.data["hydra:member"].map(school => {
                    return new SchoolModel().buildFromInstance({
                        ...school,
                        address: new AddressModel(null, null, null, null, school.postalCode),
                        types: school.types?.map(elt => new OptionModel(null, elt.name)),
                    }).toOptionModel()
                }
                )
            )
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getSchoolWithCallback(input, callback = () => { return }) {
    if (!input) {
        callback([]);
    } else {
        return await axios.get(
            `${apiConstants.API_SCHOOLS}/?name=${input}&id=${input}`)
            .then(response => {
                callback(response.data["hydra:member"]?.map(elt => {
                    const schoolAddress = extractAddress({ address: elt.address });
                    return new SchoolModel().buildFromInstance({
                        ...elt,
                        address: new AddressModel(null, schoolAddress[0], schoolAddress[1], elt.city, elt.postalCode)
                    }).toOptionModel();
                }))
            })
            .catch(function (error) {
                // handle error
                console.error(error);
                return apiConstants.ERROR;
            })
    }
}

async function getApplicationFees(customerId) {
    return await axios.get(
        `${apiConstants.API_CUSTOMERS}/${customerId}/?properties[]=applicationFees&properties[]=applicationFeesPaid`)
        .then(response => {
            return response?.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })

}

async function getTutorSchool(tutorId) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${tutorId}/?properties[school][]=id&properties[school][]=name&properties[school][]=postalCode`)
        .then(response => {
            return new SchoolModel().buildFromInstance({
                ...response.data.school,
                address: new AddressModel(null, null, null, null, response.data.school.postalCode),
                types: response.data.school.types?.map(elt => new OptionModel(null, elt.name)),
            }).toOptionModel();
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function getStripeVerified(tutorId) {
    return await axios.get(
        `${apiConstants.API_TUTORS}/${tutorId}?properties[]=stripeVerified`)
        .then(response => {
            return response.data.getStripeVerified
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        })
}

async function isPasswordAlreadyAdded(form) {
    return await axios.post(
        apiConstants.PASSWORD_ADDED,
        JSON.stringify(form),
        { headers: { "Content-Type": "application/ld+json" } }
    )
        .then((response) => {
            return response.data.passwordAdded;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}

async function getTutorPrices() {
    return await axios.get(apiConstants.API_TUTOR_PRICES)
        .then(response => {
            return response.data;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return apiConstants.ERROR;
        });
}
