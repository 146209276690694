import { userConstants } from "../_constants";
import { userService } from "../_api";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
    login,
    logout,
    setMultiples,
};

async function login(username, password, reload = true) {
    return async (dispatch) => {
        dispatch(request({ username }));
        await userService.login(username, password).then(
            (user) => {
                dispatch(success(user));
                if (reload && user) {
                    history.push("/");
                    window.location.reload();
                }
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(setForgetPassword(true));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
    function setForgetPassword(data) {
        return { type: userConstants.FORGET_PASSWORD, data };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function setMultiples(values) {
    return {
        type: userConstants.TAH_UPDATE_MULTIPLE,
        value: values
    }
}