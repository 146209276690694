import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from 'prop-types';

function LoaderSpinner({ className, color, height, absolute, global = false }) {
    if (global) {
        return (
            <div style={{ display: "none" }} id="loader">
                <Loader
                    className={className ? className : "h-100 w-100 d-flex justify-content-center align-items-center"}
                    style={{ backgroundColor: absolute ? "rgba(255,255,255, 0.5)" : "transparent", position: absolute ? "fixed" : undefined, zIndex: absolute ? "9999" : undefined, top: absolute ? "0" : undefined, left: absolute ? "0" : undefined }}
                    type="TailSpin"
                    color={color ? color : "#4a25aa"}
                    height={height ? height : 100}
                    width={100}
                    timeout={0}
                />
            </div>)
    }
    else {
        return (
            <Loader
                className={className ? className : "h-100 w-100 d-flex justify-content-center align-items-center"}
                style={{ backgroundColor: absolute ? "rgba(255,255,255, 0.5)" : "transparent", position: absolute ? "fixed" : undefined, zIndex: absolute ? "9999" : undefined, top: absolute ? "0" : undefined, left: absolute ? "0" : undefined }}
                type="TailSpin"
                color={color ? color : "#4a25aa"}
                height={height ? height : 100}
                width={100}
                timeout={0}
            />)
    }
}

LoaderSpinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    absolute: PropTypes.bool,
};

export default LoaderSpinner;