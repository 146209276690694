const { customerConstants } = require("_constants");

export const customerActions = {
    setAddresses,
    setStudents,
};

function setAddresses(addresses) {
    return {
        type: customerConstants.SET_ADDRESSES,
        value: addresses,
    }
}

function setStudents(students) {
    return {
        type: customerConstants.SET_STUDENTS,
        value: students,
    }
}